import React from 'react';
import { useNavigate } from '../utils/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useSnackbar } from '../utils/snackBar';

import Button from '../components/Button';
import createLaunchLink from '../mutations/createLaunchLink';
import { usePxPerRemFactor, useWindowDimensions } from '../utils/hooks';
import Logo from '../components/Logo';

const HEIGHT_REM = 4;

export const useLaunchLink = (contentId?: string): string | null | undefined => {
	const navigate = useNavigate();
	const snackBar = useSnackbar();
	const [launchLink, setLaunchLink] = React.useState<undefined | null | string>();
	React.useEffect(() => {
		if (contentId) {
			(async () => {
				const { success, link, error } = await createLaunchLink(contentId);
				if (success) {
					setLaunchLink(link);
				} else {
					snackBar({ error });
					setLaunchLink(null);
					navigate(`/content/${contentId}`);
				}
			})();
		}
	}, [contentId, navigate, snackBar]);
	return launchLink;
};

const Column = styled(Box)`
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const ContentPlayerView = (): JSX.Element | null => {
	const { id } = useParams();
	const launchLink = useLaunchLink(id);
	const pxPerRemFactor = usePxPerRemFactor();
	const { height: windowHeight } = useWindowDimensions();
	const iframeHeight = windowHeight - HEIGHT_REM * pxPerRemFactor;

	const [showContent, setShowContent] = React.useState(true);
	const [loading, setLoading] = React.useState(false);

	const [searchParams] = useSearchParams();
	const wasReferredFromInternal = searchParams.get('internal') || '';

	// make sure to go back in browser history until the ContentPlayerView is closed
	const intervalRef = React.useRef<any | null>(null);
	const handleClose = React.useCallback(async () => {
		setShowContent(false);
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (!showContent) {
			(async () => {
				await delay(500);
				// go back until the ContentPlayerView is closed, so the clearing hook runs
				intervalRef.current = setInterval(() => {
					window.history.back();
				}, 50);
			})();
		}
	}, [showContent]);

	React.useEffect(() => {
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	// to test a course locally, copy it into app/public and use the following link
	// const pseudoLaunchLink = `/testcourse/index.html${launchLink?.split('index.html')[1]}`;

	return (
		<>
			<Box
				display="flex"
				width="100%"
				flexDirection="row"
				justifyContent="flex-start"
				height={`${HEIGHT_REM}rem`}
				alignItems="center"
				borderBottom="1px solid black"
			>
				<Logo m="1rem" />
				<Box flex="1" />
				{wasReferredFromInternal ? (
					<Button m="1rem" variant="secondButton" onClick={handleClose} loading={loading}>
						Schließen
					</Button>
				) : null}
			</Box>
			<Column>
				{!launchLink || !showContent ? null : (
					<iframe width="100%" height={iframeHeight} src={launchLink} frameBorder="0"></iframe>
				)}
			</Column>
		</>
	);
};

export default ContentPlayerView;
