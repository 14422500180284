import React from 'react';

import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import teacherImage from '../images/BF_Grafik_Module_BFGRATIS_Lehrer.png';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import SectionWrapper from '../components/SectionWrapper';
import CreateAccessGeneratorDialog from '../components/CreateAccessGeneratorDialog';
import AccessGeneratorsSection from '../components/AccessGeneratorsSection';

import { useCurrentUser } from '../utils/user';

const ShareView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();

	const handleCreateAccessGenerator = React.useCallback(() => {
		Dialog.render({
			renderContent: CreateAccessGeneratorDialog,
			isLocked: true,
		});
	}, []);

	const remaingAccessPhrases = currentUser?.accessGeneratorsInfo?.remaining;

	return !currentUser ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={teacherImage}
				title="Teilen"
				description="Teile Kurse mit anderen Menschen und behalte den Überblick über deine ausgeteilten Kurse."
			/>

			<SectionWrapper flexDirection="column">
				<Button
					variant="mainButton"
					onClick={handleCreateAccessGenerator}
					disabled={remaingAccessPhrases === 0}
				>
					Kurs teilen
				</Button>
			</SectionWrapper>
			<AccessGeneratorsSection />
		</ContentWrapper>
	);
};

export default ShareView;
