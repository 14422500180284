import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { ContentInfos } from './content';
import { useStringArray } from './helpers';

export type CategoryGroupName =
	| 'AUDIENCE'
	| 'COLLECTION'
	| 'EDUCATION_FORMAT'
	| 'EDUCATION_LEVEL'
	| 'SERIE';

export type ContentCategoryInfo = {
	type: string;
	title: string;
};

export type Category = {
	id: string;
	groupName: CategoryGroupName;
	type: string;
	title: string;
	shortDescription?: string;
	subTitle?: string;
	description?: string;
	imageUrl?: string;
	contentEducationFormats: ContentCategoryInfo[];
	contentEducationLevels: ContentCategoryInfo[];
	authors?: string[];
};

export const CategoryDetailsFragment = gql`
	fragment CategoryDetailsFragment on Category {
		id
		title
		shortDescription
		subTitle
		description
		type
		groupName
		imageUrl
		contentEducationFormats {
			title
			type
		}
		contentEducationLevels {
			title
			type
		}
		authors
	}
`;

export const useAllCategories = (): Category[] | undefined => {
	const { data } = useQuery(
		gql`
			query AllCategories {
				categories {
					...CategoryDetailsFragment
				}
			}
			${CategoryDetailsFragment}
		`
	);
	return React.useMemo(() => data?.categories, [data?.categories]);
};

export const useCategory = (id?: string): Category | undefined => {
	const { data } = useQuery(
		gql`
			query Category($id: ID!) {
				category(id: $id) {
					...CategoryDetailsFragment
				}
			}
			${CategoryDetailsFragment}
		`,
		{
			variables: {
				id,
			},
			skip: !id,
		}
	);
	return React.useMemo(() => data?.category, [data?.category]);
};

export const useCategoriesOfGroup = (
	groupNames?: CategoryGroupName[] | CategoryGroupName
): Category[] | undefined | null => {
	const groupNamesArray = useStringArray(groupNames);
	const allCategories = useAllCategories();
	const filteredCategories = React.useMemo(
		() => allCategories?.filter((category) => groupNamesArray.includes(category.groupName)),
		[allCategories, groupNamesArray]
	);
	return !groupNames ? null : filteredCategories;
};

export const useAvailableCategories = (
	groupNames?: CategoryGroupName | CategoryGroupName[],
	contents?: ContentInfos[]
): Category[] | undefined | null => {
	const groupNamesArray = useStringArray(groupNames);
	const availableCategories = React.useMemo(() => {
		return contents?.reduce<Category[]>((acc, content) => {
			const newCategories = content.categories
				.filter((conCat) => groupNamesArray.includes(conCat.groupName))
				.filter((conCat) => !acc.some((accCat) => accCat.id === conCat.id));
			return newCategories.length ? [...acc, ...newCategories] : acc;
		}, []);
	}, [contents, groupNamesArray]);

	return availableCategories;
};
