import React from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '../utils/snackBar';

import ContentWrapper from '../components/ContentWrapper';
import OrderSummary from '../components/OrderSummary';
import { useUserOrder } from '../utils/order';

const OrderDetailView = (): JSX.Element => {
	const { id, success: successString } = useParams();
	const success = successString === 'true';
	const order = useUserOrder(id);
	const snackBar = useSnackbar();

	React.useEffect(() => {
		if (success) {
			snackBar({ success: 'Erfolg!' });
		}
	}, [snackBar, success]);

	return (
		<ContentWrapper maxWidth="100%">
			{!order ? null : (
				<OrderSummary p="2rem" priceLabel={order.priceLabel} products={order.products} />
			)}
		</ContentWrapper>
	);
};

export default OrderDetailView;
