import React from 'react';
import { useParams } from 'react-router-dom';

import ContentWrapper from '../components/ContentWrapper';
import ContentHeader from '../components/ContentHeader';
import { useCategory } from '../utils/category';
import { useApiFilteredContents } from '../utils/content';
import FilterableContents2 from '../components/FilterableContents2';
import SectionWrapper from '../components/SectionWrapper';
import { useCurrentUser } from '../utils/user';

const CategoryView = ({ categoryId }: { categoryId?: string }): JSX.Element => {
	const routeParams = useParams();
	const id = categoryId || routeParams.id;
	const currentUser = useCurrentUser();
	const category = useCategory(id);
	const filteredContents = useApiFilteredContents({
		categoryTypeFilters: category?.type,
		userAudiences: currentUser?.audienceType,
		contentIsDemo: false,
	});

	return (
		<ContentWrapper>
			{!category || !filteredContents ? null : (
				<>
					<ContentHeader
						title={category.title}
						imageUrl={category.imageUrl}
						shortDescription={category.shortDescription}
						descriptionTitle={category.subTitle}
						description={category.description}
						infoBoxTitle="Infos zur Collection"
					/>
					<SectionWrapper>
						<FilterableContents2 contents={filteredContents} />
					</SectionWrapper>
				</>
			)}
		</ContentWrapper>
	);
};

export default CategoryView;
