import { useQuery, gql } from '@apollo/client';
import { Category, CategoryDetailsFragment } from './category';

export type Product = {
	id: string;
	type: string;
	title: string;
	description?: string;
	priceCents?: number;
	priceLabel: string;
	targetLabelSingular: string;
	targetLabelPlural: string;
	targetDescription: string;
	targetApproach: string;
	audienceCategories: Category[];
};

export const ProductDetailsFragment = gql`
	fragment ProductDetailsFragment on Product {
		id
		type
		title
		description
		priceCents
		priceLabel
		targetLabelSingular
		targetLabelPlural
		targetDescription
		targetApproach
		audienceCategories {
			...CategoryDetailsFragment
		}
	}
	${CategoryDetailsFragment}
`;

export const useAllProducts = (): Product[] | undefined => {
	const { data } = useQuery(
		gql`
			query {
				products {
					...ProductDetailsFragment
				}
			}
			${ProductDetailsFragment}
		`
	);

	return data?.products;
};

export const useProduct = (id?: string): Product | undefined | null => {
	const { data } = useQuery(
		gql`
			query Product($id: ID!) {
				product(id: $id) {
					...ProductDetailsFragment
				}
			}
			${ProductDetailsFragment}
		`,
		{ variables: { id }, skip: !id }
	);

	return !id ? null : data?.product;
};
