import React from 'react';
import { useNavigate } from '../utils/hooks';
import styled from 'styled-components';
import {
	Box,
	Button as MuiButton,
	IconButton as MuiIconButton,
	CircularProgress,
} from '@mui/material';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import iconNameDict, { IconName } from '../utils/iconNameDict';
export interface ButtonProps extends MuiButtonProps {
	label?: string;
	m?: string;
	navigateTo?: string;
	onClick?: (val?: any) => any;
	loading?: boolean;
	startIconName?: IconName;
	endIconName?: IconName;
	iconName?: IconName;
	isUploadButton?: boolean;
	triggerOnEnter?: boolean;
}

const StyledCircularProgress = styled(CircularProgress)`
	color: ${(p) => p.theme.customColors.lightGrey};
`;

const Absolute = styled(Box)`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	pointer-events: cursor;
`;

const PotentiallyWrapInLabel = ({ isUploadButton, children }): JSX.Element => {
	return isUploadButton ? <label htmlFor="contained-button-file">{children}</label> : children;
};

const UploadInput = styled('input')({
	display: 'none',
});

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			label,
			loading,
			variant,
			m,
			navigateTo,
			children,
			onClick,
			id,
			disabled,
			startIconName,
			endIconName,
			iconName,
			fullWidth,
			isUploadButton,
			size,
			triggerOnEnter,
			...rest
		},
		ref
	) => {
		const navigate = useNavigate();
		const handleClick = React.useCallback(() => {
			if (navigateTo) {
				navigate(navigateTo);
			} else {
				onClick && onClick(id);
			}
		}, [id, navigate, navigateTo, onClick]);

		const Icon = iconName && iconNameDict[iconName] ? iconNameDict[iconName] : null;

		React.useEffect(() => {
			if (triggerOnEnter) {
				window.addEventListener('keydown', handleClick);
			}
			return () => {
				if (triggerOnEnter) {
					window.removeEventListener('keydown', handleClick);
				}
			};
		}, [handleClick, triggerOnEnter]);

		return (
			<Box m={m} position="relative" width={fullWidth ? '100%' : undefined}>
				<PotentiallyWrapInLabel isUploadButton={isUploadButton}>
					{isUploadButton ? (
						<UploadInput accept="*" id="contained-button-file" multiple type="file" />
					) : null}
					{children ? (
						<MuiButton
							onClick={handleClick}
							variant={
								loading ? (variant === 'mainButton' ? 'loadingMainButton' : 'loading') : variant
							}
							disabled={loading || disabled}
							endIcon={
								endIconName && iconNameDict[endIconName] ? iconNameDict[endIconName]() : undefined
							}
							startIcon={
								startIconName && iconNameDict[startIconName]
									? iconNameDict[startIconName]()
									: undefined
							}
							fullWidth={fullWidth}
							component={isUploadButton ? 'span' : 'div'}
							{...rest}
							ref={ref}
						>
							{label || children}
							{loading ? (
								<Absolute>
									<StyledCircularProgress size={24} />
								</Absolute>
							) : null}
						</MuiButton>
					) : Icon ? (
						<MuiIconButton size={size} onClick={handleClick} {...rest}>
							<Icon />
						</MuiIconButton>
					) : null}
				</PotentiallyWrapInLabel>
			</Box>
		);
	}
);

export default React.memo(Button);
