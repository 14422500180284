import client from '../utils/client';
import { gql } from '@apollo/client';

type Error =
	| 'NETWORK_ERROR'
	| 'ACCESS_GENERATOR_CODE_INVALID'
	| 'ACCESS_GENERATOR_CODE_EXPIRED'
	| 'ACCESS_GENERATOR_CODE_EXHAUSTED'
	| 'ALREADY_LOGGED_IN';

const accessGeneratorRegister = async (
	code: string
): Promise<
	| { success: true; phrase: string; error: undefined }
	| { success: false; phrase: undefined; error: Error }
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation AccessGeneratorRegister($code: String!) {
					accessGeneratorRegister(code: $code) {
						success
						phrase
					}
				}
			`,
			variables: { code },
			errorPolicy: 'all',
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
				phrase: undefined,
			};
		}
		return {
			success: true,
			phrase: data.accessGeneratorRegister.phrase,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR', phrase: undefined };
		throw e;
	}
};

export default accessGeneratorRegister;
