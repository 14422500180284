import React from 'react';
import { useCurrentUser } from './user';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import { IconName } from './iconNameDict';
import { logout } from './user';
import { useNavigate } from '../utils/hooks';
import { useIsMobile } from './hooks';

type NavButton = {
	label: string;
	navigateTo?: string;
	variant?: MuiButtonProps['variant'];
	iconName?: IconName;
	onClick?: (argument: any) => void;
	children?: NavButton[];
};

const useShowTeachAndPricing = () => {
	const currentUser = useCurrentUser();

	return (
		!currentUser ||
		(currentUser &&
			(currentUser.audienceType === 'TEACHER' || currentUser.audienceType === 'PARENTS'))
	);
};

export const useAuthButtons = (): NavButton[] => {
	const navigate = useNavigate();
	const isMobile = useIsMobile();
	const currentUser = useCurrentUser();
	const handleLogout = React.useCallback(() => {
		logout(navigate);
	}, [navigate]);
	return currentUser
		? isMobile
			? [
					{
						label: 'Abmelden',
						variant: 'outlined',
						onClick: handleLogout,
					},
			  ]
			: [
					{
						label: 'Mein Profil',
						iconName: 'AccountCircle',
						navigateTo: '/profile/account',
						variant: 'text',
					},
					{
						label: 'Abmelden',
						variant: 'outlined',
						onClick: handleLogout,
					},
			  ]
		: [
				{
					label: 'Registrieren',
					navigateTo: '/signup',
					variant: 'outlined',
				},
				{
					label: 'Anmelden',
					navigateTo: '/login',
					variant: 'outlined',
				},
		  ];
};

export const useDesktopNavButtons = (): NavButton[] => {
	const showTeachAndPricing = useShowTeachAndPricing();

	const desktopNavButtons = [
		{
			label: 'Lernen',
			navigateTo: '/learn',
			variant: 'text',
		},
		...(showTeachAndPricing
			? [
					{
						label: 'Unterrichten',
						navigateTo: '/teach',
						variant: 'text',
					},
					{
						label: 'bf PRO',
						navigateTo: '/pricing',
						variant: 'text',
					},
			  ]
			: []),
		{
			label: 'Über uns',
			navigateTo: '/about-us',
			variant: 'text',
		},
		{
			label: 'Favoriten',
			navigateTo: '/favourites',
			iconName: 'FavoriteBorder',
		},
	];

	return desktopNavButtons as NavButton[];
};

export const useMobileNavButtons = () => {
	const currentUser = useCurrentUser();

	const showTeachAndPricing = useShowTeachAndPricing();

	const mobileNavButtons = [
		{
			label: 'Lernen',
			navigateTo: '/learn',
			variant: 'text',
			iconName: 'LocalLibraryOutlined',
		},
		...(showTeachAndPricing
			? [
					{
						label: 'Unterrichten',
						navigateTo: '/teach',
						variant: 'text',
						iconName: 'SchoolOutlined',
					},
			  ]
			: []),
		{
			label: 'Favoriten',
			navigateTo: '/favourites',
			iconName: 'FavoriteBorder',
		},
		...(currentUser
			? [
					{
						label: 'Mein Profil',
						navigateTo: '/profile/account',
						variant: 'text',
						iconName: 'AccountCircleOutlined',
						children: [
							{
								label: 'Mein Konto',
								navigateTo: '/profile/account',
								variant: 'text',
							},
							{
								label: 'Mitgliedschaft',
								navigateTo: '/profile/membership',
								variant: 'text',
							},
							{
								label: 'Einstellungen',
								navigateTo: '/profile/settings',
								variant: 'text',
							},
						],
					},
			  ]
			: []),
		...(showTeachAndPricing
			? [
					{
						label: 'bf PRO',
						navigateTo: '/pricing',
						variant: 'text',
						iconName: 'Bildungsfreaks',
					},
			  ]
			: []),
		{
			label: 'Hilfe und FAQ',
			navigateTo: '/faq',
			variant: 'text',
			iconName: 'InfoOutlined',
		},
	];

	return mobileNavButtons as NavButton[];
};
