import client from '../utils/client';
import { gql } from '@apollo/client';

type ErrorType =
	| 'UNEXPECTED_ERROR'
	| 'EMAIL_ALREADY_TAKEN'
	| 'NETWORK_ERROR'
	| 'EMAIL_INVALID'
	| 'PASSWORD_INVALID'
	| 'TERMS_NOT_ACCEPTED'
	| 'SEND_CONFIRMATION_EMAIL_FAILED';

const register = async ({
	firstName,
	lastName,
	email,
	password,
	audienceCategoryId,
	organizationName,
	userName,
	termsAcceptedVersion,
}: {
	firstName;
	lastName: string;
	email: string;
	password: string;
	audienceCategoryId: string;
	organizationName?: string;
	userName?: string;
	termsAcceptedVersion: number;
}): Promise<{ success: true; error: undefined } | { success: false; error: ErrorType }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation Register(
					$email: String!
					$password: String!
					$firstName: String!
					$lastName: String!
					$audienceCategoryId: ID!
					$organizationName: String
					$userName: String
					$termsAcceptedVersion: Int!
				) {
					register(
						email: $email
						password: $password
						firstName: $firstName
						lastName: $lastName
						audienceCategoryId: $audienceCategoryId
						organizationName: $organizationName
						userName: $userName
						termsAcceptedVersion: $termsAcceptedVersion
					) {
						success
					}
				}
			`,
			variables: {
				email,
				password,
				firstName,
				lastName,
				audienceCategoryId,
				organizationName,
				userName,
				termsAcceptedVersion,
			},
			errorPolicy: 'all',
		});
		if (errors) {
			return { success: false, error: errors[0]?.extensions?.code as ErrorType };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default register;
