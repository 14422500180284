import React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';

import Txt from './Txt';
import type { Product } from '../utils/product';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const Column = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const BreakLine = styled(Box)`
	width: 100%;
	height: 0px;
	border-top: 1px solid black;
	margin: 0.5rem 0;
`;

const RowWrapper = ({ children, m }: { children: JSX.Element[]; m?: string }) => {
	return (
		<Row m={m}>
			<Box flex="1">{children[0]}</Box>
			<Box>{children[1]}</Box>
		</Row>
	);
};

const OrderSummary = ({
	priceLabel,
	products,
	p,
	m,
}: {
	priceLabel: string;
	products: Product[];
	p?: string;
	m?: string;
}): JSX.Element => {
	return (
		<Column p={p} m={m}>
			<Txt m="0 0 1rem" fontWeight="bold" variant="h6">
				Zusammenfassung
			</Txt>
			<RowWrapper m="0 0 0.5rem">
				<Txt variant="body2" fontWeight="bold">
					Paket
				</Txt>
				<Txt variant="body2" fontWeight="bold">
					Preis
				</Txt>
			</RowWrapper>
			{products.map(({ id, title, priceLabel }) => (
				<RowWrapper key={id} m="0 0 0.5rem">
					<Txt>{title}</Txt>
					<Txt fontWeight="bold">{priceLabel}</Txt>
				</RowWrapper>
			))}

			<BreakLine />
			<RowWrapper>
				<Txt>Zwischensumme</Txt>
				<Txt>{priceLabel}</Txt>
			</RowWrapper>
			<BreakLine />
			<RowWrapper m="0 0 0.5rem">
				<Txt>Total</Txt>
				<Txt fontWeight="bold">{priceLabel}</Txt>
			</RowWrapper>
		</Column>
	);
};

export default OrderSummary;
