import React from 'react';
import { gql, useQuery } from '@apollo/client';

export const CompanyInfoFragment = gql`
	fragment CompanyInfoFragment on Company {
		id
		title
		active
	}
`;

export const COMPANIES_QUERY = gql`
	query Companies {
		companies {
			...CompanyInfoFragment
		}
	}
	${CompanyInfoFragment}
`;

export const COMPANY_QUERY = gql`
	query Company($id: ID!) {
		company(id: $id) {
			...CompanyInfoFragment
		}
	}
	${CompanyInfoFragment}
`;

export type Company = {
	id: string;
	title: string;
	active: boolean;
};

export const useCompanies = (): undefined | null | Company[] => {
	const { data } = useQuery(COMPANIES_QUERY);
	return React.useMemo(() => data?.companies, [data?.companies]);
};

export const useCompany = (id?: string): undefined | null | Company => {
	const { data } = useQuery(COMPANY_QUERY, { variables: { id }, skip: !id });
	return React.useMemo(() => (!id ? null : data?.company), [data?.company, id]);
};
