import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import Button from './Button';

import { type CategoryGroupName, useCategoriesOfGroup, Category } from '../utils/category';

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Row = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow-y: scroll;
	flex-wrap: wrap;
`;

const CategoriesRow = ({
	categories,
	m,
	p,
}: {
	categories?: Category[];
	m?: string;
	p?: string;
}): JSX.Element => {
	return (
		<Wrapper m={m} p={p}>
			{!categories ? (
				<CircularProgress />
			) : (
				<Row>
					{categories.map(({ id, type, title }) => (
						<Button
							key={type}
							variant="secondButton"
							m="0 1rem 1rem 0"
							id={type}
							navigateTo={`/category/${id}`}
						>
							{title}
						</Button>
					))}
				</Row>
			)}
		</Wrapper>
	);
};

export default React.memo(CategoriesRow);
