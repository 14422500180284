import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';

import { useSnackbar } from '../utils/snackBar';
import { triggerDownload } from '../utils/download';
import SectionHeader from './SectionHeader';
import Button from './Button';
import SectionWrapper from './SectionWrapper';
import Txt from './Txt';
import SimpleTable2 from './SimpleTable2';
import createCertificateDownloadLink from '../mutations/createCertificateDownloadLink';
import { useCurrentUser } from '../utils/user';

const PdfIcon = styled(PictureAsPdf)`
	color: red;
`;

const headRowData = [
	{ id: 'title', text: 'Name' },
	{ id: 'createdAt', text: 'Abgeschlossen am' },
	{ id: 'actions', text: '' },
];

const CertificatesSection = () => {
	const snackBar = useSnackbar();
	const currentUser = useCurrentUser();
	const certificates = currentUser?.certificates;
	const sortedCertificates = React.useMemo(() => {
		const copy = certificates ? [...certificates] : null;
		return copy?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
	}, [certificates]);

	const handleDownloadPdf = React.useCallback(
		async (certId) => {
			const { success, error, presignedUrl } = await createCertificateDownloadLink(certId);
			if (success) {
				triggerDownload(presignedUrl);
			} else {
				snackBar({ error });
			}
		},
		[snackBar]
	);

	const rows = React.useMemo(
		() =>
			sortedCertificates?.map((certificate) => {
				return [
					{
						id: 'title',
						jsx: (
							<Box display="flex" flexDirection="row" alignItems="center">
								<Box m="0 0.5rem 0 0">
									<PdfIcon />
								</Box>
								<Txt fontWeight="bold" numberOfLines={2}>
									{certificate.content.title}
								</Txt>
							</Box>
						),
					},
					{ id: 'createdAt', text: certificate.createdAt },
					{
						id: 'actions',
						jsx: (
							<Box display="flex" flexDirection="row" alignItems="center">
								{/* <Button
									variant="secondButton"
									onClick={handleShowPdf}
									id={certificate.id}
									m="0 1rem 0 0"
								>
									Anzeigen
								</Button> */}
								<Button variant="secondButton" onClick={handleDownloadPdf} id={certificate.id}>
									Download
								</Button>
							</Box>
						),
					},
				];
			}),
		[handleDownloadPdf, sortedCertificates]
	);

	return rows?.length ? (
		<SectionWrapper padding="2rem" flexDirection="column" altBackground>
			<SectionHeader alignItems="flex-start" title="Zertifikate" />
			<SimpleTable2 head={headRowData} rows={rows} />
		</SectionWrapper>
	) : null;
};
export default CertificatesSection;
