import client from '../utils/client';
import { gql } from '@apollo/client';
import { VolumeLicenseInfoFragment } from '../utils/volumeLicense';

type Error = 'NETWORK_ERROR' | 'UNAUTHORIZED' | 'COMPANY_ALREADY_EXISTS';

const createVolumeLicense = async ({
	companyId,
	audienceCategoryId,
	isLimited,
	limitedUnit,
	limitedFactor,
	numberOfLicenses,
	productIds,
}: {
	companyId: string;
	audienceCategoryId: string;
	isLimited: boolean;
	limitedUnit?: 'YEAR' | 'MONTH' | 'WEEK' | 'DAY';
	limitedFactor?: number;
	numberOfLicenses: number;
	productIds: string[];
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation CreateVolumeLicense(
					$companyId: ID!
					$audienceCategoryId: ID!
					$isLimited: Boolean!
					$limitedUnit: TimeUnit
					$limitedFactor: Int
					$numberOfLicenses: Int!
					$productIds: [ID!]!
				) {
					createVolumeLicense(
						companyId: $companyId
						audienceCategoryId: $audienceCategoryId
						isLimited: $isLimited
						limitedUnit: $limitedUnit
						limitedFactor: $limitedFactor
						numberOfLicenses: $numberOfLicenses
						productIds: $productIds
					) {
						...VolumeLicenseInfoFragment
					}
				}
				${VolumeLicenseInfoFragment}
			`,
			variables: {
				companyId,
				isLimited,
				limitedUnit,
				limitedFactor,
				numberOfLicenses,
				productIds,
				audienceCategoryId,
			},
			refetchQueries: ['VolumeLicenses'],
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
			};
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default createVolumeLicense;
