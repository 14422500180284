import React from 'react';

import Txt, { TxtProps } from './Txt';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Row = ({
	cells,
	textVariant,
}: {
	cells: { id: string; text?: string; jsx?: JSX.Element }[];
	textVariant?: TxtProps['variant'];
}) => {
	return (
		<TableRow>
			{cells.map(({ id, text, jsx }) => (
				<TableCell key={id}>{text ? <Txt variant={textVariant}>{text}</Txt> : jsx}</TableCell>
			))}
		</TableRow>
	);
};

export type RowData = { id: string; text?: string; jsx?: JSX.Element }[];

const SimpleTable2 = ({
	head,
	headTextVariant = 'h6',
	rows,
}: {
	head?: RowData;
	headMargin?: string;
	headTextVariant?: TxtProps['variant'];
	rows: RowData[];
}) => {
	return (
		<TableContainer>
			<Table>
				{head ? (
					<TableHead>
						<Row cells={head} textVariant={headTextVariant} />
					</TableHead>
				) : null}

				<TableBody>
					{rows?.map((row, index) => (
						<Row key={index} cells={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
export default SimpleTable2;
