import React from 'react';
import styled from 'styled-components';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { Box, CircularProgress } from '@mui/material';

import { useAllMemberships, AppFunctionality, Membership } from '../utils/membership';
import Txt from './Txt';
import { BoxProps } from '@mui/system';

const CheckCircleIcon = styled(CheckCircle)`
	width: 1.75rem;
	height: 1.75rem;
	color: ${(p) => p.theme.customColors.lightBlue};
	margin: 0 0.5rem 0 0;
`;

const CancelIcon = styled(Cancel)`
	width: 1.75rem;
	height: 1.75rem;
	color: ${(p) => p.theme.customColors.darkGrey};
	margin: 0 0.5rem 0 0;
`;

const sortDict = {
	ACCESS_LEVEL_1: 1,
	FAVOURITES: 2,
	CERTIFICATES: 3,
	DASHBOARD: 4,
	SHARE_CONTENTS_TEACHER: 5,
	SHARE_CONTENTS_PARENTS: 5,
	SHARE_CONTENTS: 5,
	ACCESS_LEVEL_2: 6,
	ACCESS_LEVEL_3: 7,
	PRIORITY_ACCESS: 8,
};

const MembershipFeatures = ({
	appFunctionalities,
	...rest
}: {
	appFunctionalities: Membership['appFunctionalities'];
} & BoxProps): JSX.Element => {
	const allMemberships = useAllMemberships();

	const allPossibleFunctionalities = React.useMemo(
		() =>
			allMemberships
				?.reduce<AppFunctionality[]>((acc, nextMembership) => {
					const nextFunctionalities = nextMembership.appFunctionalities;
					nextFunctionalities.forEach((fn) =>
						acc.some((accFn) => accFn.type === fn.type) ? null : acc.push(fn)
					);
					return acc;
				}, [])
				.sort((a, b) => sortDict[a.type] - sortDict[b.type]),
		[allMemberships]
	);

	return !allMemberships ? (
		<CircularProgress />
	) : (
		<Box display="flex" flexDirection="column" {...rest}>
			{allPossibleFunctionalities?.map(({ type: entryType, description: entryDescription }) => (
				<Box
					m="0.5rem 0"
					display="flex"
					flexDirection="row"
					alignItems="center"
					width="100%"
					key={entryType}
				>
					{appFunctionalities.some((fn) => fn.type === entryType) ? (
						<CheckCircleIcon />
					) : (
						<CancelIcon />
					)}

					<Txt>{entryDescription}</Txt>
				</Box>
			))}
		</Box>
	);
};

export default MembershipFeatures;
