import React from 'react';
import { Box } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { BoxProps } from '@mui/material/Box';
import { useSectionWrapperPadding } from '../utils/hooks';

interface ExtendedBoxProps extends BoxProps {
	altBackground?: boolean;
	debug?: boolean;
}

const Wrapper = styled(Box)`
	width: 100%;
	background-color: ${(p) => (p.$altBackground ? p.theme.customColors.decentBlue : 'none')};
	display: flex;
`;

const SectionWrapper = React.forwardRef<HTMLDivElement, ExtendedBoxProps>(
	({ altBackground, children, ...rest }, ref) => {
		const padding = useSectionWrapperPadding();
		const theme = useTheme();
		const { sectionVerticalSpacingRem } = theme;

		return (
			<Wrapper
				ref={ref}
				$altBackground={altBackground}
				m={`${sectionVerticalSpacingRem}rem 0 0 0`}
				p={altBackground ? null : `0 ${padding}px`}
				{...rest}
			>
				{children}
			</Wrapper>
		);
	}
);

export default React.memo(SectionWrapper);
