import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { useApiFilteredContents } from '../utils/content';
import Button from '../components/Button';
import ImageHeader from '../components/ImageHeader';
import ContentWrapper from '../components/ContentWrapper';
import ProBannerSection from '../components/ProBannerSection';
import SectionHeader from '../components/SectionHeader';

import PerksSection from '../components/PerksSection';
import SectionWrapper from '../components/SectionWrapper';

import Contents from '../components/Contents';
import CategoriesRow from '../components/CategoriesRow';
import PublicStatisticsBar from '../components/PublicStatisticsBar';

import image from '../images/BF_Header_Home_Zuschnitt.png';
import TargetGroupsSection from '../components/TargetGroupsSection';
import DemosSection from '../components/DemosSection';
import BildungsfreaksFavouritesSection from '../components/BildungsfreaksFavouritesSection';
import { useCategoriesOfGroup } from '../utils/category';

const Relative = styled(Box)`
	position: relative;
`;

const LoggedOutView = (): JSX.Element => {
	const renderContent = React.useCallback(() => {
		return (
			<Button navigateTo="/pricing" m="1rem 0 0" variant="mainButton">
				Jetzt starten
			</Button>
		);
	}, []);

	const priorityContents = useApiFilteredContents({ contentHasPriority: true });

	const collectionCategories = useCategoriesOfGroup('COLLECTION');

	return (
		<ContentWrapper>
			<Relative>
				<ImageHeader
					height={40}
					src={image}
					title="Smart lernen. Smart unterrichten"
					subTitle="Jederzeit und überall."
					renderContent={renderContent}
				/>
				<PublicStatisticsBar />
			</Relative>
			<Box m={`0 0 1.5rem 0`} />
			<SectionWrapper>
				<Contents
					filterAfterCategoryGroup="AUDIENCE"
					title="Es gibt viel zu entdecken"
					subTitle="Schau dir unsere Top-Kurse an!"
					navigateTo="SELECTED_TYPE"
					contents={priorityContents}
					maxItems={6}
					buttonLabel="Mehr entdecken"
				/>
			</SectionWrapper>
			<SectionWrapper altBackground padding="2rem" flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title="Zukunftsthemen sind unser Ding"
					subTitle="Finde die passenden Kurse nach Themengiebieten"
				/>
				<CategoriesRow categories={collectionCategories || undefined} />
			</SectionWrapper>
			<PerksSection />
			<ProBannerSection />
			<BildungsfreaksFavouritesSection />
			<TargetGroupsSection />
			<DemosSection />
		</ContentWrapper>
	);
};

export default LoggedOutView;
