import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import PaypalPayment from '../components/PaypalPayment';
import { useSnackbar } from '../utils/snackBar';

// type PaypalOrderDetails = {
// 	create_time: string;
// 	id: string;
// 	intent: 'CAPTURE';
// 	status: 'COMPLETED';
// 	links: { href: string; method: string; rel: string }[];
// 	payer: unknown;
// };

const CheckoutView = () => {
	const snackBar = useSnackbar();
	const handleSuccess = React.useCallback(
		(paymentType: string) => {
			snackBar({ success: paymentType + ' erfolgreich' });
		},
		[snackBar]
	);

	return (
		<ContentWrapper center minHeight="10rem">
			<PaypalPayment onSuccess={handleSuccess} />
		</ContentWrapper>
	);
};

export default CheckoutView;
