import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

import Txt from './Txt';
import Button from './Button';

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const SectionHeader = ({
	title,
	subTitle,
	m = '0 0 2rem 0',
	p,
	alignItems = 'center',
	buttonLabel,
	onClick,
}: {
	title?: string;
	subTitle?: string;
	m?: string;
	p?: string;
	alignItems?: 'center' | 'flex-start';
	buttonLabel?: string;
	onClick?: () => void;
}): JSX.Element => {
	return (
		<Wrapper m={m} p={p} alignItems={alignItems}>
			{title ? (
				<Txt align="center" fontWeight="bold" variant="h5">
					{title}
				</Txt>
			) : null}
			{subTitle ? (
				<Txt variant="h6" m="0.5rem 0 0">
					{subTitle}
				</Txt>
			) : null}
			{onClick ? (
				<Button variant="mainButton" onClick={onClick} m="1rem 0 0">
					{buttonLabel}
				</Button>
			) : null}
		</Wrapper>
	);
};

export default SectionHeader;
