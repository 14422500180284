import React from 'react';
import styled, { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import { CircularProgress, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ContentWrapper from '../components/ContentWrapper';
import Button from '../components/Button';
import { useCurrentUser, logout } from '../utils/user';
import ImageBox from '../components/ImageBox';
import Txt from '../components/Txt';
import GridContainer from '../components/GridContainer';

import TextField, { TextFieldRef } from '../components/TextField';
import { useInitializeStateWithDefault, useIsMobile } from '../utils/hooks';
import updateUser from '../mutations/updateUser';
import { useSnackbar } from '../utils/snackBar';
import Dialog from '../components/Dialog';
import CookieBannerDialog from '../components/CookieBannerDialog';
import { CONTACT_EMAIL } from '../consts';

import { useNavigate } from '../utils/hooks';
import MembershipFeatures from '../components/MembershipFeatures';
import image from '../images/BF_Grafik_User_Avatar.svg';
import SearchSelectField2 from '../components/SearchSelectField2';

const Column = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
`;

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
`;

const navButtons = [
	{
		label: 'Mein Konto',
		navigateTo: '/profile/account',
	},
	{
		label: 'Mitgliedschaft',
		navigateTo: '/profile/membership',
	},
	{
		label: 'Einstellungen',
		navigateTo: '/profile/settings',
	},
];

const useActionButtonProps = (): {
	label: string;
	navigateTo?: string;
	onClick?: (argument?: string) => void;
}[] => {
	const navigate = useNavigate();
	const handleShowDeletionDialog = React.useCallback(() => {
		Dialog.render({
			title: 'Konto löschen',
			description: `Möchtest du dein Konto wirklich löschen? Dann schreibe uns eine E-Mail mit dem Betreff "Konto löschen" an [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL})`,
			isMarkdown: true,
		});
	}, []);

	const handleLogout = React.useCallback(() => {
		logout(navigate);
	}, [navigate]);

	return [
		{
			label: 'Abmelden',
			onClick: handleLogout,
		},
		{
			label: 'Konto löschen',
			onClick: handleShowDeletionDialog,
		},
	];
};

const LeftNavigation = () => {
	const location = useLocation();

	const subRoute = location.pathname.split('/').pop();

	const currentUser = useCurrentUser();
	const actionButtonProps = useActionButtonProps();
	return (
		<Column width="20rem">
			<ImageBox width="10rem" height="10rem" src={image} />
			<Txt m="2rem 0 4rem" variant="h5" fontWeight="bold">
				{currentUser?.firstName} {currentUser?.lastName}
			</Txt>
			{navButtons.map(({ label, navigateTo }) => (
				<Button
					key={label}
					navigateTo={navigateTo}
					variant={
						subRoute && navigateTo.includes(subRoute)
							? 'activeNavigationSelectButton'
							: 'inActiveNavigationSelectButton'
					}
				>
					{label}
				</Button>
			))}
			<Box m="1rem" />
			{actionButtonProps.map(({ label, navigateTo, onClick }) => (
				<Button key={label} navigateTo={navigateTo} onClick={onClick}>
					{label}
				</Button>
			))}
		</Column>
	);
};

const horizontalSpacing = { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 };
const verticalSpacing = { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 };

const Background = styled(Box)`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	background-color: ${(p) => p.theme.customColors.decentBlue};
`;

const defaultGridItemProps = {
	xs: 12,
	sm: 6,
	item: true,
};

const TITLE_PADDING = '0 0 2rem 0';

const EditProfileForm = () => {
	const theme = useTheme();
	const currentUser = useCurrentUser();
	const snackBar = useSnackbar();
	const [loading, setLoading] = React.useState(false);
	const finishedInitializing = currentUser !== undefined;

	const [lastName, setLastName] = useInitializeStateWithDefault({
		defaultValue: currentUser?.lastName,
		finishedInitializing,
	});
	const [firstName] = useInitializeStateWithDefault({
		defaultValue: currentUser?.firstName,
		finishedInitializing,
	});

	const handleChangeLastName = React.useCallback(
		(newLastName) => {
			setLastName(newLastName);
		},
		[setLastName]
	);

	const [organizationName, setorganizationName] = useInitializeStateWithDefault({
		defaultValue: currentUser?.organizationName,
		finishedInitializing,
	});
	const handleChangeorganizationName = React.useCallback(
		(neworganizationName) => {
			setorganizationName(neworganizationName);
		},
		[setorganizationName]
	);

	const organizationNameTextFieldRef = React.useRef<TextFieldRef>(null);
	const lastNameTextFieldRef = React.useRef<TextFieldRef>(null);

	const handleSave = React.useCallback(async () => {
		setLoading(true);
		const { error } = await updateUser({
			lastName,
			organizationName,
		});
		setLoading(false);
		if (error) {
			snackBar({ error });
		} else {
			snackBar({ success: 'Änderungen gespeichert' });
		}
	}, [lastName, organizationName, snackBar]);

	return !finishedInitializing ? (
		<CircularProgress />
	) : (
		<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
			<Grid item xs={12}>
				<Txt variant="h5" fontWeight="bold" m={TITLE_PADDING}>
					Mein Konto
				</Txt>
			</Grid>
			<Grid {...defaultGridItemProps}>
				<TextField
					variant="filled"
					label="Vorname"
					defaultValue={firstName}
					// onChange={handleChangeLastName}
					backgroundColor={theme.palette.common.white}
					disabled
				/>
			</Grid>
			<Grid {...defaultGridItemProps}>
				<TextField
					variant="filled"
					label="Nachname"
					defaultValue={lastName}
					onChange={handleChangeLastName}
					backgroundColor={theme.palette.common.white}
					ref={lastNameTextFieldRef}
				/>
			</Grid>
			<Grid {...defaultGridItemProps}>
				<TextField
					variant="filled"
					label="Organisation"
					defaultValue={organizationName}
					onChange={handleChangeorganizationName}
					backgroundColor={theme.palette.common.white}
					ref={organizationNameTextFieldRef}
				/>
			</Grid>
			<Grid item xs={12}>
				<Row justifyContent="flex-end" alignItems="center">
					<Button variant="mainButton" onClick={handleSave} loading={loading}>
						Speichern
					</Button>
				</Row>
			</Grid>
			<Grid item xs={12}>
				<Row justifyContent="flex-start" alignItems="center">
					<Button variant="blueText" navigateTo="/reset-password" loading={loading}>
						Passwort ändern
					</Button>
				</Row>
			</Grid>
		</GridContainer>
	);
};

const SettingsView = () => {
	const handleShowCookieBanner = React.useCallback(() => {
		Dialog.render({
			renderContent: CookieBannerDialog,
		});
	}, []);

	return (
		<Column m="2rem">
			<Txt variant="h5" fontWeight="bold" m={TITLE_PADDING}>
				Einstellungen
			</Txt>
			<Txt m="0 0 2rem 0">
				Hier kannst du die Datenschutzvereinbarung einsehen und Datenschutzeinstellungen vornehmen
			</Txt>
			<Button variant="blueText" navigateTo="/data-privacy">
				Aktuelle Datenschutzvereinbarung
			</Button>
			<Button variant="blueText" navigateTo="/terms">
				Nutzungsbedingungen
			</Button>
			<Button variant="blueText" onClick={handleShowCookieBanner}>
				Cookie-Settings
			</Button>
		</Column>
	);
};

const MembershipView = () => {
	const currentUser = useCurrentUser();
	const membership = currentUser?.membership;

	const options = React.useMemo(() => (membership ? [membership] : []), [membership]);

	return !currentUser || membership === undefined ? (
		<CircularProgress />
	) : (
		<Column m="2rem">
			<Txt variant="h5" fontWeight="bold" m={TITLE_PADDING}>
				Meine Mitgliedschaft
			</Txt>
			<Txt>Verwalte hier deine Mitgliedschaft</Txt>
			<SearchSelectField2
				options={options}
				defaultValues={membership?.id}
				labelKey="title"
				valueKey="id"
				m="2rem 0 0 0"
				label="Deine laufende Mitgliedschaft"
				backgroundColor="white"
				width="100%"
				disableClearable
			/>
			{membership ? (
				<MembershipFeatures m="2rem 0 0 0" appFunctionalities={membership.appFunctionalities} />
			) : null}
		</Column>
	);
};

const ProfileView = () => {
	const location = useLocation();
	const isMobile = useIsMobile();
	const activeView = location.pathname.includes('membership')
		? 'MEMBERSHIP'
		: location.pathname.includes('settings')
		? 'SETTINGS'
		: 'ACCOUNT';

	return (
		<ContentWrapper p="2rem">
			<Row width="100%">
				{isMobile ? null : <LeftNavigation />}
				<Background>
					{activeView === 'ACCOUNT' ? (
						<EditProfileForm />
					) : activeView === 'MEMBERSHIP' ? (
						<MembershipView />
					) : activeView === 'SETTINGS' ? (
						<SettingsView />
					) : null}
				</Background>
			</Row>
		</ContentWrapper>
	);
};

export default ProfileView;
