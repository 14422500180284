import React from 'react';
import { gql, useQuery } from '@apollo/client';

export const VolumeLicenseInfoFragment = gql`
	fragment VolumeLicenseInfoFragment on VolumeLicense {
		id
		companyId
		isLimited
		limitedUnit
		limitedFactor
		numberOfLicenses
		takenSeats
		remainingSeats
		products {
			id
			title
		}
		company {
			id
			title
		}
		users {
			id
			firstName
			lastName
			email
		}
		createdAt
		active
		userAudienceType
	}
`;

export const VOLUME_LICENSES_QUERY = gql`
	query VolumeLicenses {
		volumeLicenses {
			...VolumeLicenseInfoFragment
		}
	}
	${VolumeLicenseInfoFragment}
`;

export const VOLUME_LICENSE_QUERY = gql`
	query VolumeLicense($id: ID!) {
		volumeLicense(id: $id) {
			...VolumeLicenseInfoFragment
		}
	}
	${VolumeLicenseInfoFragment}
`;

export type VolumeLicense = {
	id: string;
	companyId: string;
	isLimited: boolean;
	limitedUnit?: 'YEAR' | 'MONTH' | 'WEEK' | 'DAY';
	limitedFactor?: number;
	numberOfLicenses: number;
	takenSeats: number;
	remainingSeats: number;
	products: { id: string; title: string }[];
	company: { id: string; title: string };
	users: { id: string; email: string }[];
	createdAt: string;
	active: boolean;
	statistics?: string;
	userAudienceType: 'TEACHER' | 'PARENTS' | 'PUPILS' | 'COMPANY';
};

export const useVolumeLicenses = (): undefined | null | VolumeLicense[] => {
	const { data } = useQuery(VOLUME_LICENSES_QUERY);
	return React.useMemo(() => data?.volumeLicenses, [data?.volumeLicenses]);
};

export const useVolumeLicense = (id?: string): undefined | null | VolumeLicense => {
	const { data } = useQuery(VOLUME_LICENSE_QUERY, { variables: { id }, skip: !id });
	return React.useMemo(() => (!id ? null : data?.volumeLicense), [data?.volumeLicense, id]);
};

export const useVolumeLicenseStatistics = (id?: string): string => {
	const { data } = useQuery(
		gql`
			query VolumeLicense($id: ID!) {
				volumeLicense(id: $id) {
					statistics
				}
			}
		`,
		{ variables: { id }, skip: !id }
	);
	return React.useMemo(() => data?.volumeLicense?.statistics, [data?.volumeLicense?.statistics]);
};
