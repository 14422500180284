import React from 'react';
import { Box, TypographyProps, Grid } from '@mui/material';
import styled from 'styled-components';
import ImageBox from './ImageBox';
import GridContainer from './GridContainer';
import Txt from './Txt';
import { useActiveBreakPoint } from '../utils/theme';
import MarkdownTxt from './MarkdownTxt';

const Column = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const breakPointVariantDict: { [key: string]: TypographyProps['variant'] } = {
	xs: 'h6',
	sm: 'h5',
	md: 'h4',
	lg: 'h4',
	xl: 'h4',
};

const gridItemProps = {
	item: true,
	xs: 12,
	sm: 12,
	md: 6,
};

const horizontalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const verticalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };

const GreyGrid = styled(Grid)`
	background-color: ${(p) => p.theme.customColors.decentBlue};
`;
type ObjectFit = 'fill' | 'cover' | 'contain' | 'none' | 'scale-down';
const ImageHeader = ({
	src,
	title,
	subTitle,
	description,
	markdownDescription,
	height = 20,
	objectFit = 'contain',
	p = '2rem',
	renderContent,
}: {
	src: string;
	title?: string;
	subTitle?: string;
	description?: string;
	markdownDescription?: string;
	height?: number;
	objectFit?: ObjectFit;
	p?: string;
	renderContent?: () => JSX.Element;
}) => {
	const activeBreakPoint = useActiveBreakPoint();

	return (
		<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
			<GreyGrid {...gridItemProps}>
				<Column p={p}>
					{title ? (
						<Txt m="0 0 1rem 0" fontWeight="bold" variant={breakPointVariantDict[activeBreakPoint]}>
							{title}
						</Txt>
					) : null}
					{subTitle ? (
						<Txt m="0 0 1rem 0" variant={breakPointVariantDict[activeBreakPoint]}>
							{subTitle}
						</Txt>
					) : null}
					{description ? <Txt variant="body1">{description}</Txt> : null}
					{markdownDescription ? <MarkdownTxt>{markdownDescription}</MarkdownTxt> : null}
					{renderContent ? renderContent() : null}
				</Column>
			</GreyGrid>
			<GreyGrid {...gridItemProps}>
				<Column alignItems="center">
					<ImageBox objectFit={objectFit} src={src} maxHeight={`${height}rem`} />
				</Column>
			</GreyGrid>
		</GridContainer>
	);
};

export default React.memo(ImageHeader);
