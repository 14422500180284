/*eslint-disable react-perf/jsx-no-jsx-as-prop */
import React from 'react';
import styled from 'styled-components';
import { CssBaseline, CircularProgress, Box } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider, type SnackbarOrigin } from 'notistack';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { PAYPAL_CLIENT_ID } from './consts';

import client from './utils/client';
import { useCreateTheme } from './utils/theme';
import PortalProvider from './components/PortalProvider';
import AppBar from './components/AppBar';
import AdminAppBar from './components/AdminAppBar';
import Footer from './components/Footer';
import ContentWrapper from './components/ContentWrapper';
import Txt from './components/Txt';
import Logo from './components/Logo';

import { useAppState } from './utils/app';
import {
	useCurrentUser,
	usePotentiallyRequestPasswordReset,
	usePotentiallyRequestTermsAcceptance,
} from './utils/user';
import { useMaintenanceInfo } from './utils/statistics';
import { CookieProvider, usePotentiallyCookieBanner } from './utils/cookie';
import YoutubeIframeView from './screens/YoutubeIframe';

import {
	usePotentiallyHandleLeavePlayerView,
	usePotentiallyScrollToTop,
	useTrackPageViews,
} from './utils/hooks';

import { routes, oldAppReroutes, adminRoutes } from './routes';

const anchorOrign = {
	horizontal: 'center',
	vertical: 'bottom',
} as SnackbarOrigin;

const MainScreen = styled(Box)`
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const userRoutes = [...routes, ...oldAppReroutes];

const Navigator = () => {
	const appState = useAppState();
	const maintenanceInfo = useMaintenanceInfo();
	const currentUser = useCurrentUser();
	const isAdminUser = currentUser?.isAdminUser;
	const isAccessPhraseUser = currentUser?.isAccessPhraseUser;

	usePotentiallyRequestPasswordReset();
	usePotentiallyRequestTermsAcceptance();
	usePotentiallyCookieBanner();
	usePotentiallyScrollToTop();
	usePotentiallyHandleLeavePlayerView();
	useTrackPageViews();

	return (
		<MainScreen>
			<CssBaseline />
			{maintenanceInfo?.isMaintained ? (
				<ContentWrapper center minHeight="10rem">
					<Logo m="0 0 2rem 0" />
					<Txt variant="h6">{maintenanceInfo.title || 'Wartungsarbeiten'} </Txt>
					<Txt>
						{maintenanceInfo?.description ||
							'Es finden aktuell Wartungsarbeiten statt. Bitte versuche es zu einem späteren Zeitpunkt wieder.'}
					</Txt>
				</ContentWrapper>
			) : appState === 'LOADING' ? (
				<CircularProgress size={24} />
			) : isAdminUser ? (
				<>
					<AdminAppBar />
					<Routes>
						{adminRoutes.map(({ accessibility, path, element }) =>
							accessibility.includes(appState) ? (
								<Route key={path} path={path} element={element} />
							) : null
						)}
					</Routes>
				</>
			) : !isAccessPhraseUser ? (
				<>
					<AppBar />
					<Routes>
						{userRoutes.map(({ accessibility, path, element }) =>
							accessibility.includes(appState) ? (
								<Route key={path} path={path} element={element} />
							) : null
						)}
					</Routes>
					<Footer />
				</>
			) : (
				<>
					<AppBar isAccessPhraseUser={true} />
					<Routes>
						{routes
							.filter(({ isAccessPhraseUserRoute }) => Boolean(isAccessPhraseUserRoute))
							.map(({ accessibility, path, element }) =>
								accessibility.includes(appState) ? (
									<Route key={path} path={path} element={element} />
								) : null
							)}
					</Routes>
					<Footer isAccessPhraseUser={true} />
				</>
			)}
		</MainScreen>
	);
};

const initialPaypalOptions = {
	clientId: PAYPAL_CLIENT_ID,
	currency: 'USD',
	intent: 'capture',
};

const App = () => {
	const theme = useCreateTheme();
	const location = useLocation();
	if (location.pathname === '/ytplayer') {
		return (
			<ThemeProvider theme={theme}>
				<CookieProvider>
					<PortalProvider>
						<YoutubeIframeView />
					</PortalProvider>
				</CookieProvider>
			</ThemeProvider>
		);
	}
	return (
		<PayPalScriptProvider options={initialPaypalOptions}>
			<ApolloProvider client={client}>
				<ThemeProvider theme={theme}>
					<CookieProvider>
						<SnackbarProvider maxSnack={3} anchorOrigin={anchorOrign}>
							<PortalProvider>
								<Navigator />
							</PortalProvider>
						</SnackbarProvider>
					</CookieProvider>
				</ThemeProvider>
			</ApolloProvider>
		</PayPalScriptProvider>
	);
};

export default App;
