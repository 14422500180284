import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { useLabelAndValue } from '../utils/hooks';
import { Box, TextFieldProps, TextField } from '@mui/material';
import FormControlWrapper from './FormControlWrapper';
import OldTextField from './OldTextField';
import { useStringArray } from '../utils/helpers';

export type SearchSelectFieldRef2 = {
	validate: () => boolean;
};

export type Option = { label: string; value: string };

const SearchSelectField2 = React.forwardRef<
	SearchSelectFieldRef2,
	{
		options?: { [key: string]: string | unknown }[];
		defaultValues?: string[] | string;
		label?: string;
		multiple?: boolean;
		onChange?: (selectedOptions: Option[]) => void;
		disabled?: boolean;
		labelKey: string;
		valueKey: string;
		width?: string;
		m?: string;
		variant?: TextFieldProps['variant'];
		backgroundColor?: string;
		disableClearable?: boolean;
	}
>(
	(
		{
			options,
			defaultValues,
			label = 'Label',
			multiple,
			onChange,
			disabled,
			labelKey,
			valueKey,
			width,
			m,
			variant,
			backgroundColor,
			disableClearable,
		},
		ref
	) => {
		const defaultValuesArr = useStringArray(defaultValues);

		const transformedOptions = useLabelAndValue({
			items: options,
			labelKey,
			valueKey,
		});

		const defaultOptions = React.useMemo(
			() =>
				!defaultValuesArr?.length
					? undefined
					: transformedOptions
					? transformedOptions.filter((option) => defaultValuesArr.includes(option.value))
					: undefined,
			[defaultValuesArr, transformedOptions]
		);

		const [hasSelected, setHasSelected] = React.useState(false);
		const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

		const handleChange = React.useCallback(
			(_, selectedOptions) => {
				const arrayed = (Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]) as {
					label: string;
					value: string;
				}[];
				setErrorMessage(undefined);
				setHasSelected(Boolean(arrayed?.length));
				onChange?.(arrayed);
			},
			[onChange]
		);

		const renderInput = React.useCallback(
			(props) => {
				return (
					<FormControlWrapper errorMessage={errorMessage} disabled={disabled} variant={variant}>
						<TextField
							fullWidth
							label={label}
							disabled={disabled}
							variant={variant}
							error={Boolean(errorMessage)}
							width={width}
							style={{ backgroundColor }}
							{...props}
						/>
					</FormControlWrapper>
				);
			},
			[backgroundColor, disabled, errorMessage, label, variant, width]
		);

		const handleValidate = React.useCallback(() => {
			const invalid = !hasSelected;
			if (invalid) {
				setErrorMessage('Bitte wählen');
			} else {
				setErrorMessage(undefined);
			}
			return invalid;
		}, [hasSelected]);

		React.useImperativeHandle(ref, () => ({
			validate: handleValidate,
		}));

		React.useEffect(() => {
			if (defaultValuesArr && defaultValuesArr.length > 1 && !multiple) {
				throw new Error(
					'SearchSelectField2: defaultValuesArr has more than one value but multiple is not set to true'
				);
			}
		}, [defaultValuesArr, defaultValuesArr?.length, multiple, transformedOptions]);

		return !transformedOptions?.length ? (
			<OldTextField label={label} variant={variant} m={m} />
		) : (
			<Box width={width} m={m}>
				<Autocomplete
					multiple={multiple}
					options={transformedOptions}
					renderInput={renderInput}
					onChange={handleChange}
					value={multiple ? undefined : defaultOptions?.[0]}
					defaultValue={multiple ? defaultOptions : undefined}
					fullWidth
					disabled={disabled}
					disableClearable={disableClearable}
				/>
			</Box>
		);
	}
);

export default SearchSelectField2;
