import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from '../utils/hooks';
import { useParams } from 'react-router-dom';

import Button from '../components/Button';
import Dialog from '../components/Dialog';
import AuthWrapper from '../components/AuthWrapper';
import FilledTextField from '../components/FilledTextField';
import Txt from '../components/Txt';

import useTextFieldController from '../utils/useTextFieldController';
import accessGeneratorRegister from '../mutations/accessGeneratorRegister';

const AccessGeneratorRegisterView = (): JSX.Element => {
	const { accessGeneratorCode: accessGeneratorCodeFromRoute } = useParams();
	console.log('accessGeneratorCodeFromRoute: ', accessGeneratorCodeFromRoute);
	const navigate = useNavigate();
	const {
		newStateValue: accessGeneratorCode,
		textInputProps: textFieldProps,
		validate: validate,
	} = useTextFieldController({
		defaultValue: accessGeneratorCodeFromRoute,
		inputLabel: 'Code von deiner Lehrer*in',
		stateKey: 'accessGeneratorCode',
		validationFunction: (val) => (!val ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
		textFieldType: 'accessGeneratorCode',
	});

	const [loading, setLoading] = React.useState(false);
	const [phrase, setPhrase] = React.useState<string | undefined>(undefined);

	const handleNavigate = React.useCallback(() => {
		Dialog.render({
			title: 'Login-Phrase aufgeschrieben?',
			description:
				'Hast du die Login-Phrase aufgeschrieben? Du brauchst sie gleich, um dich anzumelden.',
			buttons: [
				{
					id: '1',
					variant: 'mainButton',
					label: 'Ja',
					onClick: () => {
						navigate('/phrase-login');
					},
				},
				{
					id: '2',
					label: 'Nein',
					onClick: undefined,
				},
			],
		});
	}, [navigate]);

	const handleLogin = React.useCallback(async () => {
		const invalid = validate!();
		if (invalid) {
			return;
		}
		setLoading(true);
		const {
			success,
			error,
			phrase: loginPhrase,
		} = await accessGeneratorRegister(accessGeneratorCode!);
		setLoading(false);
		if (success) {
			setPhrase(loginPhrase);
		} else {
			switch (error) {
				case 'ACCESS_GENERATOR_CODE_INVALID':
					Dialog.render({
						title: 'Fehler',
						description: 'Code ungültig.',
					});
					break;
				case 'ACCESS_GENERATOR_CODE_EXPIRED':
					Dialog.render({
						title: 'Fehler',
						description: 'Code abgelaufen',
					});
					break;
				case 'ACCESS_GENERATOR_CODE_EXHAUSTED':
					Dialog.render({
						title: 'Fehler',
						description: 'Code bereits zu häufig verwendet. Bitte wende dich an deine Lehrer*in',
					});
					break;
				case 'ALREADY_LOGGED_IN':
					Dialog.render({
						title: 'Fehler',
						description: 'Du bist bereits eingeloggt, bitte logge dich zunächst aus.',
					});
					break;
				case 'NETWORK_ERROR':
					Dialog.render({
						title: 'Fehler',
						description: 'Netzwerkfehler',
					});
					break;
				default:
					Dialog.render({
						title: 'Fehler',
						description: 'Es ist ein unbekannter Fehler aufgetreten.',
					});
			}
		}
	}, [accessGeneratorCode, validate]);

	return !phrase ? (
		<AuthWrapper title="Mit Code registrieren">
			<Grid item xs={12}>
				<Txt>
					Nutze den Code, den du erhalten hast, um dich zu registrieren. Du erhälst im Anschluss
					deine persönliche Login-Phrase, mit welcher du dich anschließend anmelden kannst.
				</Txt>
			</Grid>
			<Grid key={textFieldProps.label} item xs={12}>
				<FilledTextField fullWidth variant="filled" {...textFieldProps} />
			</Grid>
			<Grid item xs={12}>
				<Button fullWidth onClick={handleLogin} variant="mainButton" loading={loading}>
					Anmelden
				</Button>
			</Grid>
		</AuthWrapper>
	) : (
		<AuthWrapper title="Deine persönliche Login Phrase">
			<Grid item xs={12}>
				<Txt m="0 0 1rem 0">Mit der folgenden Phrase, kannst du dich nun anmelden.</Txt>
				<Txt m="0 0 1rem 0" variant="h2">
					{phrase}
				</Txt>
				<Txt m="0 0 1rem 0">
					Bitte schreibe dir diese Phrase auf und nutze sie zukünftig zum Anmelden.
				</Txt>
			</Grid>
			<Grid item xs={12}>
				<Button fullWidth onClick={handleNavigate} variant="mainButton" loading={loading}>
					Ok
				</Button>
			</Grid>
		</AuthWrapper>
	);
};

export default AccessGeneratorRegisterView;
