import React from 'react';

import { CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Txt, { TxtProps } from './Txt';

export type Objects = { id: string; [key: string]: unknown }[];
type CellObject = { id: string; text?: string; jsx?: JSX.Element };

const Row = ({
	cells,
	textVariant,
	numberOfLines,
}: {
	cells: CellObject[];
	textVariant?: TxtProps['variant'];
	numberOfLines?: number;
}) => {
	return (
		<TableRow>
			{cells.map(({ id, text, jsx }) => (
				<TableCell key={id}>
					{text ? (
						<Txt numberOfLines={numberOfLines} variant={textVariant}>
							{text}
						</Txt>
					) : (
						jsx
					)}
				</TableCell>
			))}
		</TableRow>
	);
};

const SimpleTable3 = ({
	objects,
	renderActions,
	activeKeys,
	inactiveKeys,
	numberOfLines,
}: {
	objects: Objects;
	renderActions?: (props) => JSX.Element;
	activeKeys?: string[];
	inactiveKeys?: string[];
	numberOfLines?: number;
}) => {
	const mergedInactiveKeys = React.useMemo(
		() => (!inactiveKeys ? ['__typename'] : [...inactiveKeys, '__typename']),
		[inactiveKeys]
	);

	const tableData = React.useMemo(() => {
		const headData = objects
			? ([
					...Object.keys(objects[0])
						.filter((key) =>
							!activeKeys ? true : activeKeys.some((activeKey) => activeKey === key)
						)
						.filter((key) =>
							!mergedInactiveKeys
								? true
								: !mergedInactiveKeys.some((inactiveKey) => inactiveKey === key)
						)
						.map((key) => ({ id: key, text: key })),
					renderActions ? { id: 'actions', text: 'Aktionen' } : undefined,
			  ].filter(Boolean) as CellObject[])
			: [];
		const rowsData = objects?.map(
			(object) =>
				[
					...Object.entries(object)
						.filter(([key]) =>
							!activeKeys ? true : activeKeys.some((activeKey) => activeKey === key)
						)
						.filter(([key]) =>
							!mergedInactiveKeys
								? true
								: !mergedInactiveKeys.some((inactiveKey) => inactiveKey === key)
						)
						.map(([_, value], index) => ({ id: `${object.id}:${index}`, text: String(value) })),
					renderActions ? { id: 'actions', jsx: renderActions?.({ id: object.id }) } : undefined,
				].filter(Boolean) as CellObject[]
		);
		return headData && rowsData ? { head: headData, rows: rowsData } : undefined;
	}, [activeKeys, mergedInactiveKeys, objects, renderActions]);

	return !tableData ? (
		<CircularProgress />
	) : (
		<TableContainer>
			<Table>
				<TableHead>
					<Row cells={tableData.head} />
				</TableHead>

				<TableBody>
					{tableData.rows?.map((row, index) => (
						<Row key={index} cells={row} numberOfLines={numberOfLines} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
export default React.memo(SimpleTable3);
