import React from 'react';
import styled from 'styled-components';

import { useSnackbar } from '../utils/snackBar';
import Box from '@mui/material/Box';
import Txt from '../components/Txt';
import SimpleTable3 from '../components/SimpleTable3';
import OldTextField from '../components/OldTextField';
import Button from '../components/Button';

import useTextFieldController from '../utils/useTextFieldController';
import { useCompanies, useCompany } from '../utils/company';
import createCompany from '../mutations/createCompany';
import updateCompany from '../mutations/updateCompany';
import Dialog, { DialogButtonRow, DialogHeader } from '../components/Dialog';
import ContentWrapper from '../components/ContentWrapper';
import IconButton from '../components/IconButton';
import { useInitializeStateWithDefault } from '../utils/hooks';
import { CircularProgress } from '@mui/material';
import BooleanSelectField from '../components/BooleanSelectField';
import DialogWrapper from '../components/DialogWrapper';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const activeKeys = ['title', 'active'];

const UpdateCompanyDialog = ({
	dismissPortal,
	id,
}: {
	dismissPortal: () => void;
	id?: string;
}): JSX.Element => {
	console.log('UpdateCompanyDialog', { id });
	const company = useCompany(id);
	const snackBar = useSnackbar();

	const [loading, setLoading] = React.useState(false);

	const [title, setTitle, initializedTitle] = useInitializeStateWithDefault({
		defaultValue: company?.title,
		finishedInitializing: company !== undefined,
	});

	const handleChangeTitle = React.useCallback(
		(newTitle) => {
			setTitle(newTitle);
		},
		[setTitle]
	);

	const [active, setActive, initializedActive] = useInitializeStateWithDefault({
		defaultValue: company?.active,
		finishedInitializing: company !== undefined,
	});

	const handleChangeActive = React.useCallback(
		(newActive) => {
			setActive(newActive);
		},
		[setActive]
	);

	const handleUpdateCompany = React.useCallback(async () => {
		setLoading(true);
		const { error } = await updateCompany({
			id: id!,
			title: title,
			active: active,
		});
		setLoading(false);
		if (error) {
			snackBar({ error });
		} else {
			snackBar({ success: 'Unternehmen erfolgreich aktualisiert' });
			dismissPortal();
		}
	}, [id, title, active, snackBar, dismissPortal]);

	const maybeConfirmUpdateCompany = React.useCallback(() => {
		if (company?.active && !active) {
			Dialog.render({
				title: 'Unternehmen deaktivieren',
				description:
					'Die Deaktivierung des Unternehmens führt dazu, dass auch die verknüpften Volumenlizenzen deaktiviert werden. Bist du sicher, dass du das Unternehmen deaktivieren möchtest?',
				buttons: [
					{
						id: 'confirm',
						label: 'Deaktivieren',
						onClick: handleUpdateCompany,
					},
					{
						id: 'cancel',
						label: 'Abbrechen',
						variant: 'mainButton',
					},
				],
			});
		} else {
			handleUpdateCompany();
		}
	}, [company?.active, active, handleUpdateCompany]);

	return initializedActive && initializedTitle ? (
		<DialogWrapper p="2rem">
			<DialogHeader title="Unternehmen editieren" />
			<BooleanSelectField
				onChange={handleChangeActive}
				defaultValue={active}
				width="100%"
				m="1rem 0 0 0"
				label="Aktiv"
			/>
			<DialogButtonRow>
				<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
					Abbrechen
				</Button>

				<Button variant="mainButton" onClick={maybeConfirmUpdateCompany} loading={loading}>
					{id ? 'Speichern' : 'Hinzufügen'}
				</Button>
			</DialogButtonRow>
		</DialogWrapper>
	) : (
		<CircularProgress />
	);
};

const CompanyListView = (): JSX.Element | null => {
	const companies = useCompanies();
	const snackBar = useSnackbar();

	const {
		newStateValue: companyTitle,
		textInputProps: companyTitleFieldProps,
		validate: validateCompanyTitleFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Unternehmensname',
		stateKey: 'companyTitle',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const [loading, setLoading] = React.useState(false);

	const handleCreateCompany = React.useCallback(async () => {
		const invalid = validateCompanyTitleFn!();
		if (invalid) return;
		setLoading(true);
		const { success, error } = await createCompany({ companyTitle: companyTitle! });
		setLoading(false);
		if (success) {
			snackBar({ success: 'Unternehmen erfolgreich erstellt' });
		} else {
			snackBar({ error });
		}
	}, [companyTitle, snackBar, validateCompanyTitleFn]);

	const handleOpenUpsertVolumeLicenseDialog = React.useCallback((id) => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<UpdateCompanyDialog dismissPortal={dismissPortal} id={id} />
			),
			isLocked: true,
		});
	}, []);

	const renderActions = React.useCallback(
		({ id }) => {
			return (
				<IconButton
					m="0 1rem 0 0"
					iconName="EditRounded"
					onClick={handleOpenUpsertVolumeLicenseDialog}
					id={id}
				/>
			);
		},
		[handleOpenUpsertVolumeLicenseDialog]
	);

	return !companies ? (
		<ContentWrapper p="2rem">{null}</ContentWrapper>
	) : (
		<ContentWrapper p="2rem">
			<Txt m="0 0 2rem 0" variant="h6">
				Unternehmen
			</Txt>
			{companies.length === 0 ? null : (
				<SimpleTable3 objects={companies} renderActions={renderActions} activeKeys={activeKeys} />
			)}
			<Row m="2rem 0 0 0">
				<OldTextField {...companyTitleFieldProps} m="0 1rem 0 0" />

				<Button variant="mainButton" onClick={handleCreateCompany} loading={loading}>
					Hinzufügen
				</Button>
			</Row>
		</ContentWrapper>
	);
};

export default CompanyListView;
