/* eslint-disable promise/prefer-await-to-then */
import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import { API_BASE_URL, PAYPAL_CLIENT_ID } from '../consts';
import { getToken } from '../utils/user';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';
import Button from './Button';
import { Box } from '@mui/system';

const orderPaypalOptions = {
	clientId: PAYPAL_CLIENT_ID,
	currency: 'USD',
	intent: 'capture',
};
const subscriptionPaypalOptions = {
	clientId: PAYPAL_CLIENT_ID,
	currency: 'USD',
	intent: 'subscription',
	vault: true,
	components: 'buttons',
};

const PaypalPayment = ({ onSuccess }: { onSuccess: (paymentType) => void }) => {
	const [_, dispatch] = usePayPalScriptReducer();

	const [paymentType, setPaymentType] = React.useState<'ORDER' | 'SUBSCRIPTION'>('ORDER');
	const handleTogglePaymentType = React.useCallback(() => {
		setPaymentType((prev) => (prev === 'ORDER' ? 'SUBSCRIPTION' : 'ORDER'));
	}, []);

	React.useEffect(() => {
		if (paymentType === 'SUBSCRIPTION') {
			dispatch({
				type: 'resetOptions',
				value: subscriptionPaypalOptions,
			});
		} else {
			dispatch({
				type: 'resetOptions',
				value: orderPaypalOptions,
			});
		}
	}, [dispatch, paymentType]);

	const handleCreateOrder = React.useCallback(async () => {
		return axios({
			method: 'POST',
			url: `${API_BASE_URL}/paypal/create-paypal-order`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				productIds: ['4'],
				jwtToken: getToken(),
			},
		}).then((response) => {
			return response.data.id;
		});
	}, []);

	const handleCreateSubscription = React.useCallback(async (_, actions) => {
		return actions.subscription
			.create({
				plan_id: 'P-2AK80040D40270421MTARTEA',
			})
			.then((orderId) => {
				return orderId;
			});
	}, []);

	const handleApprove = React.useCallback(
		(data, actions) => {
			if (actions.order) {
				return actions.order.capture().then((details) => {
					console.log('PaypalPayment order handleApprove: ', details);
					onSuccess('order');
					return null;
				});
			} else if (actions.subscription) {
				onSuccess('subscription');
			} else {
				console.log('something different happened');
			}
		},
		[onSuccess]
	);

	return (
		<>
			<Box display="flex" justifyContent="center" flexDirection="row" width="100%" m="0 0 1rem 0">
				<Button
					onClick={handleTogglePaymentType}
					variant={paymentType === 'SUBSCRIPTION' ? 'activeSelectButton' : 'inactiveSelectButton'}
					m="0 1rem 0 0"
				>
					Subscription
				</Button>
				<Button
					onClick={handleTogglePaymentType}
					variant={paymentType === 'ORDER' ? 'activeSelectButton' : 'inactiveSelectButton'}
				>
					Order
				</Button>
			</Box>

			<PayPalButtons
				onApprove={handleApprove}
				createSubscription={paymentType === 'SUBSCRIPTION' ? handleCreateSubscription : undefined}
				createOrder={paymentType === 'ORDER' ? handleCreateOrder : undefined}
			/>
		</>
	);
};

export default PaypalPayment;
