import client from '../utils/client';
import { gql } from '@apollo/client';

import { TimeUnit } from '../components/TimeUnitSelectField';
import { CURRENT_USER_QUERY, CurrentUser } from '../utils/user';
import { AccessGenerator } from '../utils/accessGenerator';

type Error =
	| 'NETWORK_ERROR'
	| 'INVALID_USER'
	| 'TOO_MANY_LINKS'
	| 'TOO_FEW_LINKS'
	| 'INVALID_CONTENT_ID'
	| 'NO_ACCESS_TO_CONTENT';

const createAccessGenerator = async ({
	contentId,
	groupName,
	numberOfLinks,
	timeUnit,
	timeUnitFactor,
}: {
	contentId: string;
	groupName: string;
	numberOfLinks: number;
	timeUnit: TimeUnit;
	timeUnitFactor: number;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation CreateAccessGenerator(
					$contentId: ID!
					$groupName: String!
					$numberOfLinks: Int!
					$timeUnit: TimeUnit!
					$timeUnitFactor: Int!
				) {
					createAccessGenerator(
						contentId: $contentId
						groupName: $groupName
						numberOfLinks: $numberOfLinks
						timeUnit: $timeUnit
						timeUnitFactor: $timeUnitFactor
					) {
						id
						contentId
						groupName
						createdAt
						validUntil
						accessesAvailable
						accessesTaken
						code
						content {
							id
							title
							contentFormat
						}
					}
				}
			`,
			variables: { contentId, groupName, numberOfLinks, timeUnit, timeUnitFactor },
			errorPolicy: 'all',
			update: (cache, { data }) => {
				if (!data.createAccessGenerator) return;
				const { currentUser: currentUserBefore } = cache.readQuery({
					query: CURRENT_USER_QUERY,
				}) as {
					currentUser: {
						accessGenerators: CurrentUser['accessGenerators'];
						accessGeneratorsInfo: CurrentUser['accessGeneratorsInfo'];
					};
				};
				const addedAccessGenerator = data?.createAccessGenerator as AccessGenerator;
				if (!currentUserBefore.accessGeneratorsInfo) {
					// this should never happen
					return;
				} else {
					const updatedCurrentUser = {
						...currentUserBefore,
						accessGenerators: [...(currentUserBefore.accessGenerators || []), addedAccessGenerator],
						accessGeneratorsInfo: {
							taken:
								currentUserBefore.accessGeneratorsInfo.taken +
								addedAccessGenerator.accessesAvailable,
							remaining:
								currentUserBefore.accessGeneratorsInfo?.remaining -
								addedAccessGenerator.accessesAvailable,
						},
					};
					cache.writeQuery({
						query: CURRENT_USER_QUERY,
						data: { currentUser: updatedCurrentUser },
					});
				}
			},
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
			};
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default createAccessGenerator;
