import React from 'react';
import { AppBar as MuiAppBar, Box, Toolbar } from '@mui/material';
import { useNavigate } from '../utils/hooks';
import styled from 'styled-components';

import Button from './Button';
import Txt from './Txt';
import { logout } from '../utils/user';

const FlexWrapper = styled(Box)`
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledToolBar = styled(Toolbar)`
	background-color: ${(p) => p.theme.customColors.orange};
	min-height: 3rem;
	height: 3rem;
`;

const buttons = [
	{ title: 'Home', navigateTo: '/' },
	{ title: 'Unternehmen', navigateTo: '/companies' },
	{ title: 'Volumenlizenzen', navigateTo: '/volume-licenses' },
	{ title: 'Kategorien', navigateTo: '/categories' },
	{ title: 'Sitemap', navigateTo: '/gen-sitemap' },
];

const AppBarContent = (): JSX.Element => {
	const navigate = useNavigate();
	const handleLogout = React.useCallback(() => {
		logout(navigate);
	}, [navigate]);

	return (
		<>
			<StyledToolBar>
				<FlexWrapper>
					<Row flex="1">
						<Txt>Admin Dashboard</Txt>
					</Row>
					<Row flex="1.5">
						{buttons?.map(({ title, navigateTo }) => (
							<Button key={title} id={title} m="0 1rem 0 0" navigateTo={navigateTo}>
								{title}
							</Button>
						)) || null}
						<Button m="0 1rem 0 0" onClick={handleLogout}>
							Ausloggen
						</Button>
					</Row>
				</FlexWrapper>
			</StyledToolBar>
		</>
	);
};

const AdminAppBar = () => {
	return (
		<>
			<Box>
				<MuiAppBar position="fixed">
					<AppBarContent />
				</MuiAppBar>
				<AppBarContent />
			</Box>
		</>
	);
};

export default React.memo(AdminAppBar);
