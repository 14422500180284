import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import styled, { useTheme } from 'styled-components';

import { useDivDimensions, useScrolled, hexToRgba } from '../utils/hooks';
import AlternativeTile from './AlternativeTile';

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const RelativeWrapper = styled(Box)`
	width: 100%;
	position: relative;
`;

const Row = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow-x: auto;
`;

const Overlay = styled(Box)<{ $height: number }>`
	position: absolute;
	width: 5rem;
	right: 0;
	top: 0;
	height: ${(p) => p.$height}rem;
	background: linear-gradient(
		to right,
		${(p) => hexToRgba(p.theme.customColors.lightGrey, 0)},
		${(p) => hexToRgba(p.theme.customColors.lightGrey, 1)}
	);
`;

const useShowSliderOverlay = ({
	wrapperRef,
	rowRef,
	items,
	tileWidthRemFactor,
	tileMarginRightRemFactor,
}: {
	wrapperRef: React.RefObject<HTMLDivElement>;
	rowRef: React.RefObject<HTMLDivElement>;
	items: unknown[] | undefined;
	tileWidthRemFactor: number;
	tileMarginRightRemFactor: number;
}): boolean => {
	const theme = useTheme();
	const htmlFontSize = theme.typography.htmlFontSize;
	const { width: contentsRowWidthPixels } = useDivDimensions(wrapperRef, 'resize');
	const { scrolledFromRight } = useScrolled(rowRef);
	const showOverlay: boolean = React.useMemo(() => {
		if (!items || scrolledFromRight <= 0 || contentsRowWidthPixels === undefined) return false;
		const contentTileTotalWidthRem = tileMarginRightRemFactor + tileWidthRemFactor;
		const allContentTilesTotalWidthRem = items.length * contentTileTotalWidthRem;
		const allContentTilesTotalWidthPixels = allContentTilesTotalWidthRem * htmlFontSize;
		return allContentTilesTotalWidthPixels > contentsRowWidthPixels;
	}, [
		items,
		scrolledFromRight,
		tileMarginRightRemFactor,
		tileWidthRemFactor,
		htmlFontSize,
		contentsRowWidthPixels,
	]);
	return showOverlay;
};

export type SliderRowItem = {
	id: string;
	imageUrl?: string;
	bannerInfo?: string;
	onClick?: (id?: string) => void;
	navigateTo: string;
};

const SliderRow = ({
	items,
	p,
	tileWidthRemFactor = 15,
	tileHeightRemFactor = 17,
	tileMarginRightRemFactor = 3,
}: {
	items?: SliderRowItem[];
	p?: string;
	tileWidthRemFactor?: number;
	tileHeightRemFactor?: number;
	tileMarginRightRemFactor?: number;
}): JSX.Element | null => {
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const rowRef = React.useRef<HTMLDivElement>(null);

	const showOverlay = useShowSliderOverlay({
		wrapperRef,
		rowRef,
		items,
		tileWidthRemFactor: tileWidthRemFactor,
		tileMarginRightRemFactor: tileMarginRightRemFactor,
	});

	return items?.length === 0 ? null : !items?.length ? (
		<CircularProgress />
	) : (
		<Wrapper p={p} ref={wrapperRef}>
			<RelativeWrapper>
				<Row ref={rowRef}>
					<Box flex="1" />
					{items.map(({ id, imageUrl, bannerInfo, onClick, navigateTo }) => (
						<AlternativeTile
							key={id}
							id={id}
							imageUrl={imageUrl}
							bannerInfo={bannerInfo}
							onClick={onClick}
							navigateTo={navigateTo}
							widthRemFactor={tileWidthRemFactor}
							marginRightRemFactor={tileMarginRightRemFactor}
							heightRemFactor={tileHeightRemFactor}
						/>
					))}
					<Box flex="1" />
				</Row>
				{showOverlay ? <Overlay $height={tileHeightRemFactor} /> : null}
			</RelativeWrapper>
		</Wrapper>
	);
};

export default React.memo(SliderRow);
