import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

import SectionHeader from './SectionHeader';
import ContentsRow from './ContentsRow';
import Button from './Button';
import CategoryTypeSelect from './CategoryTypeSelect';
import { ContentInfos, ContentFilter, useFilteredContents } from '../utils/content';
import { CategoryGroupName, useAvailableCategories } from '../utils/category';

const Column = styled(Box)<{ $grey?: boolean }>`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const ButtonRow = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

const Contents = ({
	filterAfterCategoryGroup,
	buttonLabel,
	p,
	m,
	navigateTo,
	title,
	subTitle,
	contents,
	showProgress,
	contentFilter,
	maxItems,
}: {
	filterAfterCategoryGroup?: CategoryGroupName;
	buttonLabel?: string;
	p?: string;
	m?: string;
	navigateTo?: string | 'SELECTED_TYPE';
	title?: string;
	subTitle?: string;
	contents?: ContentInfos[];
	showProgress?: boolean;
	contentFilter?: ContentFilter;
	maxItems?: number;
}): JSX.Element => {
	if (navigateTo === 'SELECTED_TYPE' && !filterAfterCategoryGroup) {
		throw new Error('ContentsByCategory: groupNameis required when navigateTo is SELECTED_TYPE');
	}

	const availableCategories = useAvailableCategories(filterAfterCategoryGroup, contents);
	const filteredAvailableCategories = React.useMemo(
		() =>
			availableCategories?.filter(
				(cat) => cat.type !== 'COMPANY' // we dont want to show the company catgory for now
			),
		[availableCategories]
	);

	const availableCategoryTypes = filteredAvailableCategories?.map((category) => category.type);

	const [selectedType, setSelectedType] = React.useState<string | undefined>();
	const handleChangeType = React.useCallback((type: string) => {
		setSelectedType(type);
	}, []);

	const selectedCategory = React.useMemo(
		() => filteredAvailableCategories?.find((category) => category.type === selectedType),
		[filteredAvailableCategories, selectedType]
	);

	const combinedContentFilters = React.useMemo(
		() => ({
			...(contentFilter || {}),
			categoryTypeFilters: availableCategoryTypes ? selectedType : [],
		}),
		[availableCategoryTypes, contentFilter, selectedType]
	);

	const filteredContents = useFilteredContents(combinedContentFilters, contents);
	const slicedContents = React.useMemo(
		() => (maxItems ? filteredContents?.slice(0, maxItems) : filteredContents),
		[filteredContents, maxItems]
	);

	return (
		<Column p={p} m={m}>
			{title || subTitle ? (
				<SectionHeader alignItems="flex-start" title={title} subTitle={subTitle} />
			) : null}
			{filterAfterCategoryGroup ? (
				<CategoryTypeSelect
					categories={filteredAvailableCategories}
					categoryGroupName={filterAfterCategoryGroup}
					onChange={handleChangeType}
					m="0 0 2rem 0"
				/>
			) : null}
			<ContentsRow contents={slicedContents} showProgress={showProgress} />
			{navigateTo && (selectedCategory || buttonLabel) ? (
				<ButtonRow justifyContent="flex-end">
					<Button
						endIconName="ChevronRight"
						navigateTo={
							navigateTo === 'SELECTED_TYPE' ? `/contents/${selectedCategory?.type}` : navigateTo
						}
					>
						{buttonLabel || selectedCategory?.title}
					</Button>
				</ButtonRow>
			) : null}
		</Column>
	);
};

export default React.memo(Contents);
