import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';

import Txt from './Txt';
import { usePublicStatistics } from '../utils/statistics';

const HEIGHT = 3;

const Wrapper = styled(Box)`
	position: absolute;
	height: ${HEIGHT}rem;
	left: 0;
	bottom: -${HEIGHT / 2}rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: hidden;
	background-color: ${(p) => p.theme.customColors.orange};
	border-radius: 0 0.25rem 0.25rem 0;
	padding: 0 0 0 2rem;
`;

const PublicStatisticsBar = () => {
	const publicStatistics = usePublicStatistics();
	const filteredStats = React.useMemo(
		() =>
			publicStatistics?.filter(({ label, value }) =>
				label === 'Nutzer' ? (value >= 1000 ? true : false) : true
			),
		[publicStatistics]
	);

	return !filteredStats ? null : (
		<Wrapper>
			{filteredStats.map(({ label, value }) => (
				<React.Fragment key={`${value}${label}`}>
					<Txt m="0 0.5rem 0 0" fontWeight="bold" variant="body1">
						{value}
					</Txt>
					<Txt m="0 2rem 0 0" variant="body1">
						{label}
					</Txt>
				</React.Fragment>
			))}
		</Wrapper>
	);
};

export default PublicStatisticsBar;
