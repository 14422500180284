import * as React from 'react';
import styled from 'styled-components';
import { Box, Popper } from '@mui/material';
import IconButton from '../components/IconButton';
import Txt from '../components/Txt';
import { BoxProps } from '@mui/material/Box';
import { useTheme } from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

import { POPPER_ZINDEX } from '../utils/constants';

const popperStyle = {
	style: {
		zIndex: POPPER_ZINDEX,
	},
};

const FixedClickAwayListener = styled(Box)`
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
`;

const ToolTip = ({
	children,
	text,
	...rest
}: { children?: JSX.Element; text?: string } & BoxProps): JSX.Element => {
	const theme = useTheme();
	const id = React.useMemo(() => uuidV4(), []);
	const [open, setOpen] = React.useState(false);

	const handleClick = React.useCallback(() => {
		setOpen(true);
	}, []);

	const handleClickAway = React.useCallback(() => {
		if (open) {
			setOpen(false);
		}
	}, [open]);

	const iconRef = React.useRef(null);

	return (
		<>
			{open ? <FixedClickAwayListener onClick={handleClickAway} /> : null}
			<Box display="flex">
				<Box ref={iconRef} display="flex" width="100%" onClick={children ? handleClick : undefined}>
					{children ? children : <IconButton iconName="Info" onClick={handleClick} />}
				</Box>
				<Popper id={id} open={open} anchorEl={iconRef.current} {...popperStyle}>
					<Box
						maxWidth="40rem"
						border={`1px solid ${theme.customColors.lightGrey}`}
						borderRadius="0.5rem"
						p="1rem"
						bgcolor={theme.customColors.white}
						boxShadow={1}
						{...rest}
					>
						<Txt variant="caption">{text}</Txt>
					</Box>
				</Popper>
			</Box>
		</>
	);
};

export default React.memo(ToolTip);
