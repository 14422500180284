import React from 'react';
import { Box } from '@mui/material';

import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import MarkdownTxt from '../components/MarkdownTxt';
import Expandable from '../components/Expandable';
import SectionHeader from '../components/SectionHeader';

import image from '../images/BF_Grafik_FAQ_Kontakt.png';
import SectionWrapper from '../components/SectionWrapper';

import { CONTACT_EMAIL } from '../consts';

const faqSections = [
	{
		sectionTitle: 'PRO Mitgliedschaft',
		entries: [
			{
				title: 'Für wen ist die PRO Mitgliedschaft? ',
				description: `Die PRO-Mitgliedschaft richtet sich an Lehrkräfte und Eltern. 
				Sie bietet nicht nur den Zugriff auf mehr Bildungsinhalte, sondern gibt dir auch die Möglichkeit, 
				Kurse mit deiner Klasse bzw. deinen eigenen Kindern zu teilen, ohne dass diese sich selbst registrieren müssen. `,
			},
			{
				title: 'Was ist in der PRO Mitgliedschaft enthalten? ',
				description: `* PRO-Mitglieder erhalten Zugriff auf alle kostenfreien Kurse, sowie auf alle PRO-Kurse, -Materialien, -Fortbildungen und -Unterrichtsprogramme.
				\n * Eltern können mit der PRO-Mitgliedschaft Inhalte mit ihren Kindern teilen.  
				\n * Zugriff auf das PRO-Dashboard. 
				\n * Ständig wachsender Content und neue Zugriff auf neue Features. 
				`,
			},
			{
				title: 'Was kostet die PRO Mitgliedschaft und wie lange läuft diese? ',
				description: `Die PRO Mitgliedschaft kostet für Eltern und Lehrkräfte 7,90 EUR inkl. MwSt. pro Monat 
				und hat eine Mindestlaufzeit von 6 (sechs) Monaten. Danach kann sie monatlich gekündigt werden. 
				Ohne Kündigung verlängert sich die Mitgliedschaft ab dem 7. Monat jeweils um einen Monat. 
				Bitte beachte, dass der Zugang zu deinem Account nicht geteilt werden darf. `,
			},
		],
	},
	{
		sectionTitle: 'Allgemein',
		entries: [
			{
				title: 'Wo finde ich meine Kurse und Inhalte?',
				description: `Unter dem Menüpunkt "Lernen" findest du alle Kurse von bildungsfreaks. 
				Dort kannst du nach verschiedenen Kategorien filtern. Wenn du bereits registriert bist, findest du dort alle Kurse, die dir persönlich zum Lernen zur Verfügung stehen.
				Unter dem Menüpunkt "Unterrichten" findest du Materialien, Checklisten und Unterrichtsprogramme, die du (z. B. als Lehrkraft oder Elternteil) für die Unterrichtsplanung nutzen kannst. 
			`,
			},
			{
				title: 'Muss ich mich registrieren?',
				description: `Nein, viele Inhalte sind auch ohne vorherige Anmeldung nutzbar. Ein Profil bietet dir jedoch viele Vorteile, denn du kannst u. a. 
				\n * Kurse zu einem späteren Zeitpunkt fortsetzen, 
				\n * Zertifikate mit deinem Namen erhalten, 
				\n * Kurse mit deiner Klasse oder deinen Kindern teilen, 
				\n * Favoriten anlegen, die du später anschauen möchtest,
				\n * u. v. m. 
			`,
			},
			{
				title: 'Erhalte ich nach einem Kurs einen Nachweis über die Durchführung?  ',
				description: `Nach dem Absolvieren eines Kurses erhälts du dein persönliches Zertifikat von uns. 
				Dieses steht in deinem Nutzerprofil zum Download zur Verfügung. 
				Bitte beachte, dass wir Zertifikate nur registrierten Nutzern ausstellen können.
			`,
			},
			{
				title: 'Darf ich die Inhalte teilen oder vervielfältigen? ',
				description: `Inhalte auf bildungsfreaks.de dürfen nur in dem dafür vorgesehenen Rahmen geteilt bzw. vervielfältigt werden. 
				Lehrkräfte können z. B. Interaktive Lerneinheiten mit ihren Schülerinnen teilen, Eltern mit ihren Kindern. 
				Es ist jedoch nicht gestattet, die Austeilen-Funktion für das Teilen unter Kolleginnen oder eine kommerzielle Verwendung außerhalb der bildungsfreaks Plattform zu nutzen. 
				Es gibt auch Produktinhalte, bei denen die Nutzung, Weitergabe und / oder Bearbeitung ausdrücklich erlaubt ist. 
				Solche Inhalte sind mit einer CC-Lizenz gekennzeichnet. Du findest die Lizenzangabe in der Infobox zum Produktinhalt. 
				Bitte informiere dich dazu in unseren [Allgemeinen Nutzungsbedingungen](/terms). 
			`,
			},
			{
				title: 'Wie kann ich Kurse mit meinen Schüler*innen / Kindern teilen?',
				description: `Nach der Anmeldung findest du in deinem Profil „Meine Klassen“ / „Meine Kinder“ den Button „Kurs austeilen“. 
				Hier wählst du die gewünschte Anzahl, die Gültigkeitsdauer und den gewünschten Inhalt aus. 
				Anschließend kannst du den Link kopieren und an deine Klasse bzw. deine Kinder austeilen. 
				Über diesen Link kann der Kurs dann ohne Login gestartet werden. 
				\n Bitte beachte:
				\n * Nutzer*innen, die die Inhalte über die Austeilen-Funktion öffnen, können kein Zertifikat erhalten.  
				\n * Die Links zum Austeilen sind nur für einen begrenzten Zeitraum gültig und müssen nach Bedarf erneut erstellt und geteilt werden. 
				\n * Das Teilen der Codes mit anderen Lehrkräften, Eltern oder für kommerzielle Zwecke ist nicht gestattet.  
				\n * Das Vervielfältigen und Verbreiten der Inhalte an Dritte, außerhalb des dafür vorgesehen Zweckes, ist ohne vorherige Zustimmung des Urhebers nicht gestattet.  
				\n Die Teilen-Funktion für Eltern ist im Rahmen der PRO-Mitgliedschaft verfügbar. 
				\n Mehr Infos findest du auch in unseren [Allgemeinen Nutzungsbedingungen](/terms).
			`,
			},
			{
				title: 'Wie kann ich mein Passwort ändern?',
				description: `Um dein Passwort zu ändern, klicke beim Anmeldeformular auf [Passwort vergessen](/reset-password)`,
			},
			{
				title: 'Wie kann ich mein Konto löschen?',
				description: `Du willst dein Nutzerkonto löschen? Dann schreibe uns eine E-Mail an [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL})`,
			},
		],
	},
];

const FaqView = () => {
	return (
		<ContentWrapper>
			<ImageHeader
				title="Fragen und Antworten: unsere FAQ"
				markdownDescription={`Hier findest du Antworten auf die meistgestellten Fragen. Für mehr Infos kannst du dich gerne per Mail an uns wenden: [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL})`}
				src={image}
			/>
			<>
				{faqSections.map(({ sectionTitle, entries }) => (
					<SectionWrapper key={sectionTitle} flexDirection="column">
						<SectionHeader alignItems="flex-start" title={sectionTitle} />
						{entries.map(({ title, description }) => (
							<Expandable label={title} key={title} m="0 0 0.5rem 0" maxWidth="100%">
								<Box p="1rem">
									<MarkdownTxt>{description}</MarkdownTxt>
								</Box>
							</Expandable>
						))}
					</SectionWrapper>
				))}
			</>
		</ContentWrapper>
	);
};

export default FaqView;
