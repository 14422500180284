import React from 'react';
import styled, { useTheme } from 'styled-components';
import Box, { BoxProps } from '@mui/material/Box';

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	border-radius: 0.5rem;
	height: 1rem;
	margin: 0 1rem;
	width: 66%;
`;

const ProgressBar = ({ progress, ...rest }: { progress?: number } & BoxProps): JSX.Element => {
	const theme = useTheme();
	const complete = progress === 1;

	return (
		<Wrapper
			{...rest}
			border={`2px solid ${complete ? theme.customColors.green : theme.customColors.orange}`}
		>
			<Box
				width={progress ? progress * 100 + '%' : '0%'}
				height="100%"
				bgcolor={complete ? theme.customColors.green : theme.customColors.orange}
				borderRadius="0.5rem"
			></Box>
		</Wrapper>
	);
};

export default ProgressBar;
