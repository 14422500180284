import React from 'react';
import styled from 'styled-components';
import { Box, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Button from './Button';
import { type CategoryGroupName, useCategoriesOfGroup } from '../utils/category';
import { useAppState } from '../utils/app';
import { useCurrentUser } from '../utils/user';
import { useIsMobile } from '../utils/hooks';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledToolBar = styled(Toolbar)<{ $isMobile?: boolean }>`
	background-color: ${(p) => p.theme.customColors.orange};
	min-height: ${(p) => (p.$isMobile ? '2rem' : '3rem')};
	height: ${(p) => (p.$isMobile ? '2rem' : '3rem')};
`;

const AUDIENCE: CategoryGroupName[] = ['AUDIENCE'];

const BottomAppBar = () => {
	const currentUser = useCurrentUser();
	const appState = useAppState();
	const audienceCategories = useCategoriesOfGroup(AUDIENCE);
	const isMobile = useIsMobile();

	const filteredAudienceCategories = React.useMemo(
		() => audienceCategories?.filter((category) => category.type !== 'COMPANY'),
		[audienceCategories]
	);
	const location = useLocation();

	const userHasDashboard = currentUser?.appFunctionalities?.some((af) => af.type === 'DASHBOARD');
	const userCanShare = currentUser?.appFunctionalities?.some((af) =>
		af.type.includes('SHARE_CONTENTS')
	);
	const userCanSelectAudience = appState === 'LOGGED_OUT';
	const userCanAccessSubNavigation = appState === 'LOGGED_IN' && (userHasDashboard || userCanShare);
	const showBottomAppBar = userCanSelectAudience || userCanAccessSubNavigation;

	const navigationButtons = React.useMemo(() => {
		if (!userHasDashboard || appState === 'LOGGED_OUT') {
			return [];
		} else {
			const entries = [
				{ title: 'Übersicht', navigateTo: '/' },
				{ title: 'Mein Dashboard', navigateTo: '/dashboard' },
			];

			if (userCanShare) {
				entries.push({ title: 'Teilen', navigateTo: '/share' });
			}
			return entries;
		}
	}, [appState, userCanShare, userHasDashboard]);

	return !showBottomAppBar ? null : (
		<>
			<StyledToolBar $isMobile={isMobile}>
				<Row width="100%" justifyContent="center">
					{appState === 'LOGGED_OUT'
						? filteredAudienceCategories?.map(({ title, type }) => (
								<Button
									key={type}
									id={type}
									m="0 1rem 0 0"
									navigateTo={`/${type.toLowerCase()}`}
									variant={
										location.pathname.includes(`/${type.toLowerCase()}`)
											? 'activeTextOrange'
											: undefined
									}
								>
									{title}
								</Button>
						  )) || null
						: navigationButtons.map(({ title, navigateTo }) => (
								<Button key={title} id={title} m="0 1rem 0 0" navigateTo={navigateTo}>
									{title}
								</Button>
						  ))}
				</Row>
			</StyledToolBar>
		</>
	);
};

export default React.memo(BottomAppBar);
