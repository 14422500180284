import client from '../utils/client';
import { gql } from '@apollo/client';
import { CompanyInfoFragment } from '../utils/company';

type Error = 'NETWORK_ERROR' | 'UNAUTHORIZED' | 'COMPANY_ALREADY_EXISTS';

const createCompany = async ({
	companyTitle,
}: {
	companyTitle: string;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation CreateCompany($companyTitle: String!) {
					createCompany(companyTitle: $companyTitle) {
						...CompanyInfoFragment
					}
				}
				${CompanyInfoFragment}
			`,
			variables: { companyTitle },
			refetchQueries: ['Companies'],
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
			};
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default createCompany;
