import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import FilterableContents2 from '../components/FilterableContents2';
import image from '../images/BF_Grafik_Header_Katalog.png';
import SectionWrapper from '../components/SectionWrapper';
import { useApiFilteredContents } from '../utils/content';
import { useCurrentUser } from '../utils/user';
import { CircularProgress } from '@mui/material';

const TeachContentView = (): JSX.Element => {
	const currentUser = useCurrentUser();
	const teachContents = useApiFilteredContents({
		contentIsActive: true,
		contentIsDemo: false,
		categoryTypeFilters: ['INTERACTIVE', 'TEMPLATES', 'WORKSHEET', 'PROJECT'],
		userAudiences: currentUser ? currentUser?.audienceType : undefined,
	});

	return currentUser === undefined ? (
		<CircularProgress />
	) : (
		<ContentWrapper>
			<ImageHeader src={image} title="Unterrichten" />
			<SectionWrapper>
				<FilterableContents2 contents={teachContents} defaultShowFilter={true} />
			</SectionWrapper>
		</ContentWrapper>
	);
};

export default TeachContentView;
