import client from '../utils/client';
import { gql } from '@apollo/client';

const deleteAccessGenerator = async (
	id: string
): Promise<{ success: true; error: undefined } | { success: false; error: string }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation DeleteAccessGenerator($id: ID!) {
					deleteAccessGenerator(id: $id) {
						success
					}
				}
			`,
			variables: { id },
			refetchQueries: ['CurrentUser'],
		});
		if (errors) {
			return { success: false, error: errors[0].message };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default deleteAccessGenerator;
