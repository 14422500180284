import React from 'react';
import SectionHeader from './SectionHeader';
import GridContainer from './GridContainer';
import GridItem from './GridItem';
import SectionWrapper from './SectionWrapper';
import ReactPlayerWrapper from './ReactPlayerWrapper';
import MarkdownTxt from './MarkdownTxt';

const horizontalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };
const verticalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };

const aboutUsText = `
Willkommen bei bildungsfreaks! Hier findest du digitale Lerneinheiten und Materialien zu den Zukunftsthemen, 
die wirklich zählen – Medienbildung, Berufsorientierung, Bildung für nachhaltige Entwicklung und mehr. 
Und das Beste daran: Du kannst jederzeit und überall lernen – auf deinem Smartphone, Tablet oder Computer. 

Lernst du gerne in deinem eigenen Tempo? Mit bildungsfreaks kannst du jederzeit eine Pause machen und später nahtlos weiterlernen. 
Wir speichern deinen Lernfortschritt, damit du dich immer wieder einloggen und weitermachen kannst, wo du aufgehört hast. 
Und das ist noch nicht alles! Wenn du einen Kurs erfolgreich abgeschlossen hast, erhältst du ein Zertifikat, das deine Teilnahme bescheinigt. 
Zeige, was du geschafft hast und verdiene dir Anerkennung für deine Leistungen! 

Wir sind stolz darauf, dass unsere Plattform in Deutschland entwickelt und gehostet wird. 
Und das Beste: Wir wachsen ständig und stellen regelmäßig neue Inhalte und Funktionen bereit, damit du noch besser lernen kannst.
Mit einem eigenen Account bei bildungsfreaks PRO kannst du sogar noch mehr Funktionen erleben und zusätzliche Vorteile genießen. 
Worauf wartest du noch? Werde jetzt Teil der bildungsfreaks Community und entdecke, wie du smarter lernen und unterrichten kannst! 
`;

const LearnSmartSectionSection = () => {
	return (
		<SectionWrapper>
			<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
				<GridItem xs={12}>
					<SectionHeader
						title="Smarter lernen. Smarter unterrichten."
						subTitle="Jederzeit und überall."
						alignItems="flex-start"
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<MarkdownTxt>{aboutUsText}</MarkdownTxt>
				</GridItem>
				<GridItem xs={12} sm={12} md={6} alignItems="flex-start">
					<ReactPlayerWrapper url="https://www.youtube.com/watch?v=y9Rv_Yv1mgU" />
				</GridItem>
			</GridContainer>
		</SectionWrapper>
	);
};
export default LearnSmartSectionSection;
