import client from '../utils/client';
import { gql } from '@apollo/client';
import { CategoryDetailsFragment } from '../utils/category';

type Error = 'CATEGORY_NOT_EXISTENT' | 'NETWORK_ERROR' | 'UNAUTHORIZED';

const updateCategory = async ({
	id,
	title,
	description,
	subTitle,
	shortDescription,
	imageUrl,
}: {
	id: string;
	title?: string;
	description?: string;
	subTitle?: string;
	shortDescription?: string;
	imageUrl?: string;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateCategory(
					$id: ID!
					$title: String
					$description: String
					$subTitle: String
					$shortDescription: String
					$imageUrl: String
				) {
					updateCategory(
						id: $id
						title: $title
						description: $description
						subTitle: $subTitle
						shortDescription: $shortDescription
						imageUrl: $imageUrl
					) {
						...CategoryDetailsFragment
					}
				}
				${CategoryDetailsFragment}
			`,
			variables: { id, title, description, subTitle, shortDescription, imageUrl },
		});
		if (errors) {
			return { success: false, error: errors[0].message as Error };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default updateCategory;
