import React from 'react';
import { Box, Grid } from '@mui/material';
import styled from 'styled-components';

import Button from './Button';
import Txt from './Txt';
import ImageBox from './ImageBox';
import GridItem from './GridItem';
import SectionWrapper from './SectionWrapper';
import image from '../images/BF_Grafik_Vorteile_Home_v3.png';

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const StyledText = styled(Txt)`
	color: ${(p) => p.theme.customColors.lightBlue};
`;

const items = [
	{
		title: 'Für Lehrer*innen',
		description:
			'Erweitere dein Curriculum und spare Vorbereitungszeit mit unserem Portfolio – jederzeit und überall.',
		navigateTo: '/teacher',
	},
	{
		title: 'Für Schüler*innen',
		description:
			'Lerne digital und interaktiv was heute und morgen wichtig ist – für die Schule und danach.',
		navigateTo: '/pupils',
	},
	{
		title: 'Für Eltern',
		description:
			'Sichere und qualitätsgeprüfte Lerninhalte für dein Kind – digital und Multiscreen-kompatibel.',
		navigateTo: '/pupils',
	},
];

const TargetGroupsSection = () => {
	return (
		<SectionWrapper altBackground>
			<Grid container>
				<GridItem xs={12} sm={12} md={7}>
					<ImageBox objectFit="contain" maxHeight="40rem" width="100%" src={image} />
				</GridItem>
				<GridItem xs={12} sm={12} md={5}>
					<Box flexDirection="column" p="2rem">
						{items.map(({ title, description, navigateTo }) => (
							<Column key={title}>
								<StyledText variant="h6">{title}</StyledText>
								<Txt variant="body2" m="1rem 0">
									{description}
								</Txt>
								<Button m="0 0 2rem" endIconName="ChevronRight" navigateTo={navigateTo}>
									Mehr
								</Button>
							</Column>
						))}
					</Box>
				</GridItem>
			</Grid>
		</SectionWrapper>
	);
};

export default React.memo(TargetGroupsSection);
