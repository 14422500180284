import React from 'react';

import ImageHeader from '../components/ImageHeader';
import ContentWrapper from '../components/ContentWrapper';

import Contents from '../components/Contents';
import image from '../images/BF_Grafik_Module_BFGRATIS_Schueler.png';
import IWantMoreSection from '../components/IWantMoreSection';
import { useRecommendedApiContents } from '../utils/content';
import SectionWrapper from '../components/SectionWrapper';

const LoggedOutPupilView = (): JSX.Element => {
	const recommendedContents = useRecommendedApiContents('PUPILS');

	return (
		<ContentWrapper>
			<ImageHeader
				src={image}
				title="Mach dich mal schlau."
				description="Für die Schule oder für’s Leben lernen – mit bildungsfreaks kannst du beides. Digital und interaktiv gibst du deinem Wissen ein zukunftsfähiges Update!"
			/>
			<SectionWrapper>
				<Contents
					title="Die Besten"
					subTitle="Schau dir unsere beliebstesten Kurse für Schüler*innen an!"
					contents={recommendedContents}
					navigateTo="/contents/PUPILS"
					maxItems={6}
					buttonLabel="Mehr entdecken"
				/>
			</SectionWrapper>

			<IWantMoreSection />
		</ContentWrapper>
	);
};

export default LoggedOutPupilView;
