import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, CircularProgress } from '@mui/material';
import { useIsMobile } from '../utils/hooks';

const Column = styled(Box)`
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const WidthLimiter = styled(Box)<{ $maxWidth: string }>`
	max-width: ${(p) => (p.$maxWidth ? p.$maxWidth : 'auto')};
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
`;

const ContentWrapper = ({
	children,
	maxWidth,
	center,
	minHeight,
	p,
	pMobile = '0rem',
}: {
	children: JSX.Element | JSX.Element[] | (JSX.Element | null)[] | null;
	maxWidth?: string;
	center?: boolean;
	minHeight?: string;
	p?: string;
	pMobile?: string;
}): JSX.Element => {
	const isMobile = useIsMobile();

	const theme = useTheme();
	const contentMaxWidthPx = theme.contentMaxWidthPx;

	return (
		<Column minHeight={!children ? '10rem' : minHeight} p={isMobile ? pMobile : p}>
			<WidthLimiter
				$maxWidth={maxWidth ? maxWidth : `${contentMaxWidthPx}px`}
				justifyContent={!children || center ? 'center' : 'flex-start'}
				alignItems={!children || center ? 'center' : 'flex-start'}
				overflow="hidden"
			>
				<>{!children ? <CircularProgress /> : children}</>
			</WidthLimiter>
		</Column>
	);
};

export default React.memo(ContentWrapper);
