import React from 'react';
import { createTheme } from '@mui/material/styles';
import type { ThemeOptions as MuiThemeOptions } from '@mui/material/styles';
import { useWindowDimensions } from './hooks';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		mainButton: true;
		secondButton: true;
		altButton: true;
		loadingMainButton: true;
		link: true;
		loading: true;
		textButton: true;
		activeTextOrange: true;
		activeTextWhite: true;
		activeSelectButton: true;
		inactiveSelectButton: true;
		activeNavigationSelectButton: true;
		inActiveNavigationSelectButton: true;
		blueText: true;
		boldText: true;
	}
}

declare module '@mui/material/styles' {
	interface Theme {
		sectionVerticalSpacingRem: number;
		contentMaxWidthPx: number;
		authViewsMaxWidthPx: number;
		customColors: {
			orange: string;
			lightOrange: string;
			secondaryGrey: string;
			lightBlue: string;
			white: string;
			lightGrey: string;
			decentGrey: string;
			darkGrey: string;
			typographyGrey: string;
			green: string;
			decentBlue: string;
		};
	}
	interface ThemeOptions {
		customColors?: {
			orange: string;
			lightOrange: string;
			secondaryGrey: string;
			lightBlue: string;
			white: string;
			lightGrey: string;
			decentGrey: string;
			darkGrey: string;
			typographyGrey: string;
			green: string;
			decentBlue: string;
		};
	}
}

const orange = '#FFC358';
const lightOrange = '#FFD182';
const secondaryGrey = '#575655';
const lightBlue = '#009FE3';
const white = '#ffffff';
const lightGrey = '#E0E0E0';
const decentGrey = '#F4F4F4';
const darkGrey = '#575756';
const typographyGrey = '#575756';
const green = '#2FAC66';
const decentBlue = '#F5F9FD';

const breakPoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1536,
	},
};

const activeTextStyle = {
	'borderRadius': '0.5rem',
	'color': secondaryGrey,
	'textTransform': 'none',
	'padding': '1px 1rem',
	'fontWeight': 'none',
	'&:hover': {
		backgroundColor: 'transparent',
		color: lightBlue,
	},
};

const defaultTheme = {
	...breakPoints,
	customColors: {
		orange,
		lightOrange,
		secondaryGrey,
		lightBlue,
		white,
		lightGrey,
		decentGrey,
		darkGrey,
		typographyGrey,
		green,
		decentBlue,
	},
	contentMaxWidthPx: 1440,
	authViewsMaxWidthPx: 600,
	sectionVerticalSpacingRem: 4,
	components: {
		MuiCssBaseline: {
			// get rid of scrollbars
			styleOverrides: {
				div: {
					'-ms-overflow-style': 'none', // hide scrollbar in IE
					'scrollbar-width': 'none', // hide scrollbar in Firefox
					'::-webkit-scrollbar': {
						display: 'none', // hide scrollbar in Chrome, Safari and Opera
					},
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'mainButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${lightBlue}`,
						'color': white,
						'backgroundColor': lightBlue,
						'&:hover': {
							border: `1px solid ${secondaryGrey}`,
							color: white,
							backgroundColor: secondaryGrey,
						},
					},
				},
				{
					props: { variant: 'secondButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${darkGrey}`,
						'color': darkGrey,
						'backgroundColor': 'transparent',
						'&:hover': {
							border: `1px solid ${lightBlue}`,
							color: lightBlue,
							backgroundColor: 'transparent',
						},
					},
				},
				{
					props: { variant: 'altButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${secondaryGrey}`,
						'color': secondaryGrey,
						'backgroundColor': white,
						'&:hover': {
							border: `1px solid ${secondaryGrey}`,
							color: white,
							backgroundColor: secondaryGrey,
						},
					},
				},
				{
					props: { variant: 'link' },
					style: {
						'borderRadius': '0px',
						'border': 'none',
						'borderBottom': `1px solid ${typographyGrey}`,
						'color': secondaryGrey,
						'backgroundColor': 'none',
						'textTransform': 'none',
						'padding': '1px',
						'fontWeight': 'none',
						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
							borderBottom: `1px solid ${lightBlue}`,
						},
					},
				},
				{
					props: { variant: 'text' },
					style: {
						'minWidth': '0px',
						'textAlign': 'left',
						'borderRadius': '0px',
						'color': secondaryGrey,
						'backgroundColor': 'none',
						'textTransform': 'none',
						'padding': '1px',
						'fontWeight': 'none',
						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'boldText' },
					style: {
						'minWidth': '0px',
						'textAlign': 'left',
						'borderRadius': '0px',
						'color': secondaryGrey,
						'backgroundColor': 'none',
						'textTransform': 'none',
						'padding': '1px',
						'fontWeight': 'bold',
						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'blueText' },
					style: {
						minWidth: '0px',
						textAlign: 'left',
						borderRadius: '0px',
						color: lightBlue,
						backgroundColor: 'none',
						textTransform: 'none',
						padding: '1px',
						fontWeight: 'none',
					},
				},
				// SelectButtons
				{
					props: { variant: 'activeTextOrange' },
					style: {
						...activeTextStyle,
						'backgroundColor': lightOrange,
						'&:hover': {
							backgroundColor: lightOrange,
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'activeTextWhite' },
					style: {
						...activeTextStyle,
						'backgroundColor': white,
						'&:hover': {
							backgroundColor: white,
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'inactiveSelectButton' },
					style: {
						'textTransform': 'none',
						'backgroundColor': decentGrey,
						'color': typographyGrey,
						'&:hover': {
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'activeSelectButton' },
					style: {
						'textTransform': 'none',
						'backgroundColor': lightBlue,
						'color': typographyGrey,
						'&:hover': {
							color: white,
							backgroundColor: lightBlue,
						},
					},
				},
				{
					props: { variant: 'inActiveNavigationSelectButton' },
					style: {
						'fontWeight': 'bold',
						'minWidth': '0px',
						'textAlign': 'left',
						'borderRadius': '0px',
						'color': secondaryGrey,
						'backgroundColor': 'none',
						'textTransform': 'none',
						'padding': '1px',
						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'activeNavigationSelectButton' },
					style: {
						'fontWeight': 'bold',
						'minWidth': '0px',
						'textAlign': 'left',
						'borderRadius': '0px',
						'color': secondaryGrey,
						'backgroundColor': 'none',
						'textTransform': 'none',
						'padding': '1px',
						'textDecoration': 'underline',
						'textDecorationColor': lightBlue,
						'textDecorationThickness': '3px',
						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
							textDecoration: 'underline',
							textDecorationColor: lightBlue,
							textDecorationThickness: '3px',
						},
					},
				},
				// loading
				{
					props: { variant: 'loading' },
					style: {
						textTransform: 'none',
						borderRadius: '0.25rem',
						border: `1px solid ${lightGrey}`,
						color: secondaryGrey,
						backgroundColor: white,
					},
				},
				{
					props: { variant: 'loadingMainButton' },
					style: {
						textTransform: 'none',
						border: `1px solid ${lightGrey}`,
						color: secondaryGrey,
						backgroundColor: white,
					},
				},
			],
		},
	},
	palette: {
		primary: {
			main: orange,
		},
		secondary: {
			main: secondaryGrey,
		},
	},
	// caution: this also overwrites specific button styles
	typography: {
		allVariants: { color: typographyGrey, fontFamily: 'Open Sans, sans-serif' },
	},
};

export const useActiveBreakPoint = (): 'xs' | 'sm' | 'md' | 'lg' | 'xl' => {
	const { width } = useWindowDimensions();
	const breakPointRanges: [number, number][] = React.useMemo(() => {
		const breakPointsKey = Object.keys(breakPointsValues);
		const result: [number, number][] = [];
		for (const key in breakPointsValues) {
			const currentIndex = breakPointsKey.findIndex((k) => k === key);
			result.push([
				breakPointsValues[key],
				breakPointsValues[breakPointsKey[currentIndex + 1]] || 100000,
			]);
		}
		return result;
	}, []);
	const activeBreakPoint = React.useMemo(() => {
		return breakPointRanges
			.map(([min, max], index) =>
				width > min && width <= max ? Object.keys(breakPointsValues)[index] : null
			)
			.filter(Boolean)[0];
	}, [breakPointRanges, width]);
	return activeBreakPoint as 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const breakPointsValues = breakPoints.values;

export const useCreateTheme = () => {
	const currentTheme = React.useMemo(
		() => createTheme(defaultTheme as MuiThemeOptions),

		[]
	);
	return currentTheme;
};
