import client from '../utils/client';
import { gql } from '@apollo/client';

const upsertAddress = async ({
	id,
	federalState,
	city,
	zipCode,
	street,
	houseNumber,
	isStandardAddress,
}: {
	id?: string;
	federalState:
		| 'Baden-Württemberg'
		| 'Bayern'
		| 'Berlin'
		| 'Brandenburg'
		| 'Bremen'
		| 'Hamburg'
		| 'Hessen'
		| 'Mecklenburg-Vorpommern'
		| 'Niedersachsen'
		| 'Nordrhein-Westfalen'
		| 'Rheinland-Pfalz'
		| 'Saarland'
		| 'Sachsen-Anhalt'
		| 'Sachsen'
		| 'Schleswig-Holstein'
		| 'Thüringen';
	city: string;
	zipCode: string;
	street: string;
	houseNumber: string;
	isStandardAddress: boolean;
}): Promise<{ success: true; error: undefined } | { success: false; error: string }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpsertAddress(
					$id: ID
					$country: String!
					$federalState: String!
					$zipCode: String!
					$city: String!
					$street: String!
					$houseNumber: String!
					$isStandardAddress: Boolean!
				) {
					upsertAddress(
						id: $id
						country: $country
						federalState: $federalState
						zipCode: $zipCode
						city: $city
						street: $street
						houseNumber: $houseNumber
						isStandardAddress: $isStandardAddress
					) {
						success
						address {
							id
							country
							federalState
							zipCode
							city
							street
							houseNumber
							isStandardAddress
						}
					}
				}
			`,
			variables: {
				id,
				country: 'DE',
				federalState,
				zipCode,
				city,
				street,
				houseNumber,
				isStandardAddress,
			},
			refetchQueries: id ? undefined : ['CurrentUser'],
		});

		if (errors) {
			return { success: false, error: errors[0].message };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default upsertAddress;
