import React from 'react';

import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import Txt from '../components/Txt';
import SectionHeader from '../components/SectionHeader';
import image from '../images/BF_Grafik_FAQ_Kontakt.png';
import SectionWrapper from '../components/SectionWrapper';
import Logo from '../components/Logo';

import { CONTACT_EMAIL } from '../consts';

const ContactView = () => {
	return (
		<ContentWrapper>
			<ImageHeader
				title="Kontakt"
				markdownDescription={`In unseren [FAQs](/faq) findest du Antworten die meistgestellten Fragen. Für mehr Infos kannst du dich gerne per Mail an uns wenden: [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL})`}
				src={image}
			/>
			<SectionWrapper flexDirection="column" alignItems="center">
				<SectionHeader alignItems="center" title="Kontaktdetails" />
				<Logo m="2rem 0 0 0" />
				<Txt fontWeight="bold" m="2rem 0 0 0">
					Schreib uns eine E-mail
				</Txt>
				<Txt m="1rem 0 0 0">{CONTACT_EMAIL}</Txt>
				<Txt fontWeight="bold" m="2rem 0 0 0">
					Adresse
				</Txt>
				<Txt m="1rem 0 0 0">visionYOU GmbH</Txt>
				<Txt>Stahnsdorfer Str. 107</Txt>
				<Txt>14482 Potsdam</Txt>
			</SectionWrapper>
		</ContentWrapper>
	);
};

export default ContactView;
