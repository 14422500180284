import * as React from 'react';
import { TextFieldProps } from '@mui/material/TextField';

import OldTextField from './OldTextField';
import { ContentFilter, useAllContentDetails, useFilteredContents } from '../utils/content';
import SearchSelectField2, { Option } from './SearchSelectField2';

export type ContentSelectFieldRef = {
	validate: () => boolean;
};

const ContentSelectField = React.forwardRef<
	ContentSelectFieldRef,
	{
		label: string;
		onChange?: (selectedOptions: Option[]) => void;
		multi?: boolean;
		variant?: TextFieldProps['variant'];
		defaultValues?: string[];
		m?: string;
		disabled?: boolean;
		width?: string;
		contentFilter?: ContentFilter;
		labelKey?: string;
		valueKey?: string;
	}
>(
	(
		{
			label,
			onChange,
			multi,
			variant = 'outlined',
			defaultValues,
			m,
			disabled,
			width,
			contentFilter,
			labelKey = 'title',
			valueKey = 'id',
		},
		ref
	): JSX.Element => {
		const allContents = useAllContentDetails();
		const filteredContents = useFilteredContents(contentFilter, allContents);
		const handleChange = React.useCallback(
			(selectedOptions: Option[]) => {
				onChange?.(selectedOptions);
			},
			[onChange]
		);

		return !filteredContents ? (
			<OldTextField label={label} variant={variant} m={m} isLoading />
		) : (
			<SearchSelectField2
				options={filteredContents}
				labelKey={labelKey}
				valueKey={valueKey}
				label={label}
				onChange={handleChange}
				multiple={multi}
				variant={variant}
				defaultValues={defaultValues}
				m={m}
				ref={ref}
				disabled={disabled}
				width={width}
			/>
		);
	}
);

export default ContentSelectField;
