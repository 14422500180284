import React from 'react';

import ContentWrapper from '../components/ContentWrapper';
import Txt from '../components/Txt';
import { routes } from '../routes';
import { useApiFilteredContents } from '../utils/content';

const SitemapView = (): JSX.Element | null => {
	const contents = useApiFilteredContents({});
	const contentRoutes = React.useMemo(() => {
		const copy = contents ? [...contents] : [];
		return copy
			.sort((a, b) => (Number(a.id.split('-')[0]) > Number(b.id.split('-')[0]) ? 1 : -1))
			?.map((content) => `/content/${content.id}`);
	}, [contents]);

	const staticRoutes = React.useMemo(
		() =>
			routes
				.filter((route) => route.referenceInSitemap)
				.map((route) => route.path)
				.filter((value, index, arr) => arr.findIndex((v) => v === value) === index)
				.sort(),
		[]
	);

	const xmlString = `<?xml version="1.0" encoding="UTF-8"?><urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">${[
		...staticRoutes,
		...contentRoutes,
	]
		.map((route) =>
			route
				.replaceAll('&', '&amp;')
				.replaceAll("'", '&apos;')
				.replaceAll('"', '&quot;')
				.replaceAll('>', '&gt;')
				.replaceAll('<', '&lt;')
		)
		.map((route) => `<url><loc>https://bildungsfreaks.de${route}</loc></url>`)
		.join('')}</urlset>
	`;
	return (
		<ContentWrapper p="2rem">
			<Txt>{xmlString}</Txt>
		</ContentWrapper>
	);
};

export default SitemapView;
