import client from '../utils/client';
import { gql } from '@apollo/client';
import { ContentInfosFragment, ContentInfos } from '../utils/content';
import { CURRENT_USER_QUERY } from '../utils/user';

type Error = 'NETWORK_ERROR' | 'UNAUTHENTICATED' | 'UNEXPECTED_ERROR';

const toggleFavouriteContent = async (
	id: string
): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation ToggleFavouriteContent($id: ID!) {
					toggleFavouriteContent(id: $id) {
						isFavourite
						content {
							id
							...ContentInfosFragment
						}
					}
				}
				${ContentInfosFragment}
			`,
			variables: {
				id,
			},
			update: (cache, { data }) => {
				const { currentUser } = cache.readQuery({
					query: CURRENT_USER_QUERY,
				}) as {
					currentUser: {
						favouriteContents: ContentInfos[];
					};
				};
				const { content, isFavourite } = data.toggleFavouriteContent;
				const contentRef = `${content.__typename}:${content.id}`;
				cache.modify({
					id: cache.identify(currentUser),
					fields: {
						favouriteContents: (existingContentsRefs) => {
							return isFavourite
								? [{ __ref: contentRef }, ...existingContentsRefs]
								: existingContentsRefs.filter((cRef) => cRef.__ref !== contentRef);
						},
					},
				});
			},
		});
		if (errors) {
			return { success: false, error: errors[0].message as Error };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default toggleFavouriteContent;
