import React from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from '../utils/snackBar';

import SectionHeader from './SectionHeader';
import SectionWrapper from './SectionWrapper';
import SimpleTable2 from './SimpleTable2';
import IconButton from './IconButton';
import Txt from './Txt';
import Dialog from './Dialog';
import { useCurrentUser } from '../utils/user';
import deleteAccessGenerator from '../mutations/deleteAccessGenerator';

const AccessGeneratorsSection = () => {
	const currentUser = useCurrentUser();
	const snackBar = useSnackbar();

	const headRowData = React.useMemo(
		() => [
			{ id: 'active', text: 'Aktiv' },
			{ id: 'completed', text: 'Abgeschlossen' },
			{ id: 'title', text: 'Titel' },
			{ id: 'groupName', text: 'Gruppenname' },
			{ id: 'createdAt', text: 'Erstellt' },
			{ id: 'validUntil', text: 'Gültig bis' },
			{ id: 'actions', text: '' },
		],
		[]
	);

	const [loading, setLoading] = React.useState(false);
	const handleDeleteAccessGenerator = React.useCallback(async (id) => {
		setLoading(true);
		await deleteAccessGenerator(id);
		setLoading(false);
	}, []);

	const handleConfirmDeletion = React.useCallback(
		(id) => {
			Dialog.render({
				title: 'Löschen?',
				description: 'Möchtest du diesen Zugangscode wirklich löschen?',
				buttons: [{ id: 'confirm', label: 'Ja', onClick: () => handleDeleteAccessGenerator(id) }],
			});
		},
		[handleDeleteAccessGenerator]
	);

	const handleCopyCodeToClipboard = React.useCallback(
		(code) => {
			const link = `${window.location.origin}/access-generator-register/${code}`;
			navigator.clipboard.writeText(link);
			snackBar({
				success: 'Der Teilen-Link wurde in die Zwischenablage kopiert.',
			});
		},
		[snackBar]
	);

	const rows = React.useMemo(
		() =>
			currentUser?.accessGenerators?.map((accessGenerator) => {
				return [
					// {
					// 	id: 'contentFormat',
					// 	text: accessGenerator.content.contentFormat,
					// },
					{
						id: 'active',
						text: `${accessGenerator.accessesTaken} / ${accessGenerator.accessesAvailable}`,
					},
					{
						id: 'completed',
						text: `${accessGenerator.accessesCompleted} / ${accessGenerator.accessesAvailable}`,
					},
					{ id: 'title', text: accessGenerator.content.title },
					{ id: 'groupName', text: accessGenerator.groupName },
					{ id: 'createdAt', text: accessGenerator.createdAt },
					{ id: 'validUntil', text: accessGenerator.validUntil },
					{
						id: 'actions',
						jsx: (
							<Box display="flex" flexDirection="row" alignItems="center">
								<IconButton
									iconName="ContentCopy"
									onClick={handleCopyCodeToClipboard}
									id={accessGenerator.code}
									loading={loading}
								/>
								<IconButton
									m="0 0 0 1rem"
									iconName="Delete"
									onClick={handleConfirmDeletion}
									loading={loading}
									id={accessGenerator.id}
								/>
							</Box>
						),
					},
				];
			}),
		[currentUser?.accessGenerators, handleCopyCodeToClipboard, loading, handleConfirmDeletion]
	);

	return rows?.length ? (
		<SectionWrapper flexDirection="column">
			<SectionHeader alignItems="flex-start" title="Teilen-Links" />
			<Txt m="0 0 1rem 0">
				{currentUser?.accessGeneratorsInfo?.taken} von{' '}
				{(currentUser?.accessGeneratorsInfo?.remaining || 0) +
					(currentUser?.accessGeneratorsInfo?.taken || 0)}{' '}
				verwendet
			</Txt>
			<SimpleTable2 head={headRowData} rows={rows} />
		</SectionWrapper>
	) : null;
};
export default AccessGeneratorsSection;
