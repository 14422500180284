import { useSnackbar as useSnackbarNotistack } from 'notistack';
import React from 'react';

type ErrorCode =
	| 'NETWORK_ERROR'
	| 'NO_ACCESS_TO_CONTENT'
	| 'UNAUTHENTICATED'
	| 'UNAUTHORIZED'
	| 'INVALID_USER'
	| 'TOO_MANY_LINKS'
	| 'TOO_FEW_LINKS'
	| 'INVALID_CONTENT_ID'
	| 'GROUP_NAME_ALREADY_TAKEN'
	| 'CONFIRMATION_CODE_INVALID'
	| 'EMAIL_ALREADY_CONFIRMED'
	| 'UNEXPECTED_ERROR'
	| 'EMAIL_NOT_EXISTENT'
	| 'SEND_CONFIRMATION_EMAIL_FAILED'
	| 'EMAIL_ALREADY_TAKEN'
	| 'EMAIL_INVALID'
	| 'PASSWORD_INVALID'
	| 'TERMS_NOT_ACCEPTED'
	| 'CONFIRMATION_CODE_EXPIRED'
	| 'INVALID_CONTENT'
	| 'CANNOT_CHANGE_PASSWORD_OF_TEST_USER'
	| 'CONTENT_PROGRESS_ERROR'
	| 'INACTIVE_CONTENT'
	| 'NO_CERTIFICATE_FOUND'
	| 'COMPANY_ALREADY_EXISTS'
	| 'COPANY_NOT_EXISTENT'
	| 'VOLUME_LICENSE_NOT_EXISTENT'
	| 'CONTENT_NOT_COMPLETED'
	| 'CERTIFICATE_ALREADY_EXISTS'
	| 'CONTENT_NOT_FOUND'
	| 'CONTENT_IS_DEMO'
	| 'INVALID_NUMBER_OF_LICENSES'
	| 'UNABLE_TO_DECREASE_NUMBER_OF_LICENSES'
	| 'INVALID_ENTRIES'
	| 'VOLUME_LICENSE_NOT_FOUND'
	| 'EXCEEDS_NUMBER_OF_LICENSES'
	| 'CATEGORY_NOT_EXISTENT'
	| 'INVALID_FILE_TYPE'
	| 'USER_NOT_EXISTENT'
	| 'INVALID_AUDIENCE_CATEGORY';
const errorDict: { [key in ErrorCode]: string } = {
	NETWORK_ERROR: 'Netzwerkfehler',
	NO_ACCESS_TO_CONTENT: 'Kein Zugriff auf Produktinhalt',
	UNAUTHENTICATED: 'Nicht authentifiziert',
	UNAUTHORIZED: 'Nicht autorisiert',
	INVALID_USER: 'Ungültiger Benutzer',
	TOO_MANY_LINKS: 'Zu viele Links',
	TOO_FEW_LINKS: 'Zu wenige Links',
	INVALID_CONTENT_ID: 'Ungültiger Inhalt',
	GROUP_NAME_ALREADY_TAKEN: 'Gruppenname ist schon vergeben',
	CONFIRMATION_CODE_INVALID: 'Bestätigungscode ungültig',
	EMAIL_ALREADY_CONFIRMED: 'Email bereits bestätigt',
	UNEXPECTED_ERROR: 'Unerwarteter Fehler',
	EMAIL_NOT_EXISTENT: 'Diese Emailadresse ist uns leider nicht bekannt.',
	SEND_CONFIRMATION_EMAIL_FAILED: 'Bestätigungsemail konnte nicht gesendet werden.',
	EMAIL_ALREADY_TAKEN: 'Diese Emailadresse wird bereits verwendet',
	EMAIL_INVALID: 'Keine gültige Email',
	PASSWORD_INVALID: 'Ungültiges Passwort',
	TERMS_NOT_ACCEPTED: 'Datenschutzbestimmungen und Nutzungbedingungen nicht zugestimmt',
	CONFIRMATION_CODE_EXPIRED: 'Bestätigungscode abgelaufen',
	INVALID_CONTENT: 'Produktinhalt ungültig',
	CANNOT_CHANGE_PASSWORD_OF_TEST_USER:
		'Das Passwort eines Testaccounts kann nicht geändert werden.',
	CONTENT_PROGRESS_ERROR: 'Fehler beim Speichern des Fortschritts.',
	INACTIVE_CONTENT: 'Produktinhalt ist nicht verfügbar.',
	NO_CERTIFICATE_FOUND: 'Zertifikat konnte nicht gefunden werden.',
	COMPANY_ALREADY_EXISTS: 'Firmenname ist schon vergeben',
	COPANY_NOT_EXISTENT: 'Firma existiert nicht',
	VOLUME_LICENSE_NOT_EXISTENT: 'Lizenz existiert nicht',
	CONTENT_NOT_COMPLETED: 'Produktinhalt ist nicht abgeschlossen',
	CERTIFICATE_ALREADY_EXISTS: 'Zertifikat existiert bereits',
	CONTENT_NOT_FOUND: 'Produktinhalt nicht gefunden',
	CONTENT_IS_DEMO: 'Produktinhalt ist eine Demo',
	INVALID_NUMBER_OF_LICENSES: 'Ungültige Anzahl an Lizenzen',
	UNABLE_TO_DECREASE_NUMBER_OF_LICENSES: 'Die Anzahl der Lizenz kann nicht verringert werden.',
	INVALID_ENTRIES: 'Ungültige Angaben ',
	VOLUME_LICENSE_NOT_FOUND: 'Volumenlizenz nicht gefunden',
	EXCEEDS_NUMBER_OF_LICENSES: 'Überschreitet die Anzahl der Lizenzen',
	CATEGORY_NOT_EXISTENT: 'Kategorie existiert nicht',
	INVALID_FILE_TYPE: 'Ungültiger Dateityp',
	USER_NOT_EXISTENT: 'Benutzer existiert nicht',
	INVALID_AUDIENCE_CATEGORY: 'Ungültige Publkiums-Kategorie',
};

export const useSnackbar = () => {
	const { enqueueSnackbar } = useSnackbarNotistack();

	return React.useCallback(
		({
			error,
			errorMessage,
			warning,
			success,
			info,
		}: {
			error?: ErrorCode;
			errorMessage?: string;
			warning?: string;
			success?: string;
			info?: string;
		}) => {
			enqueueSnackbar(
				error
					? errorDict[error] || 'Unbekannter Fehler'
					: warning || info || success || errorMessage,
				{
					variant:
						error || errorMessage ? 'error' : warning ? 'warning' : info ? 'info' : 'success',
				}
			);
		},
		[enqueueSnackbar]
	);
};
