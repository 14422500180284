import * as React from 'react';
import styled from 'styled-components';
import type { BaseTextFieldProps } from '@mui/material/TextField';
import IconButton from './IconButton';
import { IconName } from '../utils/iconNameDict';
import { Box, InputAdornment, TextField as MuiTextField } from '@mui/material';
import Tooltip from './Tooltip';

export type TextFieldRef = {
	validate: () => boolean;
};

const StyledIconButton = styled(IconButton)<{ $color?: string }>`
	color: ${(p) => p.$color};
`;

const TextField = React.forwardRef<
	TextFieldRef,
	{
		label?: string;
		defaultValue?: string;
		disabled?: boolean;
		onChange?: (value?: string) => void;
		m?: string;
		variant?: BaseTextFieldProps['variant'];
		flex?: boolean;
		width?: string;
		startLabel?: string;
		startIconName?: IconName;
		startIconNameColor?: string;
		onClickStartIcon?: () => void;
		endLabel?: string;
		endIconName?: IconName;
		backgroundColor?: string;
		toolTip?: string;
		type?: 'text' | 'number';
	}
>(
	(
		{
			label,
			defaultValue,
			disabled,
			onChange,
			m,
			variant,
			flex,
			width,
			startLabel,
			startIconName,
			startIconNameColor,
			onClickStartIcon,
			endLabel,
			endIconName,
			backgroundColor,
			toolTip,
			type = 'text',
		},
		ref
	): JSX.Element => {
		const [value, setValue] = React.useState<string>(defaultValue || '');
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();

		const handleChange = React.useCallback(
			(event) => {
				const newValue = event?.target?.value;
				setErrorMessage(undefined);
				setValue(newValue);
				onChange?.(newValue);
			},
			[onChange]
		);

		const inputProps = React.useMemo(() => {
			return startIconName || startLabel || toolTip
				? {
						startAdornment: startLabel ? (
							<InputAdornment position="start">{startLabel}</InputAdornment>
						) : startIconName ? (
							<InputAdornment position="start">
								<StyledIconButton
									onClick={onClickStartIcon}
									iconName={startIconName}
									$color={startIconNameColor}
								/>
							</InputAdornment>
						) : toolTip ? (
							<InputAdornment position="start">
								<Tooltip width="40rem" text={toolTip} />
							</InputAdornment>
						) : null,
				  }
				: endIconName || endLabel
				? {
						endAdornment: endLabel ? (
							<InputAdornment position="start">{endLabel}</InputAdornment>
						) : endIconName ? (
							<InputAdornment position="start">
								<IconButton iconName={endIconName} />
							</InputAdornment>
						) : null,
				  }
				: undefined;
		}, [
			endIconName,
			endLabel,
			onClickStartIcon,
			startIconName,
			startIconNameColor,
			startLabel,
			toolTip,
		]);

		const mergedInputProps = React.useMemo(
			() => ({ style: { backgroundColor }, inputProps }),
			[backgroundColor, inputProps]
		);

		const handleValidate = React.useCallback(() => {
			let invalid = false;
			if (!disabled && !value) {
				invalid = true;
				setErrorMessage('Bitte wählen');
			}
			return invalid;
		}, [disabled, value]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		React.useEffect(() => {
			if (disabled) {
				setErrorMessage(undefined);
			}
		}, [disabled]);

		return (
			<Box m={m} flex={flex ? '1' : undefined} width={width} ref={ref}>
				<MuiTextField
					label={label}
					value={value}
					fullWidth
					onChange={handleChange}
					InputProps={mergedInputProps}
					disabled={disabled}
					type={type}
					variant={variant}
					error={Boolean(errorMessage)}
					helperText={errorMessage}
				/>
			</Box>
		);
	}
);

export default React.memo(TextField);
