import client from '../utils/client';
import { gql } from '@apollo/client';
import { VolumeLicenseInfoFragment } from '../utils/volumeLicense';

type Error =
	| 'NETWORK_ERROR'
	| 'UNAUTHORIZED'
	| 'INVALID_NUMBER_OF_LICENSES'
	| 'VOLUME_LICENSE_NOT_EXISTENT'
	| 'UNABLE_TO_DECREASE_NUMBER_OF_LICENSES';

const updateVolumeLicense = async ({
	id,
	numberOfLicenses,
	active,
}: {
	id: string;
	numberOfLicenses?: number;
	active?: boolean;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateVolumeLicense($id: ID!, $numberOfLicenses: Int, $active: Boolean) {
					updateVolumeLicense(id: $id, numberOfLicenses: $numberOfLicenses, active: $active) {
						...VolumeLicenseInfoFragment
					}
				}
				${VolumeLicenseInfoFragment}
			`,
			variables: { id, numberOfLicenses, active },
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
			};
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default updateVolumeLicense;
