import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import FilterableContents2 from '../components/FilterableContents2';
import image from '../images/BF_Grafik_Header_Katalog.png';
import { useParams } from 'react-router-dom';
import SectionWrapper from '../components/SectionWrapper';
import { useApiFilteredContents } from '../utils/content';
import { CurrentUser, useCurrentUser } from '../utils/user';
import { CircularProgress } from '@mui/material';

const ContentListView = (): JSX.Element => {
	const { userType } = useParams();
	const currentUser = useCurrentUser();

	const allContents = useApiFilteredContents({
		contentIsActive: true,
		contentIsDemo: false,
		userAudiences: currentUser ? currentUser?.audienceType : undefined,
	});
	return currentUser === undefined ? (
		<CircularProgress />
	) : (
		<ContentWrapper>
			<ImageHeader src={image} title="Alle Inhalte" />
			<SectionWrapper>
				<FilterableContents2
					audienceType={userType as CurrentUser['audienceType']}
					contents={allContents}
					defaultShowFilter={true}
				/>
			</SectionWrapper>
		</ContentWrapper>
	);
};

export default ContentListView;
