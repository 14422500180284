import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import GridContainer from './GridContainer';
import Button from './Button';
import Logo from './Logo';
import Txt from './Txt';
import ImageBox from './ImageBox';
import { useCategoriesOfGroup } from '../utils/category';

import image from '../images/Made_in_Germany_v5.png';

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${(p) => p.theme.customColors.decentBlue};
`;

const HorizontalLine = styled(Box)`
	width: 100%;
	border-bottom: 1px solid ${(p) => p.theme.customColors.darkGrey};
`;

const horizontalSpacing = { xs: 4, sm: 4, md: 6, lg: 6, xl: 6 };
const verticalSpacing = { xs: 4, sm: 4, md: 6, lg: 6, xl: 6 };

const overViewLinks = [
	{ name: 'Über uns', path: '/about-us' },
	{ name: 'FAQ', path: '/faq' },
	{ name: 'Nutzungsbedingungen', path: '/terms' },
	{ name: 'Zahlung und Versand', path: '/payment-terms' },
	{ name: 'Kontakt', path: '/contact' },
];

const proLinks = [
	{ name: 'Über bildungsfreaks Pro', path: '/about-us' },
	{ name: 'Preise', path: '/pricing' },
];

const termsOfServiceLinks = [
	{ name: 'Datenschutzbestimmungen', path: '/data-privacy' },
	{ name: 'Impressum', path: '/imprint' },
	{ name: 'Lizenzhinweise', path: '/licenses' },
];

const imageGridItemProps = {
	item: true,
	xs: 12,
	sm: 12,
	md: 12,
	lg: 2.4,
};

const gridItemProps = {
	item: true,
	xs: 12,
	sm: 6,
	md: 3,
	lg: 2.4,
};

const Column = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Row = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
`;

const audienceDict = {
	TEACHER: 'Lehrer*innen',
	PUPILS: 'Schüler*innen',
	PARENTS: 'Eltern',
};

const Footer = ({ isAccessPhraseUser }: { isAccessPhraseUser?: boolean }) => {
	const educationFormatCategories = useCategoriesOfGroup('EDUCATION_FORMAT');
	const audienceCategories = useCategoriesOfGroup('AUDIENCE');
	const filteredAudienceCategories = React.useMemo(
		() => audienceCategories?.filter((category) => category.type !== 'COMPANY'),
		[audienceCategories]
	);
	const theme = useTheme();
	const sectionVerticalSpacingRem = theme.sectionVerticalSpacingRem;

	const location = useLocation();
	const isPlayerRoute = location.pathname.includes('/player/');
	return isPlayerRoute ? null : (
		<Wrapper m={`${sectionVerticalSpacingRem}rem 0 0 0`}>
			<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
				<Grid {...imageGridItemProps}>
					<Logo navigateTo="/" maxWidth="12rem" />
				</Grid>
				{!isAccessPhraseUser ? (
					<>
						<Grid {...gridItemProps}>
							<Txt variant="h6" fontWeight="bold">
								Übersicht
							</Txt>
							{overViewLinks.map((link) => (
								<Button color="secondary" variant="text" key={link.name} navigateTo={link.path}>
									{link.name}
								</Button>
							))}
						</Grid>
						{filteredAudienceCategories?.length ? (
							<Grid {...gridItemProps}>
								<Txt variant="h6" fontWeight="bold">
									Produkte
								</Txt>
								{filteredAudienceCategories?.map((category) => (
									<Button
										color="secondary"
										variant="text"
										key={category.id}
										navigateTo={`/${category.type.toLowerCase()}`}
									>
										{`Für  ${audienceDict[category.type]}`}
									</Button>
								))}
							</Grid>
						) : null}
						<Grid {...gridItemProps}>
							<Txt variant="h6" fontWeight="bold">
								Bildungsfreaks PRO
							</Txt>
							{proLinks.map((link) => (
								<Button color="secondary" variant="text" key={link.name} navigateTo={link.path}>
									{link.name}
								</Button>
							))}
						</Grid>
						{educationFormatCategories?.length ? (
							<Grid {...gridItemProps}>
								<Txt variant="h6" fontWeight="bold">
									Kategorien
								</Txt>
								<Button color="secondary" variant="text" navigateTo={`/contents`}>
									Alle Inhalte
								</Button>
								{educationFormatCategories?.map((category) => (
									<Button
										color="secondary"
										variant="text"
										key={category.title}
										navigateTo={`/category/${category.id}`}
									>
										{category.title}
									</Button>
								))}
							</Grid>
						) : null}
					</>
				) : null}

				<Grid item xs={12}>
					<Column>
						<Row>
							<HorizontalLine m="0 0 1rem 0" />
						</Row>

						<Row>
							<Button color="secondary" variant="text" m="0 3rem 0 0">
								© 2022 bildungsfreaks
							</Button>
							{termsOfServiceLinks.map((link, index) => (
								<Button
									key={index}
									m="0 3rem 0 0"
									color="secondary"
									variant="text"
									navigateTo={link.path}
								>
									{link.name}
								</Button>
							))}
							<Box flex="1" />
							<ImageBox width="7rem" src={image} />
						</Row>
					</Column>
				</Grid>
			</GridContainer>
		</Wrapper>
	);
};

export default Footer;
