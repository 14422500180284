import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import SectionWrapper from '../components/SectionWrapper';
import Contents from '../components/Contents';
import EducationFormatCategoriesSection from '../components/EducationFormatCategoriesSection';
import SectionHeader from '../components/SectionHeader';
import CategoriesRow from '../components/CategoriesRow';
import { useCurrentUser } from '../utils/user';
import { useRecommendedApiContents } from '../utils/content';
import BildungsfreaksFavouritesSection from '../components/BildungsfreaksFavouritesSection';

import teacherImage from '../images/BF_Header_FuerLehrer.png';
import { useAvailableCategories } from '../utils/category';

const LoggedInUserView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();

	const progressedContents = React.useMemo(
		() =>
			currentUser?.progressedContents
				.filter((c) => c.completion !== 1)
				.sort((a, b) => (a.lastProgressedAt! > b.lastProgressedAt! ? -1 : 1)),
		[currentUser?.progressedContents]
	);

	const recommendedContents = useRecommendedApiContents(currentUser?.audienceType);
	const availableCategories = useAvailableCategories('COLLECTION', recommendedContents);
	const accessibleProductContents = currentUser?.accessibleProductContents;

	return !currentUser ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={teacherImage}
				title={`Hallo ${currentUser.firstName || currentUser.userName}`}
				description="Willkommen bei bildungsfreaks!"
			/>
			{progressedContents?.length ? (
				<SectionWrapper flexDirection="column">
					<Contents
						filterAfterCategoryGroup="EDUCATION_FORMAT"
						title="Meine aktiven Kurse"
						subTitle="An diesen Kursen bist du dran"
						buttonLabel="Mehr entdecken"
						navigateTo="/contents"
						contents={progressedContents}
						showProgress
					/>
				</SectionWrapper>
			) : null}
			{accessibleProductContents?.length ? (
				<SectionWrapper flexDirection="column">
					<Contents
						title="Gekaufte Kurse"
						subTitle="Diese Kurse hast du erworben."
						contents={accessibleProductContents}
						showProgress
					/>
				</SectionWrapper>
			) : null}

			<SectionWrapper flexDirection="column">
				<Contents
					contents={recommendedContents}
					buttonLabel="Mehr entdecken"
					navigateTo="/contents"
					title="Kennst du die schon?"
					subTitle="Entdecke noch mehr Kurse!"
					maxItems={6}
				/>
			</SectionWrapper>
			{currentUser.audienceType === 'TEACHER' || currentUser.audienceType === 'PARENTS' ? (
				<EducationFormatCategoriesSection />
			) : null}
			<SectionWrapper padding="2rem" altBackground flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title="Mach bildungsfreaks zu deinem Ding!"
					subTitle="Finde die passenden Kurse nach Themengiebieten"
				/>
				<CategoriesRow categories={availableCategories || undefined} />
			</SectionWrapper>
			{currentUser.audienceType === 'TEACHER' || currentUser.audienceType === 'PARENTS' ? (
				<BildungsfreaksFavouritesSection />
			) : null}
		</ContentWrapper>
	);
};

export default LoggedInUserView;
