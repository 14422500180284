import React from 'react';
import SelectField from '../components/SelectField';

export type FederalState =
	| 'Baden-Württemberg'
	| 'Bayern'
	| 'Berlin'
	| 'Brandenburg'
	| 'Bremen'
	| 'Hamburg'
	| 'Hessen'
	| 'Mecklenburg-Vorpommern'
	| 'Niedersachsen'
	| 'Nordrhein-Westfalen'
	| 'Rheinland-Pfalz'
	| 'Saarland'
	| 'Sachsen-Anhalt'
	| 'Sachsen'
	| 'Schleswig-Holstein'
	| 'Thüringen';

const federalStates: FederalState[] = [
	'Baden-Württemberg',
	'Bayern',
	'Berlin',
	'Brandenburg',
	'Bremen',
	'Hamburg',
	'Hessen',
	'Mecklenburg-Vorpommern',
	'Niedersachsen',
	'Nordrhein-Westfalen',
	'Rheinland-Pfalz',
	'Saarland',
	'Sachsen-Anhalt',
	'Sachsen',
	'Schleswig-Holstein',
	'Thüringen',
];

export type SelectFieldRef = {
	validate: () => boolean;
};

const federalStatesSelectOptions = federalStates.map((state) => ({ label: state, value: state }));

const FederalStateSelect = React.forwardRef<
	SelectFieldRef,
	{
		defaultValue?: FederalState;
		m?: string;
		flex?: boolean;
		backgroundColor?: string;
		onChange: (federalState: FederalState | null) => void;
		fullWidth?: boolean;
	}
>(({ defaultValue, m, flex, backgroundColor, onChange, fullWidth }, ref): JSX.Element => {
	const handleChange = React.useCallback(
		(newValue) => {
			onChange?.(newValue as FederalState | null);
		},
		[onChange]
	);

	return (
		<SelectField
			margin={m}
			flex={flex}
			defaultValue={defaultValue}
			backgroundColor={backgroundColor}
			fullWidth={fullWidth}
			label="Bundesland"
			items={federalStatesSelectOptions}
			onChange={handleChange}
			ref={ref}
		/>
	);
});

export default FederalStateSelect;
