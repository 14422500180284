import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import Txt from '../components/Txt';
import ContentWrapper from '../components/ContentWrapper';
import Button from '../components/Button';

import { useCurrentUser } from '../utils/user';
import { useAllContentDetails } from '../utils/content';
import ImageBox from '../components/ImageBox';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 1rem;
`;

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const ContentRow = ({ title, imageUrl }: { title: string; imageUrl?: string }) => {
	return (
		<Row boxShadow={3} m="0 0 1rem 0" alignSelf="stretch">
			<Row overflow="hidden" flex="1">
				<ImageBox src={imageUrl} width="5rem" m="0 1rem 0 0" />
				<Txt variant="body1">{title}</Txt>
				<Box flex="1" />
				<Button m="0 1rem 0 0">Editieren</Button>
				<Button m="0 1rem 0 0">Löschen</Button>
			</Row>
		</Row>
	);
};

const AdminContentListView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();
	const allContents = useAllContentDetails();
	return currentUser === undefined ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<Wrapper p="2rem 1rem 1rem 1rem">
				{allContents?.map((content) => (
					<ContentRow key={content.id} {...content} />
				))}
			</Wrapper>
		</ContentWrapper>
	);
};

export default AdminContentListView;
