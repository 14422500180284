import axios from 'axios';

import { UPLOAD_CATEGORY_IMAGE_ENDPOINT } from '../consts';
import { getToken } from '../utils/user';

type Error = 'UNAUTHENTICATED' | 'UNEXPECTED_ERROR' | 'INVALID_FILE_TYPE';

type UploadFileTypes = 'CATEGORY_IMAGE';

const fileTypeDict: { [key in UploadFileTypes]: { endpoint: string; fieldName: string } } = {
	CATEGORY_IMAGE: {
		endpoint: UPLOAD_CATEGORY_IMAGE_ENDPOINT,
		fieldName: 'categoryImage',
	},
};

const uploadFile = async ({
	file,
	type,
}: {
	file: File;
	type: 'CATEGORY_IMAGE';
}): Promise<
	| { success: true; error: undefined; errorMessage: undefined; fileUrl: string }
	| { success: false; error?: Error; errorMessage: string; fileUrl: undefined }
> => {
	try {
		const formData = new FormData();
		const fieldName = fileTypeDict[type]?.fieldName;
		const endpoint = fileTypeDict[type]?.endpoint;
		if (!fieldName || !endpoint) {
			return {
				success: false,
				error: 'INVALID_FILE_TYPE',
				errorMessage: 'Invalid file type',
				fileUrl: undefined,
			};
		}
		formData.append(fieldName, file);
		const {
			data: { success, errorMessage, fileUrl },
		} = await axios({
			method: 'POST',
			url: endpoint,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data', 'authorization': getToken() },
		});
		if (!success) {
			return { success: false, error: undefined, errorMessage, fileUrl: undefined };
		} else {
			return { success: true, error: undefined, errorMessage: undefined, fileUrl };
		}
	} catch (e) {
		return {
			success: false,
			error: 'UNEXPECTED_ERROR',
			errorMessage: e.message,
			fileUrl: undefined,
		};
	}
};

export default uploadFile;
