import React from 'react';
import { Grid, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from '../utils/hooks';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import AuthWrapper from '../components/AuthWrapper';
import FilledTextField from '../components/FilledTextField';

import useTextFieldController from '../utils/useTextFieldController';
import { checkValidationFunctions, validateEmail, validatePassword } from '../utils/helpers';
import { fetchUser, setToken } from '../utils/user';
import inviteLogin from '../mutations/inviteLogin';

const InviteLoginView = (): JSX.Element => {
	const [searchParams] = useSearchParams();
	const defaultEmail = searchParams.get('email') || '';
	const defaultPassword = searchParams.get('password') || '';

	const navigate = useNavigate();
	const {
		newStateValue: email,
		textInputProps: emailFieldProps,
		validate: validateEmailFn,
	} = useTextFieldController({
		defaultValue: defaultEmail,
		inputLabel: 'Email',
		stateKey: 'email',
		validationFunction: validateEmail,
		stateValueFallback: '',
		textFieldType: 'email',
	});

	const {
		newStateValue: password,
		textInputProps: passwordFieldProps,
		validate: validatePasswordFn,
	} = useTextFieldController({
		defaultValue: defaultPassword,
		inputLabel: 'Passwort',
		stateKey: 'password',
		validationFunction: validatePassword,
		stateValueFallback: '',
		textFieldType: 'password',
	});

	const [loading, setLoading] = React.useState(false);

	const handleLogin = React.useCallback(async () => {
		const isValid = checkValidationFunctions([validateEmailFn, validatePasswordFn]);
		if (isValid) {
			setLoading(true);
			const { success, jwt, error } = await inviteLogin(email!, password!);
			setLoading(false);
			if (success) {
				setToken(jwt!);
				await fetchUser();
				navigate('/');
			} else {
				switch (error) {
					case 'USER_NOT_FOUND':
						Dialog.render({
							title: 'Fehler',
							description: 'Diese Emailadresse ist uns nicht bekannt.',
						});
						break;
					case 'INVITED_USERS_ONLY':
						Dialog.render({
							title: 'Fehler',
							description: 'Emailadresse wurde noch nicht bestätigt.',
						});
						break;
					case 'CREDENTIALS_DO_NOT_MATCH':
						Dialog.render({
							title: 'Fehler',
							description: 'Email und Passwort stimmen nicht überein.',
						});
						break;
					case 'NETWORK_ERROR':
						Dialog.render({
							title: 'Fehler',
							description: 'Netzwerkfehler',
						});
						break;
					case 'INVITE_EXPIRED':
						Dialog.render({
							title: 'Fehler',
							description: 'Der Einladungslink ist abgelaufen. Bitte fordere einen neuen an.',
						});
						break;
					default:
						Dialog.render({
							title: 'Fehler',
							description: 'Es ist ein unbekannter Fehler aufgetreten.',
						});
				}
			}
		}
	}, [email, navigate, password, validateEmailFn, validatePasswordFn]);

	const textFieldsProps = React.useMemo(
		() => [emailFieldProps, passwordFieldProps],
		[emailFieldProps, passwordFieldProps]
	);

	const autoLoginRef = React.useRef<boolean>(false);
	React.useEffect(() => {
		if (defaultEmail && defaultPassword && autoLoginRef.current === false) {
			handleLogin();
			autoLoginRef.current = true;
		}
	}, [defaultEmail, defaultPassword, email, handleLogin, password]);

	return (
		<AuthWrapper title="Login">
			<>
				<>
					{textFieldsProps.map((textFieldProps) => (
						<Grid key={textFieldProps.label} item xs={12}>
							<FilledTextField fullWidth variant="filled" {...textFieldProps} />
						</Grid>
					))}
				</>
				<Grid item xs={12}>
					<Button variant="link" navigateTo="/reset-password" disabled={loading}>
						Passwort vergessen?
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth onClick={handleLogin} variant="mainButton" loading={loading}>
						Anmelden
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Box width="100%" display="flex" flexDirection="row" alignItems="center">
						<Button m="0 1rem 0 0 " variant="link" navigateTo="/signup" disabled={loading}>
							Ein Konto erstellen
						</Button>
						<Button variant="link" navigateTo="/phrase-login" disabled={loading}>
							Mit Phrase anmelden
						</Button>
					</Box>
				</Grid>
			</>
		</AuthWrapper>
	);
};

export default InviteLoginView;
