import React from 'react';

import { TextField } from '@mui/material';
import { useSnackbar } from '../utils/snackBar';
import Button from './Button';
import DialogWrapper from './DialogWrapper';
import Dialog, { DialogButtonRow, DialogHeader } from './Dialog';
import useTextFieldController from '../utils/useTextFieldController';
import inviteToVolumeLicense from '../mutations/inviteToVolumeLicense';

const InviteToVolumeLicenseDialog = ({
	dismissPortal,
	id,
}: {
	dismissPortal: () => void;
	id?: string;
}): JSX.Element => {
	const snackBar = useSnackbar();

	const {
		newStateValue: csvOrTsvString,
		textInputProps: csvOrTsvFieldProps,
		validate: validateCsvOrTsvString,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'CSV oder TSV mit Vorname, Nachname, E-Mail-Adresse',
		stateKey: 'emails',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const [loading, setLoading] = React.useState(false);

	const handleSubmitEmails = React.useCallback(async () => {
		const invalid = validateCsvOrTsvString!();
		if (invalid) {
			return;
		}
		setLoading(true);
		const { error, errorMessage } = await inviteToVolumeLicense({
			id: id!,
			csvOrTsvString: csvOrTsvString!,
		});
		setLoading(false);
		if (error) {
			if (error === 'INVALID_ENTRIES') {
				Dialog.render({
					title: 'Ungültige Einträge',
					description: errorMessage,
					isMarkdown: true,
				});
			} else if (error === 'EXCEEDS_NUMBER_OF_LICENSES') {
				Dialog.render({
					title: 'Unzureichende Volumenlizenz',
					description: errorMessage,
					isMarkdown: true,
				});
			} else {
				snackBar({ error });
			}
		} else {
			snackBar({ success: 'Nutzer erfolgreich hinzugefügt' });
			dismissPortal();
		}
	}, [csvOrTsvString, dismissPortal, id, snackBar, validateCsvOrTsvString]);

	return (
		<DialogWrapper p="2rem">
			<>
				<DialogHeader
					title="Nutzer zu Volumenlizenz einladen"
					description="Bitte kopiere die Informationen der einzuladenen Nutzer aus der CSV oder TSV Datei hier herein. Die Struktur muss wie folgt sein: Vorname, Nachname, email-Adresse oder Vorname; Nachname; email-Adresse."
				/>
				<TextField fullWidth multiline maxRows={10} {...csvOrTsvFieldProps} />
				<DialogButtonRow>
					<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
						Abbrechen
					</Button>
					<Button variant="mainButton" onClick={handleSubmitEmails} loading={loading}>
						Einladen
					</Button>
				</DialogButtonRow>
			</>
		</DialogWrapper>
	);
};

export default InviteToVolumeLicenseDialog;
