import React from 'react';
import { Grid, Box } from '@mui/material';
import { useSnackbar } from '../utils/snackBar';

import Button from './Button';
import Txt from './Txt';
import ImageBox from './ImageBox';
import GridItem from './GridItem';
import image from '../images/BF_Grafik_IchWillMehr_FuerSchueler.png';
import SectionWrapper from './SectionWrapper';

const IWantMoreSectionSection = (): JSX.Element => {
	const snackBar = useSnackbar();
	const handleClick = React.useCallback(() => {
		// TODO:
		snackBar({ warning: 'not implemented' });
	}, [snackBar]);

	return (
		<SectionWrapper>
			<Grid container>
				<GridItem xs={12} sm={12} md={6}>
					<Box flexDirection="column">
						<Txt width="100%" variant="h5" fontWeight="bold" m="0 0 1rem">
							Ich will mehr!
						</Txt>
						<Txt width="100%" m="0 0 1rem">
							Du möchtest dich noch schlauer machen und mehr Kurse absolvieren? Dann sprich mit
							deinen Lehrer*innen, deiner Schule oder deinen Eltern.
						</Txt>
						<Button variant="mainButton" onClick={handleClick}>
							Mehr Infos
						</Button>
					</Box>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<ImageBox width="100%" src={image} />
				</GridItem>
			</Grid>
		</SectionWrapper>
	);
};

export default React.memo(IWantMoreSectionSection);
