import React from 'react';
import Button from './Button';
import GridItem from './GridItem';
import Txt from './Txt';
import ImageBox from './ImageBox';
import GridContainer from './GridContainer';
import image from '../images/Pro_Badge.png';
import SectionWrapper from './SectionWrapper';
import { useCurrentUser } from '../utils/user';

const horizontalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const verticalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };

const gridItemProps = {
	xs: 12,
	sm: 4,
	md: 4,
};

const ProBannerSmallSectionSection = () => {
	const currentUser = useCurrentUser();
	const hasProMembership = currentUser?.membership?.level === 2;
	return hasProMembership || currentUser?.audienceType === 'PUPILS' ? null : (
		<SectionWrapper altBackground>
			<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
				<GridItem {...gridItemProps}>
					<ImageBox height="10rem" objectFit="contain" src={image} />
				</GridItem>
				<GridItem {...gridItemProps}>
					<Txt m="1rem">
						Vollen Zugriff auf alle digitalen Bildungsmaterialien und exklusiven Content
					</Txt>
				</GridItem>
				<GridItem {...gridItemProps}>
					<Button navigateTo="/pricing" m="1rem" variant="mainButton">
						Jetzt starten
					</Button>
				</GridItem>
			</GridContainer>
		</SectionWrapper>
	);
};

export default React.memo(ProBannerSmallSectionSection);
