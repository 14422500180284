import * as React from 'react';
import Button from './Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ButtonProps } from '@mui/material';
import { useTheme } from 'styled-components';

const SelectButton = ({
	variant,
	label,
	options,
	onChange,
	m,
}: {
	variant?: ButtonProps['variant'];
	label: string;
	options: { label: string; value: string }[];
	onChange: (value?: string) => void;
	m?: string;
}) => {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const ref = React.useRef<HTMLButtonElement>(null);

	const [selectedIndex, setSelectedIndex] = React.useState(null);

	const handleClick = React.useCallback(
		(event) => {
			const index = event.target.value;
			const value = selectedIndex === index ? undefined : options[index].value;
			setSelectedIndex(selectedIndex === index ? undefined : index);
			onChange?.(value);
		},
		[onChange, options, selectedIndex]
	);

	const handleOpen = React.useCallback(() => {
		const target = ref.current;
		setAnchorEl(target);
	}, []);

	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
	}, []);

	return (
		<>
			<Button onClick={handleOpen} variant={variant} ref={ref} m={m}>
				{label}
			</Button>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{options.map(({ label: optionLabel }, index) => (
					<MenuItem
						key={index}
						value={index}
						onClick={handleClick}
						selected={selectedIndex === index}
						style={{
							backgroundColor: selectedIndex === index ? theme.customColors.decentBlue : undefined,
						}}
					>
						{optionLabel}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default React.memo(SelectButton);
