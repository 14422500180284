export const triggerDownload = (url: string) => {
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = url;
	const body = document.getElementsByTagName('body')[0];
	body.appendChild(a);
	a.setAttribute('target', '_blank');
	a.click();
	body.removeChild(a);
};
