import React from 'react';
import { Grid, Box } from '@mui/material';
import styled from 'styled-components';

import Txt from './Txt';
import ImageBox from './ImageBox';
import GridContainer from './GridContainer';
import SectionHeader from './SectionHeader';
import SectionWrapper from './SectionWrapper';

import image1 from '../images/Icon_Ueberall_Lernen.svg';
import image2 from '../images/Icon_Zusammen_Lernen.svg';
import image3 from '../images/Icon_Eigenes_Tempo.svg';

const FlexGridColumn = styled(Grid)`
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: center;
`;

const data = [
	{
		icon: <ImageBox src={image1} fontSize="large" />,
		title: 'Überall lernen.',
		description:
			'Lerne zeit- und ortsunabhängig. Ob in der Mittagspause oder auf dem Heimweg. Mobil auf deinem Smartphone, Tablet oder auf deinem Computer.',
	},
	{
		icon: <ImageBox src={image2} fontSize="large" />,
		title: 'Zusammen lernen.',
		description:
			'Als Lehrkraft teilst du Kurse ganz einfach in der Klasse aus. So gelingt gemeinsames Lernen – ganz ohne Anmeldung für die Schüler*innen.',
	},
	{
		icon: <ImageBox src={image3} fontSize="large" />,
		title: 'Im eigenen Tempo lernen.',
		description:
			'Bei bildungsfreaks lernst du ohne Zeitdruck. Du wählst die Reihenfolge deiner Kurse und Module und wieviel Zeit du dir dafür nimmst.',
	},
];

const horizontalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };
const verticalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };

const PerksSection = (): JSX.Element => {
	return (
		<SectionWrapper>
			<GridContainer
				excludePadding
				horizontalSpacing={horizontalSpacing}
				verticalSpacing={verticalSpacing}
			>
				<Grid item xs={12}>
					<SectionHeader title="Warum bildungsfreaks?" />
				</Grid>
				<>
					{data.map(({ icon, title, description }) => (
						<FlexGridColumn key={title} item xs={12} sm={12} md={4}>
							<Box display="flex" flexDirection="row" justifyContent="center" height="7rem">
								{icon}
							</Box>
							<Txt align="center" width="100%" m="1rem 0" variant="h6">
								{title}
							</Txt>
							<Txt align="center">{description}</Txt>
						</FlexGridColumn>
					))}
				</>
			</GridContainer>
		</SectionWrapper>
	);
};

export default PerksSection;
