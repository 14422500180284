import React from 'react';

import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

export type TextInputPropsType = {
	disabled?: boolean;
	value: string;
	onChange: (valueOrEvent: string | { target: { value: string } }) => void;
	onFocus: () => void;
	onBlur: () => void;
	label: string;
	key: string;
	error: boolean;
	errorMessage?: string;
	helperText?: string;
	type?: MuiTextFieldProps['type'];
};

const valueExceedsLimit = (value?: string | number | null, limit?: number): boolean => {
	return limit && value
		? typeof value === 'string'
			? value.length > limit
			: typeof value === 'number'
			? value.toString().length > limit
			: false
		: false;
};

type StateValueFallbackType = '' | null | undefined;

const useTextFieldController = <InputType extends number | string>({
	defaultValue,
	inputLabel,
	stateKey,
	type = 'STRING',
	limit,
	validationFunction,
	disabledHelperText,
	disabled,
	stateValueFallback = undefined,
	textFieldType,
	trim,
}: // exactLength,
{
	defaultValue?: InputType;
	inputLabel: string;
	stateKey: string;
	type?: 'STRING' | 'NUMBER';
	limit?: number;
	validationFunction?: (value: string) => string | undefined;
	disabledHelperText?: string;
	disabled?: boolean;
	stateValueFallback?: StateValueFallbackType;
	textFieldType?: MuiTextFieldProps['type'];
	trim?: boolean;
	// exactLength?: number;
}): {
	textInputProps: TextInputPropsType;
	newState: { [key: string]: InputType | StateValueFallbackType };
	newStateValue: InputType | StateValueFallbackType;
	changed: boolean;
	exceeded: boolean;
	selected: boolean;
	error: boolean;
	validate?: () => boolean;
} => {
	const [stateValue, setStateValue] = React.useState<InputType | StateValueFallbackType>(
		defaultValue || stateValueFallback
	);
	const [textFieldValue, setTextFieldValue] = React.useState<string>(
		(defaultValue && defaultValue.toString()) || ''
	);
	const [changed, setChanged] = React.useState(false);
	// const [hasNotExactLength, setHasNotExactLength] = React.useState<boolean>(false);
	const [exceeded, setExceeded] = React.useState(valueExceedsLimit(textFieldValue, limit));
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

	const getHelperText = React.useCallback(() => {
		if (errorMessage) {
			return errorMessage;
			// } else if (exactLength) {
			// 	return `${textFieldValue.length}/${exactLength}`;
		} else if (limit) {
			return `${textFieldValue.length}/${limit}`;
		} else if (disabled && disabledHelperText) {
			return disabledHelperText;
		} else {
			return undefined;
		}
	}, [disabled, disabledHelperText, errorMessage, limit, textFieldValue.length]);

	const [helperText, setHelperText] = React.useState<string | undefined>(getHelperText());
	const [selected, setSelected] = React.useState(false);

	React.useEffect(() => {
		setHelperText(getHelperText());
	}, [disabled, getHelperText]);

	const handleValidate = React.useCallback(() => {
		const newErrorMessage = validationFunction ? validationFunction(textFieldValue) : undefined;
		setErrorMessage(newErrorMessage);
		return Boolean(newErrorMessage);
	}, [textFieldValue, validationFunction]);

	const handleInputChange = React.useCallback(
		(eventOrValue) => {
			setErrorMessage(undefined);
			const newValue = eventOrValue?.target?.value || eventOrValue;
			const newTextFieldValue: string | '' =
				typeof newValue === 'string' ? (trim ? newValue.trim() : newValue) : '';
			const newStateValue =
				newTextFieldValue !== ''
					? type === 'NUMBER'
						? Number(newTextFieldValue)
						: newTextFieldValue
					: stateValueFallback;
			const stateValueChanged =
				!newStateValue && !defaultValue ? false : newStateValue !== defaultValue;
			setTextFieldValue(newTextFieldValue);
			// if (exactLength) {
			// 	setHasNotExactLength(newTextFieldValue.length !== exactLength);
			// }
			setExceeded(valueExceedsLimit(newTextFieldValue, limit));
			setHelperText(getHelperText());
			setStateValue(newStateValue as InputType | StateValueFallbackType);
			setChanged(stateValueChanged);
		},
		[trim, type, stateValueFallback, defaultValue, limit, getHelperText]
	);

	const handleFocus = React.useCallback(() => {
		setSelected(true);
	}, []);

	const handleBlur = React.useCallback(() => {
		setSelected(false);
	}, []);

	const error = React.useMemo(() => Boolean(errorMessage) || exceeded, [errorMessage, exceeded]);

	return React.useMemo(
		() => ({
			textInputProps: {
				value: textFieldValue,
				onChange: handleInputChange,
				onFocus: handleFocus,
				onBlur: handleBlur,
				label: inputLabel,
				key: stateKey,
				error,
				helperText,
				disabled,
				type: textFieldType,
			},
			newState: { [stateKey]: stateValue },
			newStateValue: stateValue,
			changed,
			exceeded,
			selected,
			error,
			validate: handleValidate,
		}),
		[
			textFieldValue,
			handleInputChange,
			handleFocus,
			handleBlur,
			inputLabel,
			stateKey,
			error,
			helperText,
			disabled,
			textFieldType,
			stateValue,
			changed,
			exceeded,
			selected,
			handleValidate,
		]
	);
};

export default useTextFieldController;
