import client from '../utils/client';
import { gql } from '@apollo/client';
import { UserInfoFragment } from '../utils/user';

type Error = 'USER_NOT_EXISTENT' | 'UNAUTHENTICATED' | 'NETWORK_ERROR';

const updateUser = async ({
	lastName,
	organizationName,
}: {
	lastName?: string;
	organizationName?: string;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateUser($lastName: String, $organizationName: String) {
					updateUser(lastName: $lastName, organizationName: $organizationName) {
						...UserInfoFragment
					}
				}
				${UserInfoFragment}
			`,
			variables: { lastName, organizationName },
		});
		if (errors) {
			return { success: false, error: errors[0].message as Error };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default updateUser;
