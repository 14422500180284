import React from 'react';
import { gql } from '@apollo/client';

export const CertificateInfoFragment = gql`
	fragment CertificateInfoFragment on Certificate {
		id
		createdAt
		content {
			id
			title
		}
		user {
			id
			firstName
			lastName
		}
	}
`;

export type Certificate = {
	id: string;
	createdAt: string;
	content: {
		id: string;
		title: string;
	};
	user: {
		id: string;
		firstName: string;
		lastName: string;
	};
};
