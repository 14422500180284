import React from 'react';

import { useSnackbar } from '../utils/snackBar';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '../components/Button';
import { useCompanies } from '../utils/company';
import createVolumeLicense from '../mutations/createVolumeLicense';
import updateVolumeLicense from '../mutations/updateVolumeLicense';
import DialogWrapper from '../components/DialogWrapper';
import { useVolumeLicense } from '../utils/volumeLicense';
import TimePeriodSelectForm, {
	TimePeriodSelectFormSimpleRef,
	TimeUnit,
} from '../components/TimePeriodSelectForm';
import { useAllProducts } from '../utils/product';
import Dialog from './Dialog';
import { IntegerSelectFieldRef } from '../components/IntegerSelectField';
import BooleanSelectField, { BooleanSelectFieldRef } from '../components/BooleanSelectField';
import IntegerTextField from '../components/IntegerTextField';
import { DialogButtonRow, DialogHeader } from './Dialog';
import { useInitializeStateWithDefault, useLabelAndValue } from '../utils/hooks';
import { Category } from '../utils/category';
import SelectField, { SelectFieldRef } from './SelectField';
import SearchSelectField2, { SearchSelectFieldRef2 } from './SearchSelectField2';

const enabledTimeUnits: TimeUnit[] = ['DAY', 'WEEK', 'MONTH'];

const UpsertVolumeLicenseDialog = ({
	dismissPortal,
	id,
}: {
	dismissPortal: () => void;
	id?: string;
}): JSX.Element => {
	const snackBar = useSnackbar();

	const volumeLicense = useVolumeLicense(id);
	const companies = useCompanies();
	const allProducts = useAllProducts();

	// TODO: it would be better to link products to audiences via a product_vs_category table
	const selectableAudienceCategories = (useAllProducts() || [])?.reduce<Category[]>(
		(acc, { audienceCategories }) => {
			const newAcc = [...acc];
			audienceCategories.forEach((audienceCat) =>
				!acc.some((aC) => aC.id === audienceCat.id) ? newAcc.push(audienceCat) : undefined
			);
			return newAcc;
		},
		[]
	);

	const audienceTypeOptions = useLabelAndValue({
		items: selectableAudienceCategories,
		labelKey: 'title',
		valueKey: 'id',
	});

	const defaultProductIds = React.useMemo(
		() => volumeLicense?.products.map((p) => p.id),
		[volumeLicense?.products]
	);

	const [loading, setLoading] = React.useState(false);

	const [audienceCategoryId, setAudienceCategoryId] = React.useState<string | undefined>(undefined);
	const handleChangeAudienceCategory = React.useCallback((audCatId) => {
		setAudienceCategoryId(audCatId);
	}, []);

	const [productIds, setProductIds] = React.useState<string[]>([]);
	const handleChangeProducts = React.useCallback((items: { label: string; value?: string }[]) => {
		const ids = items.map(({ value }) => value) as string[];
		setProductIds(ids);
	}, []);

	const relevantProducts = React.useMemo(
		() =>
			allProducts?.filter(({ audienceCategories }) =>
				audienceCategories.some((ac) => ac.id === audienceCategoryId)
			),
		[allProducts, audienceCategoryId]
	);

	const [companyId, setCompanyId] = React.useState<string | undefined>(undefined);
	const handleChangeCompany = React.useCallback((items: { label: string; value?: string }[]) => {
		const newCompanyId = items[0]?.value;
		setCompanyId(newCompanyId);
	}, []);

	const [isLimited, setIsLimited, initializedIsLimited] = useInitializeStateWithDefault({
		defaultValue: !id ? false : volumeLicense?.isLimited,
		finishedInitializing: !id ? true : volumeLicense !== undefined,
	});

	const handleChangeLimited = React.useCallback(
		(value) => {
			setIsLimited(value);
		},
		[setIsLimited]
	);

	const [numberOfLicenses, setNumberOfLicenses, initializedNumberOfLicenses] =
		useInitializeStateWithDefault({
			defaultValue: !id ? 1 : volumeLicense?.numberOfLicenses,
			finishedInitializing: !id ? true : volumeLicense !== undefined,
		});

	const handleChangeNumberOfLicenses = React.useCallback(
		(value) => {
			setNumberOfLicenses(value);
		},
		[setNumberOfLicenses]
	);

	const [limitedUnit, setLimitedUnit, initializedLimitedUnit] = useInitializeStateWithDefault({
		defaultValue: !id ? undefined : volumeLicense?.limitedUnit,
		finishedInitializing: !id ? true : volumeLicense !== undefined,
	});

	const handleChangeTimeUnit = React.useCallback(
		(value) => {
			setLimitedUnit(value);
		},
		[setLimitedUnit]
	);

	const [limitedFactor, setLimitedFactor] = useInitializeStateWithDefault({
		defaultValue: !id ? undefined : volumeLicense?.limitedFactor,
		finishedInitializing: !id ? true : volumeLicense !== undefined,
	});

	const handleChangeTimeUnitFactor = React.useCallback(
		(value) => {
			setLimitedFactor(value);
		},
		[setLimitedFactor]
	);

	const [active, setActive] = useInitializeStateWithDefault({
		defaultValue: !id ? true : volumeLicense?.active,
		finishedInitializing: !id ? true : volumeLicense !== undefined,
	});

	const handleChangeIsActive = React.useCallback(
		(value) => {
			setActive(value);
		},
		[setActive]
	);

	const searchSelectFieldRef = React.useRef<SearchSelectFieldRef2>(null);
	const productSearchSelectFieldRef = React.useRef<SearchSelectFieldRef2>(null);
	const audienceCategorySelectFieldRef = React.useRef<SelectFieldRef>(null);
	const timePeriodSelectFormRef = React.useRef<TimePeriodSelectFormSimpleRef>(null);
	const integerSelectFieldRef = React.useRef<IntegerSelectFieldRef>(null);
	const booleanSelectFieldRef = React.useRef<BooleanSelectFieldRef>(null);

	const handleValidate = React.useCallback(() => {
		const invalid = [
			searchSelectFieldRef,
			productSearchSelectFieldRef,
			timePeriodSelectFormRef,
			integerSelectFieldRef,
			booleanSelectFieldRef,
			audienceCategorySelectFieldRef,
		]
			.map((r) => r.current?.validate())
			.some(Boolean);
		return invalid;
	}, []);

	const handleCreateVolumeLicense = React.useCallback(async () => {
		const invalid = handleValidate!();
		if (invalid) return;
		setLoading(true);
		const { success, error } = await createVolumeLicense({
			companyId: companyId!,
			audienceCategoryId: audienceCategoryId!,
			isLimited: isLimited!,
			numberOfLicenses: numberOfLicenses!,
			limitedUnit: limitedUnit!,
			limitedFactor,
			productIds: productIds!,
		});
		setLoading(false);
		if (success) {
			snackBar({ success: 'Volumenlizenz erfolgreich erstellt' });
			dismissPortal();
		} else {
			snackBar({ error });
		}
	}, [
		handleValidate,
		companyId,
		audienceCategoryId,
		isLimited,
		numberOfLicenses,
		limitedUnit,
		limitedFactor,
		productIds,
		snackBar,
		dismissPortal,
	]);

	const handleUpdateVolumeLicense = React.useCallback(async () => {
		setLoading(true);
		const { error } = await updateVolumeLicense({
			id: id!,
			numberOfLicenses: numberOfLicenses,
			active: active,
		});
		setLoading(false);
		if (error) {
			snackBar({ error });
		} else {
			snackBar({ success: 'Volumenlizenz erfolgreich aktualisiert' });
			dismissPortal();
		}
	}, [dismissPortal, id, active, numberOfLicenses, snackBar]);

	const maybeConfirmUpdateVolumeLicense = React.useCallback(() => {
		if (id && volumeLicense?.active && !active) {
			Dialog.render({
				title: 'Volumenlizenz deaktivieren',
				description:
					'Die Deaktivierung der Volumenlizenz führt dazu, dass die verknüpften Nutzer die verknüpften Produkte nicht mehr nutzen können. Bist du sicher, dass du die Volumenlizenz deaktivieren möchtest?',
				buttons: [
					{
						id: 'confirm',
						label: 'Deaktivieren',
						onClick: handleUpdateVolumeLicense,
					},
					{
						id: 'cancel',
						label: 'Abbrechen',
						variant: 'mainButton',
					},
				],
			});
		} else {
			handleUpdateVolumeLicense();
		}
	}, [handleUpdateVolumeLicense, id, active, volumeLicense?.active]);

	return (
		<DialogWrapper p="2rem">
			{volumeLicense === undefined ||
			!initializedIsLimited ||
			!initializedNumberOfLicenses ||
			!initializedLimitedUnit ||
			!relevantProducts ||
			!companies ? (
				<CircularProgress />
			) : (
				<>
					<DialogHeader title={id ? 'Volumenlizenz editieren' : 'Volumenlizenz erstellen'} />
					{!id ? (
						<SearchSelectField2
							label="Unternehmen"
							options={companies || undefined}
							onChange={handleChangeCompany}
							m="2rem 0 0 0"
							width="100%"
							ref={searchSelectFieldRef}
							defaultValues={volumeLicense?.company.id}
							labelKey="title"
							valueKey="id"
						/>
					) : null}
					{!id ? (
						<SelectField
							label="Nutzer-Typ"
							onChange={handleChangeAudienceCategory}
							margin="1rem 0 0 0"
							fullWidth
							items={audienceTypeOptions}
							ref={audienceCategorySelectFieldRef}
						/>
					) : null}
					{!id && audienceCategoryId && relevantProducts?.length ? (
						<SearchSelectField2
							label="Produkte"
							options={relevantProducts}
							onChange={handleChangeProducts}
							m="1rem 0 0 0"
							width="100%"
							ref={productSearchSelectFieldRef}
							multiple
							defaultValues={defaultProductIds}
							labelKey="title"
							valueKey="id"
						/>
					) : null}
					{!id ? (
						<BooleanSelectField
							onChange={handleChangeLimited}
							defaultValue={isLimited}
							width="100%"
							m="1rem 0 0 0"
							label="Limitiert"
							ref={booleanSelectFieldRef}
						/>
					) : null}
					{!id && isLimited ? (
						<TimePeriodSelectForm
							timeUnitLabel="Gültigkeit Einheit"
							timeUnitFactorLabel="Gültigkeit Faktor"
							m="1rem 0 0 0"
							width="100%"
							enabledTimeUnits={enabledTimeUnits}
							onChangeTimeUnit={handleChangeTimeUnit}
							onChangeTimeUnitFactor={handleChangeTimeUnitFactor}
							ref={timePeriodSelectFormRef}
							defaultTimeUnit={limitedUnit}
							defaultTimeUnitFactor={limitedFactor}
						/>
					) : null}

					<IntegerTextField
						onChange={handleChangeNumberOfLicenses}
						defaultValue={numberOfLicenses}
						width="100%"
						m="1rem 0 0 0"
						label="Anzahl Lizenzen"
						ref={integerSelectFieldRef}
						min={1}
					/>
					{id ? (
						<BooleanSelectField
							onChange={handleChangeIsActive}
							defaultValue={active}
							width="100%"
							m="1rem 0 0 0"
							label="Aktiv"
							ref={booleanSelectFieldRef}
						/>
					) : null}

					<DialogButtonRow>
						<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
							Abbrechen
						</Button>

						<Button
							variant="mainButton"
							onClick={!id ? handleCreateVolumeLicense : maybeConfirmUpdateVolumeLicense}
							loading={loading}
						>
							{id ? 'Speichern' : 'Hinzufügen'}
						</Button>
					</DialogButtonRow>
				</>
			)}
		</DialogWrapper>
	);
};

export default UpsertVolumeLicenseDialog;
