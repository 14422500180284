import React from 'react';
import { useTheme } from 'styled-components';
import { TextField, Grid } from '@mui/material';
import { useSnackbar } from '../utils/snackBar';

import useTextFieldController from '../utils/useTextFieldController';
import Button from './Button';
import GridContainer from './GridContainer';
import { type SelectFieldRef } from './SelectField';
import FederalStateSelect, { type FederalState } from './FederalStateSelect';
import upsertAddress from '../mutations/upsertAddress';

const defaultGridItemProps = {
	xs: 12,
	sm: 6,
	item: true,
};

const horizontalSpacing = { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 };
const verticalSpacing = { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 };

const AddressForm = ({
	id,
	defaultFederalState,
	defaultCity,
	defaultZipCode,
	defaultStreet,
	defaultHouseNumber,
	gridItemProps,
	onSuccess,
}: {
	id?: string;
	defaultFederalState?: FederalState;
	defaultCity?: string;
	defaultZipCode?: string;
	defaultStreet?: string;
	defaultHouseNumber?: string;
	isStandardAddress?: boolean;
	gridItemProps?: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number };
	onSuccess?: () => void;
}): JSX.Element => {
	const theme = useTheme();
	const currentGridItemProps = React.useMemo(
		() => gridItemProps || defaultGridItemProps,
		[gridItemProps]
	);
	const snackBar = useSnackbar();

	const {
		newStateValue: city,
		textInputProps: cityFieldProps,
		validate: validateCity,
		changed: cityChanged,
	} = useTextFieldController({
		defaultValue: defaultCity,
		inputLabel: 'Stadt',
		stateKey: 'city',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const {
		newStateValue: zipCode,
		textInputProps: zipCodeFieldProps,
		validate: validatezipCode,
		changed: zipCodeChanged,
	} = useTextFieldController({
		defaultValue: defaultZipCode,
		inputLabel: 'Postleitzahl',
		stateKey: 'zipCode',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const {
		newStateValue: street,
		textInputProps: streetFieldProps,
		validate: validateStreet,
		changed: streetChanged,
	} = useTextFieldController({
		defaultValue: defaultStreet,
		inputLabel: 'Straße',
		stateKey: 'street',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const {
		newStateValue: houseNumber,
		textInputProps: houseNumberFieldProps,
		validate: validateHouseNumber,
		changed: houseNumberChanged,
	} = useTextFieldController({
		defaultValue: defaultHouseNumber,
		inputLabel: 'Hausnummer',
		stateKey: 'houseNumber',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const [loading, setLoading] = React.useState<boolean>(false);

	const [federalState, setFederalState] = React.useState<null | FederalState>(
		defaultFederalState || null
	);

	const handleFederalStateChange = React.useCallback((newValue) => {
		setFederalState(newValue);
	}, []);

	const federalStateSelectFieldRef = React.useRef<SelectFieldRef>(null);

	const handleValidate = React.useCallback(() => {
		const errors = [
			federalStateSelectFieldRef?.current?.validate(),
			validateCity!(),
			validatezipCode!(),
			validateStreet!(),
			validateHouseNumber!(),
		];
		const invalid = errors.some(Boolean);
		return invalid;
	}, [validateCity, validateHouseNumber, validateStreet, validatezipCode]);

	const changed = React.useMemo(
		() =>
			[
				cityChanged,
				zipCodeChanged,
				streetChanged,
				houseNumberChanged,
				defaultFederalState && defaultFederalState !== federalState,
			].some(Boolean),
		[
			cityChanged,
			defaultFederalState,
			federalState,
			houseNumberChanged,
			streetChanged,
			zipCodeChanged,
		]
	);

	const handleSave = React.useCallback(async () => {
		const invalid = handleValidate();
		if (!invalid) {
			if (!changed) {
				onSuccess?.();
			} else {
				setLoading(true);
				const { success } = await upsertAddress({
					id: id,
					federalState: federalState!,
					city: city!,
					zipCode: zipCode!,
					street: street!,
					houseNumber: houseNumber!,
					isStandardAddress: true,
				});
				setLoading(false);
				if (success) {
					snackBar({ success: 'Gespeichert' });

					onSuccess?.();
				} else {
					snackBar({ error: 'UNEXPECTED_ERROR' });
				}
			}
		}
	}, [
		changed,
		city,
		federalState,
		handleValidate,
		houseNumber,
		id,
		onSuccess,
		snackBar,
		street,
		zipCode,
	]);

	const textFieldsProps = React.useMemo(
		() => [cityFieldProps, zipCodeFieldProps, streetFieldProps, houseNumberFieldProps],
		[cityFieldProps, houseNumberFieldProps, streetFieldProps, zipCodeFieldProps]
	);

	const inputProps = React.useMemo(
		() => ({
			style: {
				backgroundColor: theme.palette.common.white,
			},
		}),
		[theme.palette.common.white]
	);

	return (
		<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
			<Grid {...currentGridItemProps}>
				<FederalStateSelect
					defaultValue={defaultFederalState}
					backgroundColor={theme.palette.common.white}
					fullWidth
					onChange={handleFederalStateChange}
					ref={federalStateSelectFieldRef}
				/>
			</Grid>
			<>
				{textFieldsProps.map((textFieldProps) => (
					<Grid key={textFieldProps.label} {...currentGridItemProps}>
						<TextField fullWidth variant="filled" {...textFieldProps} InputProps={inputProps} />
					</Grid>
				))}
			</>
			<Grid item xs={12}>
				<Button fullWidth onClick={handleSave} variant="mainButton" loading={loading}>
					{changed ? 'Speichern' : 'Weiter'}
				</Button>
			</Grid>
		</GridContainer>
	);
};

export default AddressForm;
