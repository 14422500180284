import React from 'react';
import { useQuery } from '@apollo/client';

import { CURRENT_USER_QUERY } from './user';

export type FederalState =
	| 'Baden-Württemberg'
	| 'Bayern'
	| 'Berlin'
	| 'Brandenburg'
	| 'Bremen'
	| 'Hamburg'
	| 'Hessen'
	| 'Mecklenburg-Vorpommern'
	| 'Niedersachsen'
	| 'Nordrhein-Westfalen'
	| 'Rheinland-Pfalz'
	| 'Saarland'
	| 'Sachsen-Anhalt'
	| 'Sachsen'
	| 'Schleswig-Holstein'
	| 'Thüringen';

export type Address = {
	id: string;
	country: string;
	federalState: FederalState;
	city: string;
	zipCode: string;
	street: string;
	houseNumber: string;
	isStandardAddress?: boolean;
};

export const useAddresses = (): undefined | null | Address[] => {
	const { data } = useQuery(CURRENT_USER_QUERY);
	return React.useMemo(() => data?.currentUser?.addresses, [data?.currentUser?.addresses]);
};

export const useStandardAddress = (): undefined | null | Address => {
	const { data } = useQuery(CURRENT_USER_QUERY);
	return React.useMemo(() => {
		const addresses = data?.currentUser?.addresses;
		return !addresses?.length
			? null
			: addresses.find(({ isStandardAddress }) => Boolean(isStandardAddress)) || null;
	}, [data?.currentUser?.addresses]);
};
