export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'DEVELOPMENT';
export const DEVELOPMENT = ENVIRONMENT === 'DEVELOPMENT';

export const API_BASE_URL = DEVELOPMENT
	? 'http://localhost:5001'
	: ENVIRONMENT === 'PRODUCTION'
	? 'https://apilive.bildungsfreaks.de'
	: ENVIRONMENT === 'STAGING'
	? 'https://apistage.bildungsfreaks.de'
	: 'wrong';

console.log({
	ENVIRONMENT,
	DEVELOPMENT,
	API_BASE_URL,
});

export const API_BASE_URL_GQL = `${API_BASE_URL}/graphql`;
export const API_BASE_URL_SSO = `${API_BASE_URL}/sso`;

export const UPLOAD_CATEGORY_IMAGE_ENDPOINT = `${API_BASE_URL}/upload-category-image`;

export const COURSE_MATERIAL_CLOUDFRONT_URL = `${API_BASE_URL}/courses`;

export const CONTACT_EMAIL = 'kontakt@bildungsfreaks.de';

export const PAYPAL_CLIENT_ID =
	'AeGLpUTE3Txp16jXREo3PldIM-EgzMV7p_ibQTH1Z7T-gpZ0b7xo9MPjp9YPsCUQH8DvRNaRir8ytWbO';

//Make sure the text for this version is present in TermsOfServiceView.tsx
export const LATEST_TERMS_VERSION = 2; // This must be a integer (1,2,3...)
