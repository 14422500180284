import React from 'react';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from './IconButton';
import { usePxPerRemFactor, useWindowDimensions } from '../utils/hooks';
import Button from './Button';
import { useNavigate } from '../utils/hooks';
import Txt from './Txt';
import { useCurrentUser } from '../utils/user';
import { IconName } from '../utils/iconNameDict';

const USUAL_WIDTH_REM = 20;
const OFFEET_REM = 2;

const ELEMENT_PADDING = '0.75rem 1rem';

type ButtonElement = {
	label: string;
	navigateTo?: string;
	onClick?: (argument?: string) => void;
	iconName?: IconName;
	children?: ButtonElement[];
};

const MobileNavigation = ({
	m,
	p,
	title,
	navButtons,
	actionButtons,
	activeButtonLabel,
}: {
	m?: string;
	p?: string;
	title?: string;
	navButtons: ButtonElement[];
	actionButtons?: ButtonElement[];
	activeButtonLabel?: string;
}) => {
	const currentUser = useCurrentUser();
	const theme = useTheme();
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);

	const handleToggle = React.useCallback(() => {
		setOpen((before) => !before);
	}, []);

	const handleOpen = React.useCallback(() => {
		console.log('open');
	}, []);

	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	const handleClick = React.useCallback(
		(label: string) => {
			const flatNavButtons = navButtons.flatMap((nE) => [nE, ...(nE.children ? nE.children : [])]);
			const button = [...flatNavButtons, ...(actionButtons ? actionButtons : [])].find(
				(nE) => nE.label === label
			);
			if (button) {
				if (button.navigateTo) {
					navigate(button.navigateTo);
				} else if (button.onClick) {
					button.onClick();
				}
				handleClose();
			}
		},
		[actionButtons, handleClose, navButtons, navigate]
	);

	const { width: windowWidth } = useWindowDimensions();
	const pxPerRemFactor = usePxPerRemFactor();
	const width = Math.min(
		windowWidth - OFFEET_REM * pxPerRemFactor,
		USUAL_WIDTH_REM * pxPerRemFactor
	);

	const defaultTitle = `Hallo ${currentUser?.firstName || ''}`;

	return (
		<>
			<IconButton iconName="Menu" onClick={handleToggle} m={m} p={p} />
			<SwipeableDrawer open={open} anchor="left" onOpen={handleOpen} onClose={handleClose}>
				<Box
					width={`${width}px`}
					height="100vh"
					display="flex"
					flexDirection="column"
					overflow="scroll"
					position="relative"
				>
					<Box
						p={ELEMENT_PADDING}
						borderBottom={`3px solid ${theme.palette.common.black}`}
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<Txt fontWeight="bold">{title || defaultTitle}</Txt>
						<IconButton iconName="CloseOutlined" onClick={handleClose} />
					</Box>

					{navButtons.map(({ label, children, iconName }) => (
						<Box
							key={label}
							borderBottom={`1px solid ${theme.customColors.lightGrey}`}
							p={ELEMENT_PADDING}
							width="100%"
							overflow="hidden"
						>
							<Button
								key={label}
								onClick={handleClick}
								id={label}
								variant={children ? 'boldText' : 'text'}
								m={children ? '0 0 1rem 0' : '0'}
								startIconName={iconName}
							>
								{label}
							</Button>
							{children?.map(({ label: subButtonLabel }) => (
								<Button
									m="0 0 0.5rem 1.5rem"
									key={subButtonLabel}
									onClick={handleClick}
									id={subButtonLabel}
									variant={activeButtonLabel === label ? 'blueText' : 'text'}
								>
									{subButtonLabel}
								</Button>
							))}
						</Box>
					))}
					{actionButtons ? (
						<Box flex="1" borderBottom={`3px solid ${theme.palette.common.black}`} />
					) : null}
					{actionButtons?.map(({ label }) => (
						<Box key={label} p={ELEMENT_PADDING} width="100%" overflow="hidden">
							<Button key={label} onClick={handleClick} id={label}>
								{label}
							</Button>
						</Box>
					))}
				</Box>
			</SwipeableDrawer>
		</>
	);
};

export default MobileNavigation;
