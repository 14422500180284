import React from 'react';

import { BoxProps } from '@mui/system';
import { CardActionArea as MuiCardActionArea, Box } from '@mui/material';

const CardActionArea = ({
	id,
	onClick,
	children,
	...rest
}: { id?: string; onClick: (idvalue?: string) => void } & BoxProps): JSX.Element => {
	// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
	const handleClick = () => {
		onClick(id);
	};
	return (
		<Box display="flex" {...rest} onClick={handleClick}>
			<MuiCardActionArea>{children}</MuiCardActionArea>
		</Box>
	);
};

export default CardActionArea;
