import React from 'react';
import { AppBar as MuiAppBar, Box } from '@mui/material';

import BottomAppBar from './BottomAppBar';
import TopAppBar from './TopAppBar';
import AccessPhraseUserAppBar from './AccessPhraseUserAppBar';
import { useLocation } from 'react-router-dom';
import { useDivDimensions } from '../utils/hooks';
import { appBarHeightVar } from '../utils/localState';

const AppBar = ({ isAccessPhraseUser }: { isAccessPhraseUser?: boolean }) => {
	const location = useLocation();
	const isPlayerRoute = location.pathname.includes('/player/');

	const ref = React.useRef<HTMLDivElement>(null);

	const deps = React.useMemo(() => [location.pathname], [location.pathname]);
	const { height } = useDivDimensions(ref, 'resize', deps);
	appBarHeightVar(height);

	return isPlayerRoute ? null : (
		<>
			<Box>
				<MuiAppBar position="fixed" ref={ref}>
					{isAccessPhraseUser ? <AccessPhraseUserAppBar /> : <TopAppBar />}
					{isAccessPhraseUser ? null : <BottomAppBar />}
				</MuiAppBar>
				<Box width="100%" height={height} />
			</Box>
		</>
	);
};

export default React.memo(AppBar);
