import React from 'react';
import { useParams } from 'react-router-dom';

import Txt from '../components/Txt';
import Button from '../components/Button';
import SimpleTable3 from '../components/SimpleTable3';
import ContentWrapper from '../components/ContentWrapper';
import Dialog from '../components/Dialog';
import { useVolumeLicense } from '../utils/volumeLicense';
import InviteToVolumeLicenseDialog from '../components/InviteToVolumeLicenseDialog';

const inactiveKeys = ['id', 'companyId'];

const VolumeLicenseDetailView = (): JSX.Element | null => {
	const { id } = useParams();
	const volumeLicenses = useVolumeLicense(id);
	const volumeLicenseUsers = volumeLicenses?.users;

	const handleOpenInviteToVolumeLicenseDialog = React.useCallback(() => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<InviteToVolumeLicenseDialog dismissPortal={dismissPortal} id={id} />
			),
			isLocked: true,
		});
	}, [id]);

	return !volumeLicenseUsers ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper p="2rem">
			<Txt m="0 0 2rem 0" variant="h6">
				Volumenlizenzen Nutzer
			</Txt>
			{!volumeLicenseUsers.length ? null : (
				<SimpleTable3 objects={volumeLicenseUsers} inactiveKeys={inactiveKeys} />
			)}
			<Button m="1rem 0 0 0" variant="mainButton" onClick={handleOpenInviteToVolumeLicenseDialog}>
				Hinzufügen
			</Button>
		</ContentWrapper>
	);
};

export default VolumeLicenseDetailView;
