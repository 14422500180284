import React from 'react';
import { Box, CardActionArea } from '@mui/material';
import { useNavigate } from '../utils/hooks';
import styled from 'styled-components';

import ImageBox from './ImageBox';
import Txt from './Txt';

const Wrapper = styled(Box)`
	min-width: ${(p) => p.$width}rem;
	width: ${(p) => p.$width}rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border: 1px solid ${(p) => p.theme.customColors.lightGrey};
	overflow: hidden;
	height: ${(p) => p.$height}rem;
	margin-right: ${(p) => p.$marginRight}rem;
`;

const StyledRow = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0 1rem;
	background-color: ${(p) => p.theme.customColors.orange};
`;

const AlternativeTile = ({
	id,
	imageUrl,
	bannerInfo,
	onClick,
	navigateTo,
	widthRemFactor,
	marginRightRemFactor,
	heightRemFactor,
}: {
	id: string;
	imageUrl?: string;
	bannerInfo?: string;
	onClick?: (value?: string) => void;
	navigateTo?: string;
	widthRemFactor?: number;
	marginRightRemFactor?: number;
	heightRemFactor?: number;
}) => {
	const navigate = useNavigate();
	const handleClick = React.useCallback(() => {
		if (onClick) {
			onClick(id);
		}
		if (navigateTo) {
			navigate(navigateTo);
		}
	}, [id, navigate, navigateTo, onClick]);

	return (
		<Wrapper $width={widthRemFactor} $height={heightRemFactor} $marginRight={marginRightRemFactor}>
			<CardActionArea onClick={handleClick}>
				<StyledRow>
					<Txt variant="caption" numberOfLines={1}>
						{bannerInfo}
					</Txt>
				</StyledRow>

				<ImageBox width="100%" height={`${heightRemFactor}rem`} src={imageUrl} pointer />
			</CardActionArea>
		</Wrapper>
	);
};

export default React.memo(AlternativeTile);
