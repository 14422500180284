import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { Category } from './category';
import { useStringArray } from './helpers';
import { CurrentUser, useCurrentUser } from './user';
import { useNavigate } from './hooks';

export type ContentFormat = 'WEBSITE' | 'PDF' | 'WORD' | 'VIDEO';

export type ContentAccessibilityType =
	| 'REGISTER_FREE'
	| 'FREE_MEMBERSHIP'
	| 'PAID_MEMBERSHIP'
	| 'PRODUCT';

export type ContentInfos = {
	id: string;
	title: string;
	imageUrl?: string;
	author?: string;
	demo?: boolean;
	categories: Category[];
	completion?: number;
	lastProgressedAt?: string;
	active?: boolean;
	serieOrder?: { type: string; order: number };
	priority?: number;
	contentFormat: ContentFormat;
	hasCertificate?: boolean;
	isRegisterFree?: boolean;
	accessibilities: {
		type: 'REGISTER_FREE' | 'FREE_MEMBERSHIP' | 'PAID_MEMBERSHIP' | 'PRODUCT';
		ids?: string[];
	}[];
	createdAt: string;
	createdAtLabel: string;
	updatedAtLabel: string;
	popularity?: number;
};

export type ContentDetails = ContentInfos & {
	id: string;
	sku: string;
	version: number;
	description?: string;
	shortDescription?: string;
	duration?: number;
	updatedAt: string;
	source?: string;
	path: string;
	cooperations?: { label: string; link: string }[];
	licenseInfos?: { label: string; link: string }[];
	learningGoals?: string[];
	why?: string;
	who?: string[];
	what?: string[];
	techRequirements?: string[];
	requirements?: string[];
	structure?: { chapter: string; lessons: string[] }[];
};

export const ContentInfosFragment = gql`
	fragment ContentInfosFragment on Content {
		id
		title
		imageUrl
		author
		demo
		categories {
			id
			groupName
			type
			title
		}
		active
		serieOrder {
			type
			order
		}
		completion
		lastProgressedAt
		priority
		contentFormat
		hasCertificate
		isRegisterFree
		accessibilities {
			type
			ids
		}
		createdAt
		createdAtLabel
		popularity
		structure {
			chapter
			lessons
		}
	}
`;

export const ContentDetailsFragment = gql`
	fragment ContentDetailsFragment on Content {
		...ContentInfosFragment
		sku
		version
		description
		shortDescription
		duration
		updatedAt
		updatedAtLabel
		source
		path
		cooperations {
			label
			link
		}
		licenseInfos {
			label
			link
		}
		learningGoals
		why
		who
		what
		techRequirements
		requirements
	}
	${ContentInfosFragment}
`;

export const CONTENT_INFO_QUERY = gql`
	query Content($id: ID!) {
		content(id: $id) {
			...ContentInfosFragment
		}
	}
	${ContentInfosFragment}
`;

export const CONTENT_DETAILS_QUERY = gql`
	query {
		contents {
			...ContentDetailsFragment
		}
	}
	${ContentDetailsFragment}
`;

export const useAllContentDetails = (): ContentDetails[] | undefined => {
	const { data } = useQuery(CONTENT_DETAILS_QUERY);
	return data?.contents;
};

export const useContentInfo = (id?: string): ContentInfos | undefined => {
	const { data } = useQuery(
		gql`
			query Content($id: ID!) {
				content(id: $id) {
					...ContentInfosFragment
				}
			}
			${ContentInfosFragment}
		`,
		{
			variables: {
				id,
			},
			skip: !id,
		}
	);
	return React.useMemo(() => data?.content, [data?.content]);
};

export const useContentDetails = (id?: string): ContentDetails | undefined => {
	const { data } = useQuery(
		gql`
			query Content($id: ID!) {
				content(id: $id) {
					...ContentDetailsFragment
				}
			}
			${ContentDetailsFragment}
		`,
		{
			variables: {
				id,
			},
			skip: !id,
		}
	);
	return React.useMemo(() => data?.content, [data?.content]);
};

const getCategoryTitles = (categories?: { title: string }[]): string => {
	return categories ? categories.map(({ title }) => title).join(', ') : '';
};

export type InfoBoxContent = {
	label: string;
	value?: string | number;
	values?: { label: string; value: string; onClick?: () => void }[];
	hide?: boolean;
}[];

export const useContentInfoBoxContent = (content?: ContentDetails): InfoBoxContent | undefined => {
	const navigate = useNavigate();

	const relatedSerie = React.useMemo(
		() => content?.categories?.find((cat) => cat.groupName === 'SERIE'),
		[content]
	);

	const result = React.useMemo(
		() =>
			content
				? ([
						{
							label: 'Kategorie',
							value:
								getCategoryTitles(
									content?.categories.filter(({ groupName }) => groupName === 'EDUCATION_FORMAT')
								) || '',
						},
						{
							label: 'Zielgruppe',
							value:
								getCategoryTitles(
									content?.categories.filter(({ groupName }) => groupName === 'AUDIENCE')
								) || '',
						},
						{ label: 'Erstellt von', value: content?.author || '' },
						{
							label: 'Kursdauer',
							value: content?.duration ? content.duration + ' min' : '',
							hide: content.contentFormat !== 'WEBSITE',
						},
						{ label: 'Aktualisiert', value: content.updatedAtLabel },
						content.licenseInfos
							? {
									label: 'Lizenzen',
									values: content.licenseInfos.map(({ label, link }) => ({
										label,
										value: link,
										onClick: () => window.open(link, '_blank'),
									})),
							  }
							: null,
						relatedSerie
							? {
									label: 'Reihe',
									values: [
										{
											label: relatedSerie.title,
											onClick: () => navigate(`/category/${relatedSerie.id}`),
										},
									],
							  }
							: null,
				  ].filter(Boolean) as InfoBoxContent)
				: undefined,
		[content, navigate, relatedSerie]
	);

	return result;
};

export type ContentFilter =
	| undefined
	| {
			contents?: ContentInfos[];
			userHasAccess?: boolean;
			userHasProgress?: boolean;
			userHasCompleted?: boolean;
			userAudiences?: string | string[];
			categoryTypeFilters?: string | string[];
			categoryTypeFiltersEvery?: string | string[];
			contentFormats?:
				| 'WEBSITE'
				| 'PDF'
				| 'WORD'
				| 'VIDEO'
				| ('WEBSITE' | 'PDF' | 'WORD' | 'VIDEO')[];
			contentIsDemo?: boolean;
			contentIsActive?: boolean;
			contentIsContainedInProducts?: boolean;
			contentIsRegisterFree?: boolean;
			contentHasPriority?: boolean;
			sortAfterPriority?: boolean;
			contentIsContainedInProductIds?: string | string[];
			contentIsContainedInMembershipIds?: string | string[];
	  };

export const useFilteredContents = (
	contentFilter: ContentFilter,
	contents?: ContentDetails[] | ContentInfos[]
): ContentInfos[] | undefined => {
	const {
		userHasAccess,
		userHasProgress,
		userHasCompleted,
		userAudiences,
		categoryTypeFilters,
		categoryTypeFiltersEvery,
		contentFormats,
		contentIsDemo,
		contentIsActive,
		contentIsContainedInProducts,
		contentIsRegisterFree,
		contentHasPriority,
		sortAfterPriority,
		contentIsContainedInProductIds,
		contentIsContainedInMembershipIds,
	} = contentFilter || {};

	const userAudiencesARR = useStringArray(userAudiences);
	const categoryTypesFiltersARR = useStringArray(categoryTypeFilters);
	const categoryTypesFiltersEveryARR = useStringArray(categoryTypeFiltersEvery);
	const currentUser = useCurrentUser();
	const accessibleContentsInfo = currentUser?.accessibleContentsInfo;

	return React.useMemo(() => {
		const filtered = contents?.filter((content) => {
			const contentCompliesUserHasAccess =
				userHasAccess === undefined
					? true
					: userHasAccess === Boolean(accessibleContentsInfo?.some(({ id }) => id === content.id));

			const contentCompliesUserHasProgress =
				userHasProgress === undefined ? true : Boolean(content.completion) === userHasProgress;

			const contentCompliesCompletion =
				userHasCompleted === undefined
					? true
					: userHasCompleted
					? content.completion && content.completion === 1
					: content.completion && content.completion < 1;

			const contentCompliesUserAudiences =
				userAudiencesARR.length === 0
					? true
					: content.categories.some((category) => userAudiencesARR.includes(category.type));

			const contentCompliesCategoryTypeFilters =
				categoryTypesFiltersARR.length === 0
					? true
					: content.categories.some((category) => categoryTypesFiltersARR.includes(category.type));

			const contentCompliesCategoryTypeFiltersEvery =
				categoryTypesFiltersEveryARR.length === 0
					? true
					: categoryTypesFiltersEveryARR.every((categoryType) =>
							content.categories.some((c) => c.type === categoryType)
					  );

			const contentCompliesContentFormat = !contentFormats
				? true
				: contentFormats.includes(content.contentFormat);

			const contentCompliesDemo =
				contentIsDemo === undefined ? true : contentIsDemo === Boolean(content.demo);

			const contentCompliesActive =
				contentIsActive === undefined ? true : contentIsActive === Boolean(content.active);

			const contentCompliesContainedInProduct =
				contentIsContainedInProducts === undefined
					? true
					: contentIsContainedInProducts ===
					  Boolean(content.accessibilities.find(({ type }) => type === 'PRODUCT')?.ids?.length);

			const contentCompliesRegisterFree =
				contentIsRegisterFree === undefined
					? true
					: contentIsRegisterFree === Boolean(content.isRegisterFree);

			const contentCompliesPriority =
				contentHasPriority === undefined ? true : contentHasPriority === Boolean(content.priority);

			const contentCompliesContainedInProductIds =
				contentIsContainedInProductIds === undefined || contentIsContainedInProductIds.length === 0
					? true
					: content.accessibilities
							.find(({ type }) => type === 'PRODUCT')
							?.ids?.some((productId) => contentIsContainedInProductIds.includes(productId));

			const contentCompliesContainedInMembershipIds =
				contentIsContainedInMembershipIds === undefined ||
				contentIsContainedInMembershipIds.length === 0
					? true
					: content.accessibilities
							.filter(({ type }) => type === 'FREE_MEMBERSHIP' || type === 'PAID_MEMBERSHIP')
							.flatMap(({ ids }) => ids)
							.filter(Boolean)
							?.some((membershipIds) => contentIsContainedInMembershipIds.includes(membershipIds!));

			return (
				contentCompliesUserHasAccess &&
				contentCompliesUserHasProgress &&
				contentCompliesCompletion &&
				contentCompliesUserAudiences &&
				contentCompliesCategoryTypeFilters &&
				contentCompliesContentFormat &&
				contentCompliesDemo &&
				contentCompliesActive &&
				contentCompliesContainedInProduct &&
				contentCompliesRegisterFree &&
				contentCompliesPriority &&
				contentCompliesCategoryTypeFiltersEvery &&
				contentCompliesContainedInProductIds &&
				contentCompliesContainedInMembershipIds
			);
		});

		return sortAfterPriority
			? filtered?.sort((a, b) => (a.priority! > b.priority! ? -1 : 1))
			: filtered;
	}, [
		contents,
		sortAfterPriority,
		userHasAccess,
		accessibleContentsInfo,
		userHasProgress,
		userHasCompleted,
		userAudiencesARR,
		categoryTypesFiltersARR,
		categoryTypesFiltersEveryARR,
		contentFormats,
		contentIsDemo,
		contentIsActive,
		contentIsContainedInProducts,
		contentIsRegisterFree,
		contentHasPriority,
		contentIsContainedInProductIds,
		contentIsContainedInMembershipIds,
	]);
};

export const useRecommendedApiContents = (userType?: CurrentUser['audienceType']) => {
	const recommendedContents = useApiFilteredContents({
		categoryTypeFilters: userType,
		userHasProgress: false,
		contentIsActive: true,
		contentIsContainedInProducts: true,
		sortAfterPriority: true,
		contentIsDemo: false,
		contentHasPriority: true,
	});
	return recommendedContents;
};

export const useApiFilteredContents = ({
	skip,
	userHasAccess,
	userHasProgress,
	userHasCompleted,
	contentIsDemo,
	contentIsActive,
	contentIsRegisterFree,
	contentHasPriority,
	userAudiences,
	categoryTypeFilters,
	categoryTypeFiltersEvery,
	sortAfterPriority,
	contentFormats,
	contentIsContainedInProducts,
	contentIsContainedInProductIds,
	userHasFavored,
	excludeContentIds,
}: {
	skip?: boolean;
	userHasAccess?: boolean;
	userHasProgress?: boolean;
	userHasCompleted?: boolean;
	contentIsDemo?: boolean;
	contentIsActive?: boolean;
	contentIsRegisterFree?: boolean;
	contentHasPriority?: boolean;
	userAudiences?: string | string[];
	categoryTypeFilters?: string | string[];
	categoryTypeFiltersEvery?: string | string[];
	sortAfterPriority?: boolean;
	contentFormats?: ContentDetails['contentFormat'][];
	contentIsContainedInProducts?: boolean;
	contentIsContainedInProductIds?: string[];
	userHasFavored?: boolean;
	excludeContentIds?: string[];
}): ContentInfos[] | undefined => {
	const userAudiencesArr = useStringArray(userAudiences);
	const categoryTypesFiltersArr = useStringArray(categoryTypeFilters);
	const categoryTypesFiltersEveryArr = useStringArray(categoryTypeFiltersEvery);

	const { data } = useQuery(
		gql`
			query FilteredContents(
				$userHasAccess: Boolean
				$userHasProgress: Boolean
				$userHasCompleted: Boolean
				$contentIsDemo: Boolean
				$contentIsActive: Boolean
				$contentIsRegisterFree: Boolean
				$contentHasPriority: Boolean
				$userAudiences: [String!]
				$categoryTypeFilters: [String!]
				$categoryTypeFiltersEvery: [String!]
				$sortAfterPriority: Boolean
				$contentFormats: [ContentFormat!]
				$contentIsContainedInProducts: Boolean
				$contentIsContainedInProductIds: [String!]
				$userHasFavored: Boolean
			) {
				filteredContents(
					userHasAccess: $userHasAccess
					userHasProgress: $userHasProgress
					userHasCompleted: $userHasCompleted
					contentIsDemo: $contentIsDemo
					contentIsActive: $contentIsActive
					contentIsRegisterFree: $contentIsRegisterFree
					contentHasPriority: $contentHasPriority
					userAudiences: $userAudiences
					categoryTypeFilters: $categoryTypeFilters
					categoryTypeFiltersEvery: $categoryTypeFiltersEvery
					sortAfterPriority: $sortAfterPriority
					contentFormats: $contentFormats
					contentIsContainedInProducts: $contentIsContainedInProducts
					contentIsContainedInProductIds: $contentIsContainedInProductIds
					userHasFavored: $userHasFavored
				) {
					...ContentInfosFragment
				}
			}
			${ContentInfosFragment}
		`,
		{
			variables: {
				userHasAccess,
				userHasProgress,
				userHasCompleted,
				contentIsDemo,
				contentIsActive,
				contentIsRegisterFree,
				contentHasPriority,
				userAudiences: userAudiencesArr,
				categoryTypeFilters: categoryTypesFiltersArr,
				categoryTypeFiltersEvery: categoryTypesFiltersEveryArr,
				sortAfterPriority,
				contentFormats,
				contentIsContainedInProducts,
				contentIsContainedInProductIds,
				userHasFavored,
			},
			skip,
		}
	);
	return React.useMemo(() => {
		if (skip) {
			return [];
		}
		if (!data || !data.filteredContents) {
			return undefined;
		}
		if (excludeContentIds) {
			return data.filteredContents.filter(
				(content: ContentInfos) => !excludeContentIds.includes(content.id)
			);
		}
		return data.filteredContents;
	}, [data, excludeContentIds, skip]);
};

export const getSortedFilteredContents = ({
	sortType,
	filteredContents,
}: {
	sortType?: 'MOST_FAVOURITE' | 'NEWEST' | 'ASCENDING' | 'DESCENDING';
	filteredContents?: ContentInfos[];
}): ContentInfos[] | undefined => {
	if (!filteredContents) return undefined;
	if (!sortType) return filteredContents;
	const sortCopy = filteredContents ? [...filteredContents] : [];
	const sortedFilteredContents = !sortType
		? filteredContents
		: sortCopy?.sort((a, b) => {
				if (sortType === 'ASCENDING') {
					return b.title > a.title ? -1 : 1;
				} else if (sortType === 'DESCENDING') {
					return b.title > a.title ? 1 : -1;
				} else if (sortType === 'NEWEST') {
					return new Date(Number(b.createdAt)) > new Date(Number(a.createdAt)) ? 1 : -1;
				} else if (sortType === 'MOST_FAVOURITE') {
					return (b?.popularity || 0) > (a?.popularity || 0) ? 1 : -1;
				} else {
					return 0;
				}
		  });
	return sortedFilteredContents;
};
