import React from 'react';
import styled from 'styled-components';
import { useNavigate } from '../utils/hooks';
import { Box, Toolbar, Grid, BoxProps } from '@mui/material';

import Button from './Button';
import Logo from './Logo';

import { logout } from '../utils/user';
import GridContainer from './GridContainer';
import Txt from './Txt';

const StyledToolBar = styled(Toolbar)`
	background-color: ${(p) => p.theme.customColors.decentBlue};
	min-height: 5rem;
`;

const horizontalSpacing = { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 };
const verticalSpacing = { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 };

const gridConfig = {
	item: true,
	xs: 6,
	sm: 6,
	md: 6,
	lg: 6,
};

const GridItem = ({
	children,
	xs,
	sm,
	md,
	lg,
	xl,
	...rest
}: {
	children: JSX.Element;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
} & BoxProps) => {
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			<Box
				width="100%"
				height="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				{...rest}
			>
				{children}
			</Box>
		</Grid>
	);
};

const AccessPhraseUserAppBar = () => {
	const navigate = useNavigate();

	const handleLogout = React.useCallback(() => {
		logout(navigate);
	}, [navigate]);
	return (
		<StyledToolBar>
			<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
				<GridItem {...gridConfig} justifyContent="flex-start">
					<Logo navigateTo="/" />
				</GridItem>
				<GridItem {...gridConfig} justifyContent="flex-end">
					<Button variant="outlined" color="secondary" onClick={handleLogout} m="0 1rem 0 0">
						Abmelden
					</Button>
				</GridItem>
			</GridContainer>
		</StyledToolBar>
	);
};

export default React.memo(AccessPhraseUserAppBar);
