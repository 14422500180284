import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from 'styled-components';
import { ContentInfos } from '../utils/content';
import { CircularProgress } from '@mui/material';
import { useDivDimensions, usePxPerRemFactor, useWindowDimensions } from '../utils/hooks';
import { appBarHeightVar } from '../utils/localState';
import Button from './Button';
import { CurrentUser } from '../utils/user';
import FilterSectionRender from './FilterSectionRender';
import SortButton, { SortType } from './SortButton';
import { getSortedFilteredContents } from '../utils/content';

const DesktopFilterSection = ({
	contents,
	onChange,
	defaultShowFilter,
	audienceType,
}: {
	contents?: ContentInfos[];
	onChange: (filteredContents?: ContentInfos[]) => void;
	defaultShowFilter?: boolean;
	audienceType?: CurrentUser['audienceType'];
}): JSX.Element => {
	const theme = useTheme();

	const [showFilter, setShowFilter] = React.useState<boolean>(defaultShowFilter || false);
	const [filteredContents, setFilteredContents] = React.useState<ContentInfos[] | undefined>(
		contents
	);
	const [sortType, setSortType] = React.useState<SortType | undefined>(undefined);

	const handleToggleShowFilter = React.useCallback(() => {
		setShowFilter((b) => !b);
	}, []);

	const handleChangeFilteredContents = React.useCallback(
		(newFilteredContents) => {
			setFilteredContents(newFilteredContents);
			const sortedFilteredContents = getSortedFilteredContents({
				sortType,
				filteredContents: newFilteredContents,
			});
			onChange(sortedFilteredContents);
		},
		[onChange, sortType]
	);

	// sort type logic
	const handleSetSortType = React.useCallback(
		(newSortType) => {
			setSortType(newSortType);
			const sortedFilteredContents = getSortedFilteredContents({
				sortType: newSortType,
				filteredContents,
			});
			onChange(sortedFilteredContents);
		},
		[filteredContents, onChange]
	);

	// reset filters logic
	const [resetFiltersKey, setResetFiltersKey] = React.useState<number>(0);
	const handleResetFilters = React.useCallback(() => {
		setResetFiltersKey((before) => before + 1);
	}, []);

	const pxPerRemFactor = usePxPerRemFactor();
	const verticalMargin = pxPerRemFactor;

	// logic to control the filter section positioning to make sure it is always visible
	const appBarHeight = appBarHeightVar();
	const { height: windowHeight } = useWindowDimensions();

	const deps = React.useMemo(
		() => [showFilter, contents, filteredContents],
		[contents, filteredContents, showFilter]
	);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const { top: topDistance, bottom } = useDivDimensions(containerRef, 'scroll&resize', deps);

	const correctedAppBarHeight = appBarHeight + verticalMargin;

	const topReached = topDistance !== undefined ? topDistance <= correctedAppBarHeight : false;
	const bottomDistance = bottom !== undefined ? windowHeight - bottom : undefined;
	const endVisible = bottomDistance !== undefined ? bottomDistance - verticalMargin >= 0 : false;

	const maxFilterHeight =
		bottomDistance !== undefined && topDistance !== undefined
			? endVisible && topReached
				? windowHeight - correctedAppBarHeight - bottomDistance - verticalMargin
				: !endVisible && !topReached
				? windowHeight - topDistance - verticalMargin
				: endVisible
				? windowHeight - correctedAppBarHeight - bottomDistance - verticalMargin
				: topReached
				? windowHeight - correctedAppBarHeight - verticalMargin
				: undefined
			: undefined;

	const widthRef = React.useRef<HTMLDivElement>(null);
	const widthDeps = React.useMemo(() => [topReached, showFilter], [topReached, showFilter]);
	const { width: placeholderWidth } = useDivDimensions(widthRef, 'scroll&resize', widthDeps, 1);

	return !contents ? (
		<CircularProgress />
	) : (
		<>
			<Box ref={containerRef} display="flex" flexDirection="column" m="0 1rem 0 0">
				{topReached ? <Box width={placeholderWidth} height={maxFilterHeight} /> : null}
				<Box
					p="1rem"
					display="flex"
					flexDirection="column"
					position={topReached ? 'fixed' : undefined}
					top={topReached ? correctedAppBarHeight : undefined}
					maxHeight={maxFilterHeight}
					overflow="scroll"
					border={`1px solid ${theme.customColors.lightGrey}`}
					borderRadius="1rem"
					ref={widthRef}
					width={showFilter ? '20rem' : undefined}
				>
					<Box width="100%" display="flex" flexDirection="row">
						<Button
							variant={showFilter ? 'altButton' : 'mainButton'}
							onClick={handleToggleShowFilter}
							startIconName="FilterList"
						>
							Filtern
						</Button>
						<SortButton m="0 0 0 1rem" variant="mainButton" onChange={handleSetSortType} />
					</Box>
					<Box display={showFilter ? undefined : 'none'}>
						<FilterSectionRender
							contents={contents}
							onChange={handleChangeFilteredContents}
							audienceType={resetFiltersKey === 0 ? audienceType : undefined}
							key={resetFiltersKey}
						/>
					</Box>
					<Box
						width="100%"
						display="flex"
						flexDirection="row"
						justifyContent={showFilter ? 'space-around' : 'flex-start'}
						m="1rem 0 0 0"
					>
						<Button variant="secondButton" onClick={handleResetFilters}>
							Filter zurücksetzen
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default React.memo(DesktopFilterSection);
