import React from 'react';

import SectionHeader from './SectionHeader';
import SliderRow from './SliderRow';
import SectionWrapper from './SectionWrapper';
import { useApiFilteredContents } from '../utils/content';

const DemosSection = () => {
	const demoContents = useApiFilteredContents({ contentIsDemo: true });
	const transformedDemoContents = React.useMemo(
		() =>
			demoContents?.map((c) => ({
				id: c.id,
				imageUrl: c.imageUrl,
				navigateTo: `/content/${c.id}`,
				bannerInfo: c.categories
					?.filter(({ groupName }) => groupName === 'AUDIENCE')
					.map(({ title }) => title)
					.join(', '),
			})),
		[demoContents]
	);

	return (
		<SectionWrapper flexDirection="column">
			<SectionHeader
				title="Überzeuge dich selbst!"
				subTitle="Schau dir unsere Demos an und entscheide, womit du starten möchtest."
			/>
			<SliderRow items={transformedDemoContents} tileMarginRightRemFactor={1} />
		</SectionWrapper>
	);
};
export default DemosSection;
