import React from 'react';
import SectionHeader from './SectionHeader';
import SliderRow, { SliderRowItem } from './SliderRow';
import { useCategoriesOfGroup } from '../utils/category';
import SectionWrapper from './SectionWrapper';

const favouriteCategoryTypes = ['DIVERSITY', 'SOPHIE_SCHOLL', 'JOB_ORIENTATION', 'KNOWLEDGE_SNACK'];

const BildungsfreaksFavouritesSection = (): JSX.Element => {
	const categories = useCategoriesOfGroup('COLLECTION');
	const favourites = React.useMemo(
		() =>
			favouriteCategoryTypes
				?.map((favouriteCategory) => {
					const category = categories?.find((c) => c.type === favouriteCategory);

					return category
						? {
								id: category?.id,
								imageUrl: category?.imageUrl,
								navigateTo: `/category/${category?.id}`,
						  }
						: undefined;
				})
				.filter(Boolean) as SliderRowItem[],

		[categories]
	);

	return (
		<SectionWrapper flexDirection="column">
			<SectionHeader
				alignItems="center"
				title="bildungsfreaks-Lieblinge"
				subTitle="Entdecke unsere beliebtesten Themen für den digitalen Unterricht."
			/>
			{favourites ? <SliderRow items={favourites} tileMarginRightRemFactor={1} /> : null}
		</SectionWrapper>
	);
};

export default BildungsfreaksFavouritesSection;
