import React from 'react';

import { useSnackbar } from '../utils/snackBar';
import Button from './Button';
import IntegerSelectField, { IntegerSelectFieldRef } from './IntegerSelectField';
import TimePeriodSelectForm, {
	TimePeriodSelectFormSimpleRef,
	TimeUnit,
} from './TimePeriodSelectForm';
import OldTextField from './OldTextField';
import DialogWrapper from './DialogWrapper';
import ContentSelectField, { ContentSelectFieldRef } from './ContentSelectField';
import { DialogButtonRow, DialogHeader } from './Dialog';
import useTextFieldController from '../utils/useTextFieldController';
import createAccessGenerator from '../mutations/createAccessGenerator';
import { useCurrentUser } from '../utils/user';

const enabledTimeUnits: TimeUnit[] = ['DAY', 'WEEK', 'MONTH'];

const contentFilter = {
	userHasAccess: true,
	userAudiences: 'PUPILS',
	contentIsActive: true,
};

const CreateAccessGeneratorDialog = ({
	dismissPortal,
}: {
	dismissPortal: () => void;
}): JSX.Element => {
	const currentUser = useCurrentUser();
	const snackBar = useSnackbar();

	const [loading, setLoading] = React.useState(false);

	const {
		textInputProps: groupFieldProps,
		validate: validateGroupFn,
		newStateValue: groupName,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Gruppenname',
		stateKey: 'group',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
		textFieldType: 'group',
	});
	const [numberOfLinks, setNumberOfLinks] = React.useState<number>(1);
	const [timeUnit, setTimeUnit] = React.useState<TimeUnit>('DAY');
	const [timeUnitFactor, setTimeUnitFactor] = React.useState<number>(7);
	const [contentId, setContentId] = React.useState<string | undefined>(undefined);

	const handleChangeNumberOfLinks = React.useCallback((value) => {
		setNumberOfLinks(value as number);
	}, []);

	const handleChangeTimeUnit = React.useCallback((value) => {
		setTimeUnit(value as TimeUnit);
	}, []);

	const handleChangeTimeUnitFactor = React.useCallback((value) => {
		setTimeUnitFactor(value as number);
	}, []);

	const handleChangeContent = React.useCallback((selectedOptions) => {
		const selectedContentId = selectedOptions[0]?.value;
		setContentId(selectedContentId as string);
	}, []);

	const integerSelectFieldRef = React.useRef<IntegerSelectFieldRef>(null);
	const timePeriodSelectFormRef = React.useRef<TimePeriodSelectFormSimpleRef>(null);
	const contentSelectFieldRef = React.useRef<ContentSelectFieldRef>(null);

	const handleValidate = React.useCallback(() => {
		const groupInvalid = Boolean(validateGroupFn!());
		const restInvalid = [integerSelectFieldRef, timePeriodSelectFormRef, contentSelectFieldRef]
			.map((r) => r.current?.validate())
			.some(Boolean);
		return groupInvalid || restInvalid;
	}, [validateGroupFn]);

	const handleCreateAccessGenerator = React.useCallback(async () => {
		const invalid = handleValidate();
		if (invalid) {
			return;
		}
		setLoading(true);
		const { error } = await createAccessGenerator({
			groupName: groupName!,
			numberOfLinks: numberOfLinks!,
			timeUnit,
			timeUnitFactor,
			contentId: contentId!,
		});
		if (error) {
			setLoading(false);
			snackBar({ error });
		} else {
			snackBar({ success: 'Zugangs-Generator erstellt' });
			dismissPortal();
		}
	}, [
		contentId,
		dismissPortal,
		groupName,
		handleValidate,
		numberOfLinks,
		snackBar,
		timeUnit,
		timeUnitFactor,
	]);

	const remainingAccessPhrases = Math.min(40, currentUser?.accessGeneratorsInfo?.remaining || 0);

	return (
		<DialogWrapper>
			<DialogHeader
				title="Kurse teilen"
				description="Hier können Kurse mit anderen geteilt werden."
			/>
			<IntegerSelectField
				width="100%"
				label="Anzahl"
				onChange={handleChangeNumberOfLinks}
				margin="2rem 0 0 0"
				defaultValue={numberOfLinks}
				ref={integerSelectFieldRef}
				min={1}
				max={remainingAccessPhrases}
				disabled={remainingAccessPhrases === 0}
			/>
			<TimePeriodSelectForm
				width="100%"
				onChangeTimeUnit={handleChangeTimeUnit}
				onChangeTimeUnitFactor={handleChangeTimeUnitFactor}
				margin="2rem 0 0 0"
				timeUnitFactorLabel="Gültigkeit Anzahl"
				timeUnitLabel="Gültigkeit Einheit"
				defaultTimeUnit={timeUnit}
				defaultTimeUnitFactor={timeUnitFactor}
				ref={timePeriodSelectFormRef}
				enabledTimeUnits={enabledTimeUnits}
			/>
			<ContentSelectField
				contentFilter={contentFilter}
				label="Kurs wählen"
				onChange={handleChangeContent}
				m="2rem 0 0 0"
				width="100%"
				ref={contentSelectFieldRef}
				labelKey="title"
				valueKey="id"
			/>
			<OldTextField width="100%" m="2rem 0 0 0" {...groupFieldProps} />
			<DialogButtonRow>
				<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading} variant="secondButton">
					Abbrechen
				</Button>
				<Button
					onClick={handleCreateAccessGenerator}
					loading={loading}
					variant="mainButton"
					disabled={remainingAccessPhrases === 0}
				>
					Erstellen
				</Button>
			</DialogButtonRow>
		</DialogWrapper>
	);
};

export default CreateAccessGeneratorDialog;
