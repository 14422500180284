import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from '../utils/hooks';

import Button from '../components/Button';
import Dialog from '../components/Dialog';
import AuthWrapper from '../components/AuthWrapper';
import FilledTextField from '../components/FilledTextField';

import useTextFieldController from '../utils/useTextFieldController';
import { fetchUser, setToken } from '../utils/user';
import loginWithPhrase from '../mutations/phraseLogin';

const PhraseLoginView = (): JSX.Element => {
	const navigate = useNavigate();

	const {
		newStateValue: phrase,
		textInputProps: textFieldProps,
		validate: validate,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Login-Phrase',
		stateKey: 'phrase',
		validationFunction: (val) => (!val ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
		textFieldType: 'phrase',
	});

	const [loading, setLoading] = React.useState(false);

	const handleLogin = React.useCallback(async () => {
		const invalid = validate!();
		if (invalid) {
			return;
		}

		setLoading(true);
		const { success, jwt, error } = await loginWithPhrase(phrase!);
		setLoading(false);
		if (success) {
			setToken(jwt!);
			await fetchUser();
			navigate('/');
		} else {
			switch (error) {
				case 'USER_NOT_FOUND':
					Dialog.render({
						title: 'Fehler',
						description: 'Diese Emailadresse ist uns nicht bekannt.',
					});
					break;
				case 'NETWORK_ERROR':
					Dialog.render({
						title: 'Fehler',
						description: 'Netzwerkfehler',
					});
					break;
				default:
					Dialog.render({
						title: 'Fehler',
						description: 'Es ist ein unbekannter Fehler aufgetreten.',
					});
			}
		}
	}, [navigate, phrase, validate]);

	return (
		<AuthWrapper title="Mit Login-Phrase anmelden">
			<>
				<Grid key={textFieldProps.label} item xs={12}>
					<FilledTextField fullWidth variant="filled" {...textFieldProps} />
				</Grid>

				<Grid item xs={12}>
					<Button fullWidth onClick={handleLogin} variant="mainButton" loading={loading}>
						Anmelden
					</Button>
				</Grid>
			</>
		</AuthWrapper>
	);
};

export default PhraseLoginView;
