import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';

import ContentWrapper from '../components/ContentWrapper';
import { useAllCategories } from '../utils/category';
import SimpleTable3 from '../components/SimpleTable3';
import IconButton from '../components/IconButton';

const Row = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
`;

const activeKeys = ['groupName', 'type', 'title', 'subTitle', 'description', 'shortDescription'];

const CategoryListView = (): JSX.Element => {
	const categories = useAllCategories();
	const renderActions = React.useCallback(({ id }) => {
		return (
			<Row>
				<IconButton m="0 1rem 0 0" iconName="EditRounded" navigateTo={`/category/${id}`} id={id} />
			</Row>
		);
	}, []);

	return (
		<ContentWrapper p="2rem">
			{!categories ? null : (
				<SimpleTable3
					objects={categories}
					activeKeys={activeKeys}
					numberOfLines={2}
					renderActions={renderActions}
				/>
			)}
		</ContentWrapper>
	);
};

export default CategoryListView;
