// deafult z-indexes of material-ui components:
// mobile stepper: 1000
// fab: 1050
// speed dial: 1050
// app bar: 1100
// drawer: 1200
// modal: 1300
// snackbar: 1400
// tooltip: 1500

// dialog neeeds to go over app bar

export const POPPER_ZINDEX = 1103;
export const DIALOG_ZINDEX = 1102;
export const DIALOG_BACKGROUND_ZINDEX = 1101;
export const FLOATING_ACTION_BUTTON_ZINDEX = 1099;
