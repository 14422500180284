import React from 'react';
import { Box, Input } from '@mui/material';
import styledWeb from 'styled-components';
import Button, { ButtonProps } from '../components/Button';

const UploadButtonWrapper = styledWeb(Box)`
	margin: 0;
	> .UploadButton-input {
		display: none;
	};
`;

interface ExtendedButtonProps extends Omit<ButtonProps, 'onChange' | 'onError'> {
	label?: string;
	fileName?: string;
	onChange: ({ fileUrl, file }: { fileUrl: string; file: File }) => void;
	onError?: (errorMessage: 'NO_TARGET' | 'INVALID' | 'NO_FILE') => void;
}

const UploadFileButton = ({
	label = 'Upload',
	onChange,
	onError,
	...rest
}: ExtendedButtonProps): JSX.Element => {
	const handleImageUpload = React.useCallback(
		(event) => {
			const target = event?.target as HTMLInputElement;
			if (!target) {
				onError?.('NO_TARGET');
				return;
			}
			const valid = target?.validity?.valid;
			if (!valid) {
				onError?.('INVALID');
				return;
			}
			const file = target?.files?.[0];
			if (!file) {
				onError?.('NO_FILE');
				return;
			}
			const fileUrl = URL.createObjectURL(file);
			onChange({ file, fileUrl });
		},
		[onChange, onError]
	);

	return (
		<UploadButtonWrapper>
			<Input
				className="UploadButton-input"
				id="contained-button-file"
				type="file"
				onChange={handleImageUpload}
			/>
			<label htmlFor="contained-button-file">
				<Button {...rest}>{label}</Button>
			</label>
		</UploadButtonWrapper>
	);
};

export default UploadFileButton;
