import React from 'react';
import { Box } from '@mui/material';
import { useDivDimensions, useNavigate } from '../utils/hooks';

import Txt from './Txt';
import SectionWrapper from './SectionWrapper';
import { useCategoriesOfGroup } from '../utils/category';
import CardActionArea from './CardActionArea';

import INTERACTIVE from '../images/BF_Grafik_Kategorie_Interaktive_Lerneinheiten.png';
import TEMPLATES from '../images/BF_Grafik_Kategorie_Checklisten_Vorlagen.png';
import TRAINING from '../images/BF_Grafik_Kategorie_Fortbildungen.png';
import WORKSHEET from '../images/BF_Grafik_Kategorie_Materialien.png';
import PROJECT from '../images/BF_Grafik_Kategorie_Unterrichtsprogramme.png';
import ImageBox from './ImageBox';
import { BoxProps } from '@mui/system';

const imageDict = {
	INTERACTIVE,
	TEMPLATES,
	TRAINING,
	WORKSHEET,
	PROJECT,
};

const orderDict = {
	INTERACTIVE: 1,
	TRAINING: 2,
	PROJECT: 3,
	WORKSHEET: 4,
	TEMPLATES: 5,
};

const MINIMUM_SIZE = 300;

const BackgroundBox = ({
	image,
	children,
	...rest
}: { image: string } & BoxProps): JSX.Element | null => {
	return (
		<Box position="relative" {...rest}>
			{children}
			<Box zIndex={-100} position="absolute" left="0" top="0" right="0" bottom="0">
				<ImageBox objectFit="cover" width="100%" height="100%" src={image} />
			</Box>
		</Box>
	);
};

const EducationFormatCategoriesSectionSection = (): JSX.Element | null => {
	const navigate = useNavigate();
	const allEducationFormats = useCategoriesOfGroup('EDUCATION_FORMAT');
	const sortedEducationFormats = React.useMemo(() => {
		return allEducationFormats
			? [...allEducationFormats].sort((a, b) => orderDict[a.type] - orderDict[b.type])
			: undefined;
	}, [allEducationFormats]);

	// get available width
	const ref = React.useRef<HTMLDivElement>(null);
	const deps = React.useMemo(() => [sortedEducationFormats], [sortedEducationFormats]);
	const { width: availableWidth } = useDivDimensions(ref, 'resize', deps);

	// calculate number of rows, items per row and box size
	const numberOfItemsPerRow = availableWidth ? Math.floor(availableWidth / MINIMUM_SIZE) : 1;
	const numberOfRows = sortedEducationFormats
		? Math.ceil(sortedEducationFormats.length / numberOfItemsPerRow)
		: 1;
	const boxSize = availableWidth ? availableWidth / numberOfItemsPerRow : MINIMUM_SIZE;

	const handleClick = React.useCallback(
		(id) => {
			navigate(`/category/${id}`);
		},
		[navigate]
	);

	return !sortedEducationFormats ? null : (
		<SectionWrapper flexDirection="column">
			<Box ref={ref} width="100%" display="hidden" />
			{!availableWidth
				? null
				: Array.from(Array(numberOfRows).keys()).map((_, rowIndex) => {
						const rowItems = sortedEducationFormats.slice(
							rowIndex * numberOfItemsPerRow,
							(rowIndex + 1) * numberOfItemsPerRow
						);

						return (
							<Box key={rowIndex} display="flex" flexDirection="row" width="100%">
								{rowItems.map((item, index) => {
									return (
										<Box
											key={`${rowIndex}:${index}`}
											width={boxSize}
											minHeight={boxSize}
											display="flex"
											p="0.5rem"
										>
											<BackgroundBox
												width="100%"
												height="100%"
												image={imageDict[item.type]}
												display="flex"
												borderRadius="1rem"
												overflow="hidden"
											>
												<CardActionArea
													width="100%"
													height="100%"
													id={item.id}
													onClick={handleClick}
												>
													<Box display="flex" flexDirection="column" width="100%" height="100%">
														<Box width="100%" height={boxSize / 2}>
															<Txt m="28% 12% 12% 12%" fontWeight="bold" variant="h6">
																{item.title}
															</Txt>
														</Box>
														<Box width="100%" minHeight={boxSize / 2}>
															<Txt m="5% 12% 12% 12%" variant="body2">
																{item.shortDescription}
															</Txt>
														</Box>
													</Box>
												</CardActionArea>
											</BackgroundBox>
										</Box>
									);
								})}
							</Box>
						);
				  })}
		</SectionWrapper>
	);
};

export default EducationFormatCategoriesSectionSection;
