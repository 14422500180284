import React from 'react';

import SectionWrapper from '../components/SectionWrapper';
import ImageHeader from '../components/ImageHeader';
import ContentWrapper from '../components/ContentWrapper';
import Contents from '../components/Contents';
import CategoriesRow from '../components/CategoriesRow';
import EducationFormatCategoriesSection from '../components/EducationFormatCategoriesSection';
import ProBannerSection from '../components/ProBannerSection';
import SectionHeader from '../components/SectionHeader';

import { useCategoriesOfGroup } from '../utils/category';

import image from '../images/BF_Header_FuerLehrer.png';
import { useCurrentUser } from '../utils/user';
import { useNavigate } from '../utils/hooks';
import { useRecommendedApiContents } from '../utils/content';
import BildungsfreaksFavouritesSection from '../components/BildungsfreaksFavouritesSection';

const LoggedOutTeacherView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();
	const navigate = useNavigate();

	const educationLevelCategories = useCategoriesOfGroup('EDUCATION_LEVEL');
	const collectionCategories = useCategoriesOfGroup('COLLECTION');

	React.useEffect(() => {
		if (currentUser && currentUser.audienceType !== 'TEACHER') {
			navigate('/');
		}
	}, [currentUser, navigate]);

	const recommendedContents = useRecommendedApiContents('TEACHER');

	return currentUser === undefined ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={image}
				title="Mach dich und deine Klasse zu bildungsfreaks."
				description="Erhalte Zugang zu Online-Fortbildungen, interaktiven Unterrichtsprogrammen, einsatzbereiten Unterrichtsentwürfen sowie digitalen Unterrichtseinheiten und -materialien."
			/>
			<SectionWrapper>
				<Contents
					contents={recommendedContents}
					buttonLabel="Mehr entdecken"
					navigateTo="/contents/TEACHER"
					title="Die Favoriten"
					subTitle="Schau dir unsere beliebstesten Kurse an!"
					maxItems={6}
				/>
			</SectionWrapper>

			<EducationFormatCategoriesSection />
			<SectionWrapper flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title="Mach Bildungsfreaks zu deinem Ding!"
					subTitle="Finde passende Kurse nach Themengebiet oder Altersgruppe."
				/>
				<CategoriesRow categories={educationLevelCategories || undefined} m="0 0 1rem 0" />
				<CategoriesRow categories={collectionCategories || undefined} />
			</SectionWrapper>
			<ProBannerSection />
			<BildungsfreaksFavouritesSection />
		</ContentWrapper>
	);
};

export default LoggedOutTeacherView;
