import client from '../utils/client';
import { gql } from '@apollo/client';

type Error =
	| 'USER_NOT_FOUND'
	| 'USER_NOT_VERIFIED'
	| 'CREDENTIALS_DO_NOT_MATCH'
	| 'UNEXPECTED_ERROR'
	| 'NETWORK_ERROR';

const phraseLogin = async (
	phrase: string
): Promise<
	| { success: true; jwt: string; error: undefined }
	| { success: false; jwt: undefined; error: Error }
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation PhraseLogin($phrase: String!) {
					phraseLogin(phrase: $phrase) {
						success
						jwt
					}
				}
			`,
			variables: { phrase },
			errorPolicy: 'all',
		});

		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
				jwt: undefined,
			};
		}
		return {
			success: true,
			jwt: data.phraseLogin.jwt,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR', jwt: undefined };
		throw e;
	}
};

export default phraseLogin;
