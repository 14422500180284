import React from 'react';
import { Grid, Box } from '@mui/material';
import { useNavigate } from '../utils/hooks';

import Button from '../components/Button';
import Dialog from '../components/Dialog';
import AuthWrapper from '../components/AuthWrapper';
import FilledTextField from '../components/FilledTextField';

import useTextFieldController from '../utils/useTextFieldController';
import { checkValidationFunctions, validateEmail, validatePassword } from '../utils/helpers';
import { fetchUser, setToken } from '../utils/user';
import login from '../mutations/login';
import { useSearchParams } from 'react-router-dom';

const LoginView = (): JSX.Element => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const contentId = searchParams.get('contentId') || undefined;
	const {
		newStateValue: email,
		textInputProps: emailFieldProps,
		validate: validateEmailFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Email',
		stateKey: 'email',
		validationFunction: validateEmail,
		stateValueFallback: '',
		textFieldType: 'email',
	});

	const {
		newStateValue: password,
		textInputProps: passwordFieldProps,
		validate: validatePasswordFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Passwort',
		stateKey: 'password',
		validationFunction: validatePassword,
		stateValueFallback: '',
		textFieldType: 'password',
	});

	const [loading, setLoading] = React.useState(false);

	const handleLogin = React.useCallback(async () => {
		const isValid = checkValidationFunctions([validateEmailFn, validatePasswordFn]);
		if (isValid) {
			setLoading(true);
			const { success, jwt, error } = await login(email!, password!);
			setLoading(false);
			if (success) {
				setToken(jwt!);
				await fetchUser();
				contentId ? navigate(`/content/${contentId}`) : navigate('/');
			} else {
				switch (error) {
					case 'USER_NOT_FOUND':
						Dialog.render({
							title: 'Fehler',
							description: 'Diese Emailadresse ist uns nicht bekannt.',
						});
						break;
					case 'USER_NOT_VERIFIED':
						Dialog.render({
							title: 'Fehler',
							description: 'Emailadresse wurde noch nicht bestätigt.',
						});
						break;
					case 'CREDENTIALS_DO_NOT_MATCH':
						Dialog.render({
							title: 'Fehler',
							description: 'Email und Passwort stimmen nicht überein.',
						});
						break;
					case 'NETWORK_ERROR':
						Dialog.render({
							title: 'Fehler',
							description: 'Netzwerkfehler',
						});
						break;
					default:
						Dialog.render({
							title: 'Fehler',
							description: 'Es ist ein unbekannter Fehler aufgetreten.',
						});
				}
			}
		}
	}, [contentId, email, navigate, password, validateEmailFn, validatePasswordFn]);

	const textFieldsProps = React.useMemo(
		() => [emailFieldProps, passwordFieldProps],
		[emailFieldProps, passwordFieldProps]
	);

	return (
		<AuthWrapper title="Login">
			<>
				{textFieldsProps.map((textFieldProps) => (
					<Grid key={textFieldProps.label} item xs={12}>
						<FilledTextField fullWidth variant="filled" {...textFieldProps} />
					</Grid>
				))}
			</>
			<Grid item xs={12}>
				<Button variant="link" navigateTo="/reset-password" disabled={loading}>
					Passwort vergessen?
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Button
					fullWidth
					onClick={handleLogin}
					variant="mainButton"
					loading={loading}
					triggerOnEnter
				>
					Anmelden
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Box width="100%" display="flex" flexDirection="row" alignItems="center">
					<Button m="0 1rem 0 0 " variant="link" navigateTo="/signup" disabled={loading}>
						Ein Konto erstellen
					</Button>
					<Button variant="link" navigateTo="/phrase-login" disabled={loading}>
						Mit Phrase anmelden
					</Button>
				</Box>
			</Grid>
		</AuthWrapper>
	);
};

export default LoginView;
