import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import OldTextField from '../components/OldTextField';
import ContentWrapper from '../components/ContentWrapper';
import { useCategory } from '../utils/category';
import useTextFieldController from '../utils/useTextFieldController';
import { useSnackbar } from '../utils/snackBar';
import Button from '../components/Button';
import updateCategory from '../mutations/updateCategory';
import ImageBox from '../components/ImageBox';
import UploadFileButton from '../components/UploadButton';
import uploadFile from '../expressRequests/uploadFile';

const UpdateCategoryForm = ({
	id,
	defaultTitle,
	defaultDescription,
	defaultSubTitle,
	defaultShortDescription,
	defaultImageUrl,
}: {
	id: string;
	defaultTitle: string;
	defaultDescription?: string;
	defaultSubTitle?: string;
	defaultShortDescription?: string;
	defaultImageUrl?: string;
}) => {
	const [loading, setLoading] = React.useState(false);
	const snackBar = useSnackbar();

	const {
		newStateValue: title,
		textInputProps: titleFieldProps,
		validate: validateTitle,
		changed: titleChanged,
	} = useTextFieldController({
		defaultValue: defaultTitle,
		inputLabel: 'Produkttitel',
		stateKey: 'title',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const {
		newStateValue: description,
		textInputProps: descriptionFieldProps,
		changed: descriptionChanged,
	} = useTextFieldController({
		defaultValue: defaultDescription,
		inputLabel: 'Kurzbeschreibung',
		stateKey: 'description',
		stateValueFallback: '',
	});

	const {
		newStateValue: subTitle,
		textInputProps: subTitleFieldProps,
		changed: subTitleChanged,
	} = useTextFieldController({
		defaultValue: defaultSubTitle,
		inputLabel: 'Beschreibungstitel',
		stateKey: 'subTitle',
		stateValueFallback: '',
	});

	const {
		newStateValue: shortDescription,
		textInputProps: shortDescriptionFieldProps,
		changed: shortDescriptionChanged,
	} = useTextFieldController({
		defaultValue: defaultShortDescription,
		inputLabel: 'Untertitel',
		stateKey: 'shortDescription',
		stateValueFallback: '',
	});

	const [updatedImageFile, setUpdatedImageFile] = React.useState<File | undefined>(undefined);
	const [updatedImageUrl, setUpdatedImageUrl] = React.useState<string | undefined>(undefined);
	const handleChangeFile = React.useCallback(({ file, fileUrl }) => {
		console.log({ file, fileUrl });
		setUpdatedImageUrl(fileUrl);
		setUpdatedImageFile(file);
	}, []);

	const somethinChanged =
		titleChanged ||
		descriptionChanged ||
		subTitleChanged ||
		shortDescriptionChanged ||
		updatedImageUrl;

	const handleUpdateCategory = React.useCallback(async () => {
		const invalid = validateTitle!();
		if (invalid) return;

		let uploadedImageUrl: string | undefined = undefined;
		setLoading(true);
		if (updatedImageFile) {
			const { success, fileUrl, error, errorMessage } = await uploadFile({
				file: updatedImageFile,
				type: 'CATEGORY_IMAGE',
			});
			if (!success) {
				setLoading(false);
				snackBar({ error, errorMessage });
				return;
			}
			uploadedImageUrl = fileUrl;
		}
		const { success, error } = await updateCategory({
			id,
			title: title!,
			description: description || undefined,
			subTitle: subTitle || undefined,
			shortDescription: shortDescription || undefined,
			imageUrl: uploadedImageUrl,
		});
		setLoading(false);
		if (success) {
			snackBar({ success: 'Kategorie gespeichert!' });
		} else {
			snackBar({ error });
		}
	}, [
		description,
		id,
		updatedImageFile,
		shortDescription,
		snackBar,
		subTitle,
		title,
		validateTitle,
	]);

	return (
		<>
			<OldTextField width="100%" m="1rem 0 0" {...titleFieldProps} />
			<OldTextField width="100%" m="1rem 0 0" {...shortDescriptionFieldProps} />
			<OldTextField width="100%" m="1rem 0 0" {...subTitleFieldProps} />
			<OldTextField width="100%" m="1rem 0 0" {...descriptionFieldProps} />

			<ImageBox
				m="1rem 0 0 0"
				border="1px solid black"
				width="20rem"
				src={updatedImageUrl || defaultImageUrl}
			>
				<Box position="absolute" right="0" bottom="0" display="flex">
					<UploadFileButton m="1rem" variant="mainButton" onChange={handleChangeFile} />
				</Box>
			</ImageBox>
			<Button
				disabled={!somethinChanged}
				variant="mainButton"
				onClick={handleUpdateCategory}
				loading={loading}
				m="1rem 0 0"
			>
				Speichern
			</Button>
		</>
	);
};

const CategoryDetailView = (): JSX.Element => {
	const { id } = useParams();
	const category = useCategory(id);

	return (
		<ContentWrapper p="2rem">
			{!category ? null : (
				<>
					<UpdateCategoryForm
						id={id!}
						defaultTitle={category.title}
						defaultDescription={category.description}
						defaultSubTitle={category.subTitle}
						defaultShortDescription={category.shortDescription}
						defaultImageUrl={category.imageUrl}
					/>
				</>
			)}
		</ContentWrapper>
	);
};

export default CategoryDetailView;
