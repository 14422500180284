import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { ProductDetailsFragment, type Product } from './product';

export type Order = {
	id: string;
	userId: string;
	createdAt: string;
	totalPrice?: number;
	priceLabel: string;
	taxes?: number;
	taxesLabel: string;
	status: 'STARTED' | 'COMPLETE';
	products: Product[];
};

export const OrderDetailsFragment = gql`
	fragment OrderDetailsFragment on Order {
		id
		userId
		createdAt
		totalPrice
		priceLabel
		taxes
		taxesLabel
		status
		products {
			...ProductDetailsFragment
		}
	}
	${ProductDetailsFragment}
`;

export const useUserOrders = (): Order[] | undefined => {
	const { data } = useQuery(
		gql`
			query UserContent {
				currentUser {
					orders {
						...OrderDetailsFragment
					}
				}
			}
			${OrderDetailsFragment}
		`
	);
	return React.useMemo(() => data?.currentUser?.orders, [data?.currentUser?.orders]);
};

export const useUserOrder = (id?: string): Order | undefined => {
	const { data } = useQuery(
		gql`
			query UserContent {
				currentUser {
					orders {
						...OrderDetailsFragment
					}
				}
			}
			${OrderDetailsFragment}
		`,
		{
			skip: !id,
		}
	);
	return React.useMemo(() => {
		const allUserOrders = data?.currentUser?.orders;
		return allUserOrders?.filter((order) => order.id === id)[0] || undefined;
	}, [data?.currentUser?.orders, id]);
};
