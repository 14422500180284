import client from '../utils/client';
import { gql } from '@apollo/client';

type Error = 'NETWORK_ERROR' | 'INVALID_CONTENT' | 'INACTIVE_CONTENT' | 'NO_ACCESS_TO_CONTENT';

const createLaunchLink = async (
	contentId: string
): Promise<
	| { success: true; error: undefined; link: string }
	| { success: false; error: Error; link: undefined }
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation CreateLaunchLink($contentId: String!) {
					createLaunchLink(contentId: $contentId) {
						success
						link
					}
				}
			`,
			variables: { contentId },
		});
		if (errors) {
			return { success: false, error: errors[0]?.extensions?.code as Error, link: undefined };
		}
		return {
			success: true,
			error: undefined,
			link: data.createLaunchLink.link,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR', link: undefined };
		throw e;
	}
};

export default createLaunchLink;
