import React from 'react';
import { Box, CardActionArea } from '@mui/material';

import { useNavigate } from '../utils/hooks';
import styled from 'styled-components';
import Txt from './Txt';
import ImageBox from './ImageBox';
import Dialog from '../components/Dialog';
import ProgressBar from '../components/ProgressBar';
import Badge from './Badge';
import { type Category } from '../utils/category';
import { ContentDetails, type ContentInfos } from '../utils/content';
import { useCurrentUser } from '../utils/user';
import FavouriteIcon from './FavouriteIcon';

export const WIDTH: number = 15;
export const HEIGHT: number = 27;

const AbsolutTopRight = styled(Box)`
	position: absolute;
	right: 0;
	top: 0;
	padding: 0.5rem;
`;

const Wrapper = styled(Box)`
	min-width: ${WIDTH}rem;
	width: ${WIDTH}rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border: 1px solid ${(p) => p.theme.customColors.lightGrey};
	border-radius: 1rem;
	overflow: hidden;
	height: ${HEIGHT}rem;
`;

const Row = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const Spacer = styled(Box)`
	flex: 1;
`;

const AbsoluteBottomLeft = styled(Box)`
	position: absolute;
	left: 0;
	bottom: 0;
`;

const Module = ({ order }: { order: number }) => {
	return (
		<AbsoluteBottomLeft>
			<Txt fontWeight="bold" m="0 0 0.5rem 1rem">
				Modul {order}
			</Txt>
		</AbsoluteBottomLeft>
	);
};

const ContentTile = ({
	id,
	title,
	imageUrl,
	author,
	categories,
	completion,
	showProgress,
	active,
	serieOrder,
	accessibilities,
	m,
}: {
	id: string;
	title: string;
	imageUrl?: string;
	author?: string;
	categories: Category[];
	completion?: number;
	showProgress?: boolean;
	userHasAccess?: boolean;
	active?: boolean;
	serieOrder?: ContentInfos['serieOrder'];
	accessibilities?: ContentDetails['accessibilities'];
	m?: string;
}) => {
	const navigate = useNavigate();
	const handleNavigateToContent = React.useCallback(() => {
		navigate(`/content/${id}`);
	}, [id, navigate]);

	const handleShowDisabledInfo = React.useCallback(async () => {
		Dialog.render({
			title: 'Produktinhalt deaktiviert',
			description:
				'Dieser Produktinhalt wurde deaktiviert und ist aktuell nicht verfügbar. Für weitere Informationen kontaktiere bitte den Support.',
		});
	}, []);

	return (
		<Wrapper m={m}>
			<CardActionArea onClick={!active ? handleShowDisabledInfo : handleNavigateToContent}>
				<ImageBox
					width="100%"
					height="17rem"
					src={imageUrl}
					objectFit="cover"
					disabled={!active}
					pointer
				>
					<AbsolutTopRight>
						<Badge width="3.5rem" accessibilities={accessibilities} />
					</AbsolutTopRight>
					{serieOrder ? <Module order={serieOrder.order} /> : null}
				</ImageBox>
				<Txt m="1rem 1rem 0 1rem" variant="body1" numberOfLines={2}>
					{title}
				</Txt>
				<Txt width="100%" align="left" m="0 0 0.5rem 1rem" variant="caption" numberOfLines={1}>
					{author}
				</Txt>
				{showProgress ? <ProgressBar progress={completion} /> : null}
			</CardActionArea>
			<Spacer />
			<Row justifyContent="space-between" p="0.5rem 1rem 1rem">
				<Txt variant="caption" numberOfLines={1}>
					{categories
						.filter(({ groupName }) => groupName === 'EDUCATION_FORMAT')
						.map(({ title: t }) => t)
						.join(', ')}
				</Txt>
				<FavouriteIcon contentId={id} />
			</Row>
		</Wrapper>
	);
};

export default React.memo(ContentTile);
