import React from 'react';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player/youtube';
import { useExternalCookiesAllowed } from '../utils/cookie';
import CookiesDisabled from './CookiesDisabled';

const ReactPlayerWrapper = ({ url }: { url: string }): JSX.Element | null => {
	const externalCookiesAllowed = useExternalCookiesAllowed();
	return externalCookiesAllowed ? (
		<Box display="flex" position="relative" maxWidth="100%">
			<ReactPlayer controls url={url} />
		</Box>
	) : (
		<CookiesDisabled width="50%" height="20rem" />
	);
};

export default ReactPlayerWrapper;
