import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, Toolbar, Grid, BoxProps } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Button from './Button';
import IconButton from './IconButton';
import Logo from './Logo';
import GridContainer from './GridContainer';
import WidthLimiter from './WidthLimiter';
import { useIsMobile } from '../utils/hooks';
import MobileNavigation from './MobileNavigation';
import { useDesktopNavButtons, useMobileNavButtons, useAuthButtons } from '../utils/navButtons';

const StyledToolBar = styled(Toolbar)<{ $minHeight: string }>`
	background-color: ${(p) => p.theme.customColors.decentBlue};
	min-height: ${(p) => p.$minHeight || ''};
`;

const horizontalSpacing = { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 };
const verticalSpacing = { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 };

const searchAndLogoGridItemProps = {
	xs: 12,
	sm: 12,
	md: 3,
	lg: 3,
};

const navButtonsGridItemProps = {
	xs: 12,
	sm: 12,
	md: 5,
	lg: 6,
};

const authButtonGridItemProps = {
	xs: 12,
	sm: 12,
	md: 4,
	lg: 3,
};

const GridItem = ({
	children,
	xs,
	sm,
	md,
	lg,
	xl,
	...rest
}: {
	children: JSX.Element;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
} & BoxProps) => {
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			<Box
				width="100%"
				height="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				{...rest}
			>
				{children}
			</Box>
		</Grid>
	);
};

const DesktopTopAppBar = () => {
	const location = useLocation();
	const theme = useTheme();
	const pathName = location.pathname;
	const authButtons = useAuthButtons();
	const mainNavButtons = useDesktopNavButtons();

	return (
		<StyledToolBar $minHeight="5rem">
			<WidthLimiter maxWidth={theme.contentMaxWidthPx}>
				<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
					<GridItem {...searchAndLogoGridItemProps} justifyContent="flex-start">
						<Logo navigateTo="/" />
					</GridItem>
					<GridItem {...navButtonsGridItemProps}>
						<>
							{mainNavButtons.map(({ label, navigateTo, variant, iconName }) => {
								const isActive = navigateTo === pathName;
								return iconName ? (
									<IconButton
										iconName={iconName}
										key={label}
										navigateTo={navigateTo}
										m="0 1rem 0 0"
										backgroundColor={isActive ? theme.customColors.white : ''}
									/>
								) : (
									<Button
										key={label}
										color="secondary"
										variant={isActive ? 'activeTextWhite' : variant}
										navigateTo={navigateTo}
										m="0 1rem 0 0"
									>
										{label}
									</Button>
								);
							})}
						</>
					</GridItem>

					<GridItem {...authButtonGridItemProps} justifyContent="flex-end">
						<>
							{authButtons.map(({ label, navigateTo, variant, iconName, onClick }) => {
								return (
									<Button
										key={label}
										color="secondary"
										variant={variant}
										navigateTo={navigateTo}
										onClick={onClick}
										m="0 0 0 1rem"
										startIconName={iconName}
									>
										{label}
									</Button>
								);
							})}
						</>
					</GridItem>
				</GridContainer>
			</WidthLimiter>
		</StyledToolBar>
	);
};

const MobileTopAppBar = () => {
	const theme = useTheme();

	const mainNavButtonProps = useMobileNavButtons();
	const authButtons = useAuthButtons();

	return (
		<StyledToolBar>
			<WidthLimiter maxWidth={theme.contentMaxWidthPx}>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					width="100%"
					alignItems="center"
				>
					<MobileNavigation navButtons={mainNavButtonProps} actionButtons={authButtons} />
					<Logo navigateTo="/" maxWidth="9rem" />
				</Box>
			</WidthLimiter>
		</StyledToolBar>
	);
};

const TopAppBar = () => {
	const isMobile = useIsMobile();
	return isMobile ? <MobileTopAppBar /> : <DesktopTopAppBar />;
};
export default React.memo(TopAppBar);
