import { useQuery, gql } from '@apollo/client';

export type AppFunctionalityType =
	| 'ACCESS_LEVEL_1'
	| 'ACCESS_LEVEL_2'
	| 'ACCESS_LEVEL_3'
	| 'CERTIFICATES'
	| 'FAVOURITES'
	| 'DASHBOARD'
	| 'SHARE_CONTENTS_TEACHER'
	| 'SHARE_CONTENTS_PARENTS'
	| 'SHARE_CONTENTS'
	| 'PRIORITY_ACCESS';

export type AppFunctionality = {
	type: AppFunctionalityType;
	description: string;
	property?: number;
};

export type Membership = {
	id: string;
	level: number;
	type: 'LEVEL_1' | 'LEVEL_2';
	title: string;
	description?: string;
	priceCents?: string;
	priceLabel: string;
	appFunctionalities: AppFunctionality[];
};

export const MembershipInfoFragment = gql`
	fragment MembershipInfoFragment on Membership {
		id
		type
		level
		title
		description
		priceCents
		priceLabel
		appFunctionalities {
			type
			description
			property
		}
	}
`;

export const useAllMemberships = (): Membership[] | undefined => {
	const { data } = useQuery(
		gql`
			query {
				memberships {
					...MembershipInfoFragment
				}
			}
			${MembershipInfoFragment}
		`
	);

	return data?.memberships;
};

export const useMembership = (id?: string): Membership | undefined | null => {
	const { data } = useQuery(
		gql`
			query Product($id: ID!) {
				membership(id: $id) {
					...MembershipInfoFragment
				}
			}
			${MembershipInfoFragment}
		`,
		{ variables: { id }, skip: !id }
	);

	return !id ? null : data?.membership;
};
